import { useAppDispatch } from "../store/hook/store.hook";
import { updateLocation } from "../store/slices/profile.slice";

const useGeolocation = () => {

    const dispatch = useAppDispatch()

    const getLocation = () => {


        if (!navigator.geolocation) {
            console.log("no permitido");
        }

        navigator.geolocation.getCurrentPosition(
            (position) => {
                dispatch(
                    updateLocation(
                        {
                            latitud: position.coords.latitude,
                            longitud: position.coords.longitude
                        }
                    )
                )
            },
            () => {
                console.log("no permitido");
            }
        );
    };

    return { location, getLocation };
};

export default useGeolocation;
