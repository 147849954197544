export interface BusinessUnitSchema {
    id: string;
    name: string;
    created_at: string;
    updated_at: string;
}

export interface BusinessUnitState {
    businessUnits: BusinessUnitSchema[];
    loading?: boolean;
    error?: string | null;
    selected?: BusinessUnitSchema | null
}

export const initialState: BusinessUnitState = {
    businessUnits: [],
    loading: false,
    error: null,
    selected: null
};