import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook";
import { createFolder } from "../../store/actions/folders.action";




const schema = z.object({
    name: z.string({
        required_error: "Debe ingresar el Nombre de la Carpeta.",
    }).min(1, "Debe ingresar un Nombre valido."),
    description: z.string().optional(),
    bucket_id: z.string({ required_error: "Debe ingresar el Bucket." }).min(1, "Debe ingresar un Bucket valido."),
    parent_folder_id: z.string().optional(),
})


export type FolderFormTypeSchema = z.infer<typeof schema>

const useForlderForm = () => {
    const dispatch = useAppDispatch()
    const { opened } = useAppSelector(state => state.fileExplorer)
    const { selected } = useAppSelector(state => state.buckets)
    const { trigger, control, handleSubmit, setValue } = useForm<FolderFormTypeSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            name: '',
            description: '',
            bucket_id: '',
        }
    });

    useEffect(() => {
        if (selected) {
            setValue('bucket_id', selected.id)
        }
        if (opened) {
            setValue('parent_folder_id', opened.id)
        }
    }, [opened])


    const onSubmit: SubmitHandler<FolderFormTypeSchema> = (data: FolderFormTypeSchema) => {
        let tmpData: FolderFormTypeSchema = {
            name: data.name,
            description: data.description,
            bucket_id: data.bucket_id,
        }
        if (data.parent_folder_id) {
            tmpData.parent_folder_id = data.parent_folder_id
        }
        dispatch(createFolder({
            ...tmpData
        }, selected, opened))
    };

    const onSubmitEdit: SubmitHandler<FolderFormTypeSchema> = (data: FolderFormTypeSchema) => {
        console.log(data);
    }

    return {
        trigger,
        control,
        handleSubmit: handleSubmit(onSubmit),
        handleEditSubmit: handleSubmit(onSubmitEdit)
    }
}

export default useForlderForm