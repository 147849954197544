import { createSvgIcon } from "@mui/material";

export const ProjectIcon = createSvgIcon(
    <svg width="1080" height="1080" viewBox="0 0 1080 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M915.93 71.2908C915.93 50.6908 908.22 43.0408 887.47 43.0308C642.17 42.9908 414.73 42.9908 192.16 43.0408C171.68 43.0408 164.07 50.8008 164.07 71.6408C164.05 357.901 164.05 644.161 164.07 930.421C164.07 951.981 171.12 958.901 193.07 958.911C231.81 958.941 270.55 958.931 309.29 958.931H373.08V988.751C373.08 991.121 373.07 993.501 373.06 995.871C373.05 1001.06 373.03 1006.44 373.1 1011.71C373.3 1027.18 381.7 1036.87 395.03 1037C401.25 1037.04 406.61 1035.09 410.45 1031.31C415.06 1026.79 417.53 1019.83 417.6 1011.18C417.69 1000.13 417.67 989.311 417.65 977.851C417.64 972.851 417.63 967.821 417.63 962.751V959.751H469.6V962.751C469.6 968.341 469.56 973.871 469.52 979.341C469.43 992.041 469.33 1004.03 469.75 1016.19C470.15 1028.1 479.28 1036.86 491.44 1037C503.47 1037.08 512.69 1028.79 513.85 1016.7C514.18 1013.29 514.13 1009.85 514.08 1006.21C514.06 1004.82 514.04 1003.44 514.04 1002.05C514.05 992.541 514.05 983.041 514.05 973.621V959.701H565.96V962.701C565.96 968.111 565.94 973.441 565.92 978.721C565.86 990.811 565.81 1002.22 566.04 1013.82C566.18 1021.03 568.75 1027.29 573.26 1031.46C577.58 1035.44 583.33 1037.34 589.88 1036.95C601.94 1036.22 610.19 1026.84 610.4 1013.61C610.59 1001.76 610.55 989.711 610.5 978.061C610.49 972.931 610.47 967.801 610.47 962.691V959.691H662.37V962.691C662.37 967.881 662.36 972.991 662.35 978.061C662.33 989.571 662.3 1000.45 662.4 1011.52C662.54 1027.01 670.91 1036.77 684.23 1037C690.51 1037.08 695.87 1035.16 699.74 1031.38C704.35 1026.89 706.82 1019.97 706.88 1011.38C706.97 1000.37 706.95 989.431 706.93 977.861C706.92 972.661 706.91 967.361 706.91 961.931V958.931H771.67C809.93 958.941 849.5 958.951 888.41 958.901C908.73 958.881 915.93 951.631 915.93 931.211C915.96 652.701 915.96 363.381 915.93 71.2908ZM870.62 913.541H209.37V88.3408H870.62V913.541Z" fill="#8F8F8F" />
        <path d="M814.81 829.829C814.81 850.069 807.34 857.589 787.27 857.599C624.59 857.639 458.23 857.639 292.79 857.599C272.67 857.589 265.19 850.089 265.18 829.899C265.16 735.599 265.16 641.289 265.17 546.989V470.869C265.15 469.079 265.14 467.389 265.26 465.709C266.11 453.469 274.96 444.749 286.76 444.509C286.94 444.509 287.11 444.499 287.29 444.499C299 444.499 308.08 452.669 309.47 464.539C309.82 467.499 309.78 470.519 309.74 473.719C309.72 474.749 309.71 475.789 309.71 476.819C309.71 566.879 309.71 656.939 309.71 746.989V812.319H769.35V190.099H310.44V349.259H384C484.94 349.259 585.88 349.259 686.82 349.289H687.25C692.27 349.289 697.47 349.299 702.3 350.139C712.47 351.899 720.19 361.079 720.26 371.489C720.33 382.309 712.28 391.659 701.54 393.239C697.54 393.829 693.28 393.819 689.15 393.819H688.25C557.84 393.849 427.43 393.849 297.03 393.839C271.42 393.839 265.16 387.639 265.16 362.279V330.089C265.15 278.479 265.14 225.099 265.17 172.609C265.19 152.349 272.88 144.449 292.59 144.439C449.62 144.329 615.98 144.329 787.07 144.429C806.76 144.439 814.79 152.519 814.79 172.309C814.82 384.769 814.82 605.989 814.8 829.829H814.81Z" fill="#8F8F8F" />
        <path d="M605.75 619.041C588.75 650.671 568.63 687.981 548.39 724.881C542.12 736.311 531.33 740.741 520.25 736.421C509.79 732.351 504.11 722.001 506.43 711.231C507.2 707.671 509.02 704.101 510.98 700.451C517.32 688.621 523.83 676.631 530.13 665.031C534.3 657.341 538.48 649.661 542.63 641.961C543.81 639.781 544.85 637.581 546.17 634.811C546.84 633.371 547.6 631.781 548.48 629.971L550.56 625.661H545.78C540.2 625.661 534.79 625.671 529.51 625.671C518.09 625.691 507.3 625.701 496.47 625.641C484.59 625.581 475.81 621.771 471.74 614.921C467.66 608.031 468.54 598.411 474.24 587.851C482.11 573.261 490.16 558.451 497.94 544.121C508.64 524.421 519.71 504.061 530.48 483.961C536.17 473.351 543.29 468.541 552.91 468.751C560.57 468.931 567.15 472.701 570.97 479.081C575.01 485.851 575.13 494.411 571.28 501.981C564.17 515.991 556.47 530.031 549.02 543.601C545.44 550.111 541.75 556.851 538.16 563.511C536.85 565.921 535.68 568.331 534.19 571.371C533.43 572.931 532.59 574.641 531.62 576.581L529.46 580.921H534.31C539.98 580.921 545.43 580.911 550.74 580.901C562.03 580.871 572.7 580.841 583.33 580.951C595.21 581.061 604.01 584.961 608.12 591.911C612.25 598.891 611.4 608.521 605.75 619.041Z" fill="#8F8F8F" />
    </svg>
    ,
    'ProjectsIcon'
)