import { FC } from "react";
import SubMenu from "../../atomos/explorer/SubMenu";
import WrapperControl from "../../atomos/explorer/wrapperControl";
import { Box, Button, Divider, Menu, MenuItem as MenuItemMui, styled, Typography } from "@mui/material";
import useAnchorEl from "../../../hooks/useAnchorEl";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useModal from "../../../hooks/useModal";
import ModalComponent from "../../moleculas/Modal";
import DragBtn from "../../atomos/explorer/DragCmp";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useAppDispatch, useAppSelector } from "../../../store/hook/store.hook";
import { deleteFile, deleteFolder } from "../../../store/actions/folders.action";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FolderEdited from "../../organismos/folders/edited";
import DownloadIcon from '@mui/icons-material/Download';
import useFileLoader from "../../../hooks/fileExplorer/ext/useFileLoader";



import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";


dayjs.extend(utc);
dayjs.extend(timezone);


const MenuItem = styled(MenuItemMui)(({ }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: "space-between"
}))


interface ControlProps {
    infoProps: any
    listeners?: any
    isAdmin?: boolean
}

const ControlItem: FC<ControlProps> = ({
    infoProps,
    listeners,
    isAdmin
}) => {
    const dispatch = useAppDispatch()
    const { selected } = useAppSelector(state => state.buckets)
    const { opened } = useAppSelector(state => state.fileExplorer)
    const { anchorEl, handleClick, handleClose, open } = useAnchorEl()
    const { handleClose: handleCloseModal, handleOpen: handleOpen, open: openModal } = useModal()
    const { handleClose: handleDeleteCloseModal, handleOpen: handleDeleteOpenModal, open: openDeleteModal } = useModal()
    const { handleClose: handleUpdateCloseModal, handleOpen: handleUpdateOpenModal, open: openUpdateModal } = useModal()

    const { downloadFile } = useFileLoader({ fileProps: infoProps });
    const deleteAttachment = () => {
        dispatch(deleteFile(infoProps.id, selected, opened))
    }

    const handleDeleteFolder = () => {
        dispatch(deleteFolder(infoProps.idItem, selected, opened))
    }

    return (
        <WrapperControl >
            {listeners && <DragBtn listeners={listeners} />}
            <SubMenu onClick={handleClick} />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleOpen()
                    handleClose()
                    console.log(infoProps);

                }}>
                    <Typography>
                        Información
                    </Typography>
                    <InfoOutlinedIcon />
                </MenuItem>
                {
                    infoProps && infoProps.type !== "folder" && (
                        <MenuItem onClick={() => {
                            downloadFile();
                            handleClose()
                        }}>
                            <Typography>
                                Descargar
                            </Typography>
                            <DownloadIcon />
                        </MenuItem>
                    )
                }
                {
                    isAdmin && (
                        <MenuItem onClick={() => {
                            handleDeleteOpenModal()
                            handleClose()
                        }}>
                            <Typography>
                                Eliminar
                            </Typography>
                            <DeleteOutlineOutlinedIcon />
                        </MenuItem>
                    )
                }
                {
                    isAdmin && infoProps && infoProps.type === 'folder' && (
                        <MenuItem onClick={() => {
                            handleUpdateOpenModal()
                            handleClose()
                        }}>
                            <Typography>
                                Editar
                            </Typography>
                            <EditOutlinedIcon />
                        </MenuItem>
                    )
                }
            </Menu>
            <ModalComponent open={openModal} onClose={handleCloseModal}>
                <>
                    {
                        infoProps && infoProps.type === 'folder' ? (
                            <>
                                <Typography variant="h6" color="primary" >Información</Typography>
                                <Typography variant="body1" color="secondary" ><b>Nombre:</b> {infoProps.name}</Typography>
                                <Divider />
                                <Typography variant="body1" color="secondary" ><b>Fecha de creación:</b> {
                                    dayjs(infoProps.createdDate)
                                        .tz("America/Bogota")
                                        .format("DD/MM/YYYY")
                                }</Typography>
                            </>
                        ) :
                            <>
                                <Typography variant="h6" color="primary" >Información</Typography>
                                <Typography variant="body1" color="secondary" ><b>Nombre:</b> {infoProps.fileName}</Typography>
                                <Typography variant="body1" color="secondary">
                                    <b>Peso:</b> {infoProps.fileSize >= 1024 * 1024 ? `${(infoProps.fileSize / (1024 * 1024)).toFixed(2)} MB` : infoProps.fileSize >= 1024 ? `${(infoProps.fileSize / 1024).toFixed(2)} KB` : `${infoProps.fileSize.toFixed(2)} B`}
                                </Typography>
                                <Typography variant="body1" color="secondary" ><b>Formato:</b> {infoProps.fileType}</Typography>
                            </>
                    }
                </>
            </ModalComponent>
            <ModalComponent open={openDeleteModal} onClose={handleDeleteCloseModal}>
                <>
                    <Typography variant="h6" color="primary" >Eliminar</Typography>
                    <Typography variant="body1" color="secondary" >¿Estas seguro de querer eliminar?</Typography>
                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleDeleteCloseModal}
                        >Cancelar</Button>
                        <Button
                            onClick={infoProps && infoProps.type === 'folder' ? handleDeleteFolder : deleteAttachment}
                        >Eliminar</Button>
                    </Box>
                </>
            </ModalComponent>
            {
                infoProps && infoProps.type === 'folder' && (
                    <ModalComponent open={openUpdateModal} onClose={handleUpdateCloseModal}>
                        <FolderEdited handleCloseModal={handleUpdateCloseModal} infoProps={infoProps} />
                    </ModalComponent>
                )
            }
        </WrapperControl>
    )
}

export default ControlItem