import { FC, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useLoaderBusinessUnit } from "../../hooks/businessUnit/useLoaderBusinessUnit";
import DataGridBuckets from "../../components/organismos/buckets/DataGridBuckets";
import { Alert, Container, Snackbar, SnackbarCloseReason, styled, Typography } from "@mui/material";
import BucketFilterController from "../../components/moleculas/bucket/FilterController";
import BucketActionsController from "../../components/moleculas/bucket/ActionsController";
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook";
import { getChargeBuckets } from "../../store/actions/buckets.action";
import { clearError } from "../../store/slices/project.slice";

interface IBusinessUnitProps {

}

const Titled = styled(Typography)(({ }) => ({
    textTransform: 'capitalize',

}))

const BusinessUnit: FC<IBusinessUnitProps> = ({ }) => {

    const [openMessage, setOpenMessage] = useState<boolean>(false)

    const [search, setSearch] = useState<string>("")
    const [project_state, setProjectState] = useState<string>("")
    const [from, setFrom] = useState<string>("")
    const [to, setTo] = useState<string>("")

    const { state } = useLocation()
    const { loadBuckets, paginated } = useLoaderBusinessUnit()
    const dispatch = useAppDispatch()

    const { error } = useAppSelector(state => state.projects)

    useEffect(() => {
        loadBuckets(state.businessUnit.id)
    }, [state])

    useEffect(() => {
        if (error === null || error === undefined || error === "") {
            setOpenMessage(false)
        } else {
            setOpenMessage(true)
        }
    }, [error])

    useEffect(() => {
        dispatch(getChargeBuckets(
            {
                businessUnit: state.businessUnit.id,
                pagination: {
                    page: paginated!.page + 1,
                    limit: paginated!.limit
                },
                search,
                project_state,
                from,
                to
            }
        ))
    }, [paginated, search, project_state, from, to])

    const handleClose = (
        _: React.SyntheticEvent | Event, // Use underscore (_) to indicate the unused parameter
        reason?: SnackbarCloseReason
    ) => {

        dispatch(clearError())
        if (reason === 'clickaway') {
            return;
        }

        setOpenMessage(false);
    };


    return (
        <Container>
            <Titled variant="h3">
                {state.businessUnit.name}
            </Titled>
            <BucketFilterController
                search={search}
                setSearch={setSearch}
                project_state={project_state}
                setProjectState={setProjectState}
                setFrom={setFrom}
                setTo={setTo}
            />
            <BucketActionsController />
            <DataGridBuckets />
            <Snackbar
                open={openMessage}
                autoHideDuration={5000}
                onClose={handleClose}
                message={error}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {error}
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default BusinessUnit