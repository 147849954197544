import { useEffect } from "react"
import useAdminHistoryMonitoring from "../../hooks/monitoring/useAdminHistoryMonitoring"
import { Container, Typography } from "@mui/material"
import AdminHistoryGrid from "../../components/organismos/specials/AdminGrid"

const AdminMonitored = () => {
    const { pagination, visitedAllData } = useAdminHistoryMonitoring()

    useEffect(() => {
        visitedAllData()
    }, [pagination])

    return (
        <Container>
            <Typography variant="h3" gutterBottom>Visitas Administradores</Typography>
            <AdminHistoryGrid />
        </Container>
    )
}

export default AdminMonitored;