import { styled, TextFieldProps } from "@mui/material";
import CustomTextField from "../atomos/TextField";
import { FC } from "react";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import MessageError from "../atomos/MessageError";


const Wrapper = styled('div')(() => ({
    width: '100%',
}))



type TempProps = TextFieldProps & {
    field: ControllerRenderProps<any, any>;
    fieldState: ControllerFieldState;
};


const InputMessage: FC<TempProps> = ({ field, fieldState, ...props }) => {

    return (
        <Wrapper>
            <CustomTextField
                {...field}
                {...props}
                value={field.value || undefined} // Asegúrate de que siempre sea una cadena
                error={!!fieldState.error}
                sx={fieldState.error ? {
                    '&::after': {
                        content: '""',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: '2%', // Ancho del borde azul
                        backgroundColor: 'red' // Color del borde azul
                    }
                } : {}}
            />
            <MessageError>{fieldState.error?.message}</MessageError>
        </Wrapper>
    )
}

export default InputMessage