import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { closestCenter, DndContext, PointerSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import useBucketExplorer from "../../../hooks/bucket/useBucketExplorer";
import { SortableContext } from '@dnd-kit/sortable';
import SortableItem from '../../moleculas/dnd/SortableItem';
import TargetsWrapper from "../../atomos/TargetsWrap";
import TargeGridItem from "../../atomos/TargetItem";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import Attachment from "../../file-explorer/entity/attachment";
import { Skeleton, Stack } from "@mui/material";
import Viewer from "../../file-explorer/viewers";
import useModal from "../../../hooks/useModal";

interface IFoldersBucketProps {
}

const FoldersBucket: FC<IFoldersBucketProps> = ({ }) => {

    const { folderId } = useParams()
    const { items: reduxItems, actualProject, reloadFileExplorer, attachment, updateSequence, loading } = useBucketExplorer()
    const [items, setItems] = useState(reduxItems); // Estado local para actualizar visualmente
    const navigate = useNavigate()
    const [fileProps, setFileProps] = useState({})
    const {open: openModal, handleOpen: handleOpenModal, handleClose: handleCloseModal} = useModal()


    const pointSensor = useSensor(PointerSensor, {
        activationConstraint: {
            delay: 0, // Prueba sin retraso
            tolerance: 0,
        }
    })
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            delay: 0, // Prueba sin retraso
            tolerance: 0,
        },
    });
    const sensors = useSensors(pointSensor, touchSensor)

    useEffect(() => {
        reloadFileExplorer(folderId!)
    }, [folderId])

    useEffect(() => {
        // Sincronizar solo si los items no han cambiado localmente
        if (JSON.stringify(items) !== JSON.stringify(reduxItems)) {
            setItems(reduxItems);
        }
    }, [reduxItems]);

    const tmpFunc = (folderId: string) => {
        navigate(`/dashboard/businessUnit/${actualProject}/folder/${folderId}`)
    }

    const handleDragEnd = async (event: any) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = items.findIndex((item) => item.id === active.id);
            const newIndex = items.findIndex((item) => item.id === over?.id);

            if (oldIndex !== -1 && newIndex !== -1) {
                const updatedItems = [...items];
                const [movedItem] = updatedItems.splice(oldIndex, 1);
                updatedItems.splice(newIndex, 0, movedItem);

                // Actualizar estado local para cambios inmediatos
                setItems(
                    updatedItems.map((item, index) => ({
                        ...item,
                        sequence: index,
                    }))
                );

                // Actualizar el backend y Redux
                updateSequence(movedItem.fldId, newIndex);
            }
        }
    };

    const handleClickAttachment = (fileProps: any) => {
        setFileProps(fileProps)
        handleOpenModal()
    }

    if (loading) {
        return (
            <Stack spacing={1}>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rounded" />
            </Stack>
        )
    }

    return (
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToWindowEdges]} sensors={sensors}>
            <SortableContext items={items} >
                <TargetsWrapper columns={{ xs: 2, sm: 6, md: 12, lg: 12, xl: 14 }}>
                    {
                        items.map((it) => (
                            <TargeGridItem size={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} key={it.id}>
                                <SortableItem key={it.id} idItem={it.fldId} clickAccess={tmpFunc} sequence={it.sequence} name={it.name} createdDate={it.createdDate} isAdmin/>
                            </TargeGridItem>
                        ))
                    }

                    {
                        attachment.map((at: any) => {

                            return (
                                <TargeGridItem size={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} key={at.id}>
                                    <Attachment
                                        id={at.id}
                                        fileName={at.fileName}
                                        fileType={at.fileType}
                                        fileSize={at.fileSize}
                                        filePath={at.filePath}
                                        onClick={() => handleClickAttachment(at)}
                                        isAdmin
                                    />
                                </TargeGridItem>
                            )
                        })
                    }
                    <Viewer open={openModal} onClose={handleCloseModal} fileProps={fileProps} />
                </TargetsWrapper>
            </SortableContext>
        </DndContext>
    )
}

export default FoldersBucket