import { IconButton, styled } from "@mui/material"
import { ToolbarPlugin, ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/toolbar"
import { FC, ReactElement } from "react"
import LabelFolder from "../../../atomos/explorer/LabelFolder"
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const Div = styled('div')(() => ({
    width: '100%',
    backgroundColor: '#8F8F8F',
    padding: '.4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white'
}))

const WrapperPluginToolbar = styled('div')(() => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 0,
    margin: 0,
}))

const WrapperFloatToolbar = styled('div')(() => ({
    alignItems: 'center',
    backgroundColor: '#eeeeee',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '2px',
    bottom: '50px',
    display: 'flex',
    left: '50%',
    padding: '4px',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    zIndex: 1,
}))

const CountPageWrap = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

const ImageToolbar = styled('div')(() => ({
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 0,
    margin: 0,
}))

const renderToolBar = (Toolbar: (props: ToolbarProps) => ReactElement, fileName: string, onClose: () => void) => (
    <Toolbar>
        {
            (slots: ToolbarSlot) => {
                const {
                    CurrentPageLabel,
                    NumberOfPages
                } = slots

                return (
                    <WrapperPluginToolbar>
                        <LabelFolder sx={{ maxWidth: "40% !important" }} title={fileName}>
                            {fileName}
                        </LabelFolder>
                        <CountPageWrap>
                            <CurrentPageLabel /> / <NumberOfPages />
                            <IconButton sx={{ color: 'white' }} onClick={onClose}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        </CountPageWrap>
                    </WrapperPluginToolbar>
                )
            }
        }
    </Toolbar>
)


const floatToolbar = (Toolbar: (props: ToolbarProps) => ReactElement) => (
    <Toolbar>
        {
            (slots: ToolbarSlot) => {
                const {
                    ZoomOut,
                    ZoomIn,
                    EnterFullScreen,
                    Download,
                    Print
                } = slots

                return (
                    <WrapperFloatToolbar>
                        <div style={{ padding: '0px 2px' }}>
                            <ZoomOut />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <ZoomIn />
                        </div>
                        <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                            <EnterFullScreen />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <Download />
                        </div>
                        <div style={{ padding: '0px 2px' }}>
                            <Print />
                        </div>
                    </WrapperFloatToolbar>
                )
            }
        }
    </Toolbar>
)

const ImageToolBar = ({ onClose }: { onClose: () => void }) => (
    <ImageToolbar>
        <IconButton sx={{ color: 'white' }} onClick={onClose}>
            <CloseOutlinedIcon />
        </IconButton>
    </ImageToolbar>
)

export interface ToolTipNavProps {
    toolbarPlugin: ToolbarPlugin,
    fileProps: any
    onClose: () => void
}

const ToolTipNav: FC<ToolTipNavProps> = ({ toolbarPlugin: toolbarPluginInstance, fileProps, onClose }) => {

    const { Toolbar } = toolbarPluginInstance;
    return (
        <Div>
            {fileProps.fileType === 'application/pdf' && renderToolBar(Toolbar, fileProps.fileName, onClose)}
            {fileProps.fileType === 'application/pdf' && floatToolbar(Toolbar)}
            {fileProps.fileType === 'image/jpeg' || fileProps.fileType === 'image/png' && <ImageToolBar onClose={onClose} />}
        </Div>
    )
}

export default ToolTipNav