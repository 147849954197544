export interface ProfileState {
    id?: number | null;
    name: string;
    email: string;
    rol: string;
    location: {
        latitud: number,
        longitud: number
    } | null
}

const initialState: ProfileState = {
    name: '',
    email: '',
    rol: '',
    location: null
};

export default initialState