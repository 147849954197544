import { Control, Controller } from "react-hook-form"
import Form from "../../atomos/Form"
import { Box, Button, TextField } from "@mui/material"
import { FC } from "react"
import { FolderEditedFormTypeSchema } from "../../../hooks/folders/useEditedFolder"

interface EditedFolderFormProps {
    control: Control<FolderEditedFormTypeSchema>
    handleSubmit: any
    handleCloseModal: () => void
}


const EditedForm: FC<EditedFolderFormProps> = ({ control, handleSubmit, handleCloseModal }) => {

    return (
        <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Controller
                name="name"
                control={control}
                render={({ field, fieldState }) => (
                    <TextField
                        label="Nombre de la carpeta"
                        {...field}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                    />
                )}
            />
            <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                    <TextField
                        label="Descripción de la carpeta"
                        {...field}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                    />
                )}
            />
            <Controller
                name="id"
                control={control}
                render={({ field, fieldState }) => (
                    <TextField
                        label="ID de la carpeta"
                        {...field}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        sx={{ visibility: 'hidden' }}
                    />
                )}
            />
            <Box sx={{ display: 'flex', gap: '1rem', width: '100%', justifyContent: 'flex-end' }}>
                <Button type="submit" variant="contained" onClick={() => {
                    handleSubmit()
                    handleCloseModal()
                }} >Guardar</Button>
                <Button onClick={handleCloseModal} variant="contained">Cancelar</Button>
            </Box>
        </Form>
    )
}


export default EditedForm