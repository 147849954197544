import { useEffect } from "react"
import { getVisitedAllData } from "../../store/actions/monitoring.action"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook"
import { visitedChangePagination } from "../../store/slices/monitoring/monitoring.slice"


const useVisitedMonitoring = () => {

    const dispatch = useAppDispatch()
    const { visited: { allData, pagination, meta }, loading } = useAppSelector(state => state.monitoring)

    const visitedAllData = () => {
        dispatch(getVisitedAllData({
            pagination
        }))
    }

    useEffect(() => {
        visitedAllData()
    }, [pagination])

    const changePagination = (page: number, limit: number) => {
        dispatch(visitedChangePagination({
            limit,
            page
        }))
    }

    return {
        visitedAllData,
        allData,
        pagination,
        meta,
        loading,
        changePagination
    }
}


export default useVisitedMonitoring