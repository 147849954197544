import { loadFolder } from "../../store/actions/recursos.actions"
import { useAppDispatch } from "../../store/hook/store.hook"


const useReloadFileExplorer = ({
    proyectoId,
    folderId
}: {proyectoId: string, folderId: string}) => {
    
    const dispatch = useAppDispatch()

    const set = () => {
        dispatch(loadFolder(proyectoId, folderId))
    }

    return {
        set,
        actualFolderId: folderId
    }
}

export default useReloadFileExplorer