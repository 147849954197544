import { Box, Modal, ModalProps, styled } from "@mui/material"
import { FC } from "react"


const Wrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '15px',
    padding: '20px',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
        width: '90%',
    }
}))

interface ImodalProps extends ModalProps {
    padding?: number
}

const ModalComponent: FC<ImodalProps> = ({ children, padding, ...props }) => {

    return (
        <Modal {...props}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Wrapper bgcolor={'background.paper'} sx={{ padding }}>
                {children}
            </Wrapper>
        </Modal>
    )
}

export default ModalComponent