import {
    closestCenter,
    DndContext,
    PointerSensor,
    TouchSensor,
    KeyboardSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import SortableItem from '../../moleculas/dnd/SortableItem';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import useBucketExplorer from '../../../hooks/bucket/useBucketExplorer';
import TargetsWrapper from '../../atomos/TargetsWrap';
import TargeGridItem from '../../atomos/TargetItem';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Skeleton, Stack } from '@mui/material';
import { useAppDispatch } from '../../../store/hook/store.hook';
import { loadOpened } from '../../../store/slices/fileExplorer.slice';

const BucketExplorer = () => {
    const { items: reduxItems, actualProject, updateSequence, loading } = useBucketExplorer();
    const [items, setItems] = useState(reduxItems); // Estado local para actualizar visualmente
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(loadOpened())
    }, [])

    useEffect(() => {
        // Sincronizar solo si los items no han cambiado localmente
        if (JSON.stringify(items) !== JSON.stringify(reduxItems)) {
            setItems(reduxItems);
        }
    }, [reduxItems]);


    const pointSensor = useSensor(PointerSensor, {
        activationConstraint: {
            delay: 0, // Prueba sin retraso
            tolerance: 0,
        },
    });
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            delay: 0, // Prueba sin retraso
            tolerance: 0,
        },
    });
    
    const keySensor = useSensor(KeyboardSensor)
    const sensors = useSensors(pointSensor, touchSensor, keySensor);

    const tmpFunc = (folderId: string) => {
        navigate(`/dashboard/businessUnit/${actualProject}/folder/${folderId}`);
    };

    const handleDragEnd = async (event: any) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            const oldIndex = items.findIndex((item) => item.id === active.id);
            const newIndex = items.findIndex((item) => item.id === over?.id);

            if (oldIndex !== -1 && newIndex !== -1) {
                const updatedItems = [...items];
                const [movedItem] = updatedItems.splice(oldIndex, 1);
                updatedItems.splice(newIndex, 0, movedItem);

                // Actualizar estado local para cambios inmediatos
                setItems(
                    updatedItems.map((item, index) => ({
                        ...item,
                        sequence: index,
                    }))
                );

                // Actualizar el backend y Redux
                updateSequence(movedItem.fldId, newIndex);
            }
        }
    };

    if (loading) {
        return (
            <Stack spacing={1}>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rounded" />
            </Stack>
        )
    }

    return (
        <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToWindowEdges]}
            sensors={sensors}
        >
            <SortableContext items={items.map((item) => item.id)}>
                <TargetsWrapper
                    columns={{ xs: 2, sm: 6, md: 12, lg: 12, xl: 14 }}
                >
                    {items.map((it) => (
                        <TargeGridItem
                            key={it.id}
                            size={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }}
                        >
                            <SortableItem
                                idItem={it.fldId}
                                clickAccess={tmpFunc}
                                sequence={it.sequence}
                                name={it.name}
                                createdDate={it.createdDate}
                                key={it.id}
                                isAdmin
                            />
                        </TargeGridItem>
                    ))}

                </TargetsWrapper>
            </SortableContext>
        </DndContext>
    );
};

export default BucketExplorer;
