import { Container } from "@mui/material"
import { useEffect } from "react"
import { useParams } from "react-router-dom"


const EditedTemplateContainer = () => {

    const { templateId } = useParams()


    useEffect(() => { }, [templateId])

    return (
        <Container>
            Edited template
            {
                templateId
            }
        </Container>
    )
}

export default EditedTemplateContainer