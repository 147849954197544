import { Box, styled } from "@mui/material";


const LoginBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        height: '70%',  
    },
    [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
    },
}))

export default LoginBox