import { useAppSelector } from "../../store/hook/store.hook"


const useFileExplorer = () => {

    const recursosState = useAppSelector(state => state.recursos)

    const structure = recursosState.structure
    const isLoading = recursosState.loading

    return {
        structure,
        isLoading
    }
}


export default useFileExplorer