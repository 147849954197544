import { Container, Typography } from "@mui/material"
import EditableProjectFormContainer from "../../components/organismos/projects/forms/EditableFormContainer"
import useFormProject from "../../hooks/projects/useFormProject"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook"
import { getInfoProject } from "../../store/actions/projects.action"
import useNavigationVisibility from "../../hooks/useNavigationVisibility"


const ProjectComponent = () => {


    const profile = useAppSelector((state) => state.profile)
    const allowerSuperAdmin = ['superadmin', 'admin'];
    
    const showNavigationForSuperAdmin = useNavigationVisibility(profile.rol, allowerSuperAdmin);

    const { control, setValue, trigger, getValues, errors } = useFormProject()
    const { projectId } = useParams()
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (projectId) {
            const params = new URLSearchParams()
            params.set('managements', 'true')
            params.set('ubication', 'true')
            dispatch(getInfoProject(projectId, params, setValue))
        }
    }, [projectId])

    
    if (!showNavigationForSuperAdmin) {
        return (
            <>
                ACCESO DENEGADO
            </>
        )
    }

    return (
        <Container>
            <Typography variant="h3" sx={{ marginBottom: '5rem' }}>
                Editar Proyecto
            </Typography>
            <EditableProjectFormContainer control={control} trigger={trigger} getValues={getValues} errors={errors} />
        </Container>
    )
}

export default ProjectComponent