import { FC, useEffect } from "react";
import useIconLoader from "../../../hooks/useIconLoader";
import TargetsWrapper from "../../atomos/TargetsWrap";
import TargeGridItem from "../../atomos/TargetItem";
import Target from "../../moleculas/Target";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/hook/store.hook";
import { loadOpened } from "../../../store/slices/fileExplorer.slice";
import { styled, Typography, useMediaQuery, useTheme } from "@mui/material";

const WrapTargetContent = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
}))

interface IModuleSelectorProps {
    modules: Array<{
        id: string,
        name: string,
    }>
}

const ModuleSelector: FC<IModuleSelectorProps> = ({ modules }) => {

    const dispatch = useAppDispatch()
    const { getIcon } = useIconLoader()
    const navigate = useNavigate()
    const theme = useTheme()
    const query = useMediaQuery(theme.breakpoints.down('lg'))

    const onClickTarget = (id: string) => {
        navigate(`?businessUnit=${id}`)
    }

    useEffect(() => {
        dispatch(loadOpened())
    }, [])


    return (
        <>
            <TargetsWrapper >
                {
                    modules.map((module) => (
                        <TargeGridItem key={module.id} onClick={() => onClickTarget(module.id)}>
                            <Target id={module.name}>
                                {!query && getIcon(module.name, 90, 90)}
                                {query && (
                                    <WrapTargetContent>
                                        {getIcon(module.name, 90, 90)}
                                        <Typography variant="h6">
                                            {module.name}
                                        </Typography>
                                    </WrapTargetContent>
                                )}
                            </Target>
                        </TargeGridItem>
                    ))
                }
            </TargetsWrapper>
        </>
    )
}

export default ModuleSelector