import { loadFolder, setInfoFolder } from "../../store/actions/recursos.actions"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook"
import { UniqueIdentifier } from "@dnd-kit/core"
import { setSequenceFolder } from "../../store/actions/folders.action"


const useBucketExplorer = () => {

    const dispatch = useAppDispatch()

    const fileExplorerState = useAppSelector(state => state.fileExplorer)
    const { selected } = useAppSelector(state => state.buckets)

    const items: Array<{
        id: UniqueIdentifier;
        name: string;
        fldId: string
        sequence: number;
        clickAccess: (id: string) => void;
        createdDate: string
    }> = fileExplorerState.folders.map((fld) => ({
        id: fld.sequence,
        name: fld.name,
        fldId: fld.id,
        sequence: fld.sequence,
        createdDate: fld.created_at,
        clickAccess: () => { },
    }))

    const reloadFileExplorer = (folderId: string) => {
        if (selected) {
            dispatch(loadFolder(selected.project_id!, folderId))
            dispatch(setInfoFolder(folderId))
        }
    }

    const updateSequence = (flderId: string, sequence: number) => {
        dispatch(setSequenceFolder({flderId, sequence}))
    }


    return {
        items,
        bucketInfo: selected,
        actualProject: selected?.project_id || '',
        reloadFileExplorer,
        attachment: fileExplorerState.attachments || [],
        updateSequence,
        loading: fileExplorerState.loading
    }

}


export default useBucketExplorer