import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import DataGridSSR from "../../moleculas/datagrids"
import { IMeta, IPagination, QRVisited } from "../../../store/schemas/monitoring.schema";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import { FC } from "react";
import { styled, Tooltip, Typography } from "@mui/material";


dayjs.extend(utc);
dayjs.extend(timezone);

const TmpTooltip = styled(Tooltip)(({ }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
}))

interface IVisitedGridProps {
    pagination: IPagination
    allData: Array<QRVisited>
    meta: IMeta
    loading: boolean
    changePagination: (page: number, limit: number) => void
}

const VisitedGrid: FC<IVisitedGridProps> = ({ pagination, allData, meta, loading, changePagination }) => {

    const columns: GridColDef<QRVisited>[] = [
        {
            field: "Nombre",
            headerName: "Nombre",
            flex: 0.8,
            renderCell: (params: GridRenderCellParams<QRVisited>) => (
                <TmpTooltip title={params.row.visitante.name}>
                    <Typography>
                        {params.row.visitante.name}
                    </Typography>
                </TmpTooltip>
            )
        },
        {
            field: "Email",
            headerName: "Email",
            renderCell: (params: GridRenderCellParams<QRVisited>) => params.row.visitante.email,
            flex: 0.8
        },
        {
            field: "Proyecto",
            headerName: "Proyecto",
            renderCell: (params: GridRenderCellParams<QRVisited>) => (
                <TmpTooltip title={params.row.bucket?.name || "No especificado."}>
                    <Typography>
                        {params.row.bucket?.name || "No especificado."}
                    </Typography>
                </TmpTooltip>
            ),
            flex: 1
        },
        {
            field: "Ruta",
            headerName: "Ruta",
            renderCell: (params: GridRenderCellParams<QRVisited>) => (
                <TmpTooltip title={params.row.routePath}>
                    <Typography>
                        {params.row.routePath}
                    </Typography>
                </TmpTooltip>
            ),
            flex: 1
        },
        {
            field: "Ubicación",
            headerName: "Ubicación",
            renderCell: (params: GridRenderCellParams<QRVisited>) => (
                <TmpTooltip title={params.row.latitud + ", " + params.row.longitud}>
                    <Typography>
                        {params.row.latitud + ", " + params.row.longitud}
                    </Typography>
                </TmpTooltip>
            ),
            flex: 0.5
        },
        {
            field: "Fecha",
            headerName: "Fecha Ingreso",
            renderCell: (params: GridRenderCellParams<QRVisited>) => dayjs(params.row.dateConsult).format("DD/MM/YYYY HH:mm"),
            flex: 0.5
        }
    ]

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        changePagination(model.page, model.pageSize)
    };

    return (
        <DataGridSSR rows={allData || []} columns={columns}
            rowCount={meta && meta.totalItems ? meta.totalItems : 0}
            paginationModel={{
                page: pagination!.page,
                pageSize: pagination!.limit
            }}
            onPaginationModelChange={handlePaginationModelChange}
            loading={loading}
        />
    )
}

export default VisitedGrid