import { styled } from "@mui/material"
import { FC, useState } from "react"
import ProjectStepper from "../../moleculas/projects/Stepper"
import ProjectStepperContent from "../../moleculas/projects/StepperContent"
import ProjectStepperActions from "../../moleculas/projects/StepperActions"
import useFormProject from "../../../hooks/projects/useFormProject"
import { useAppSelector } from "../../../store/hook/store.hook"

const WrapperStepper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: '2rem',
    gap: '2rem'
}))

interface IProjectWrapperStepperProps {
    projects: Array<any>
    loadNextPage: () => void
    isLoading?: boolean
    setSearch: (value: string) => void
}

const ProjectWrapperStepper: FC<IProjectWrapperStepperProps> = ({ projects }) => {

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set<number>());
    const { selected } = useAppSelector(state => state.projects)

    const { trigger, control, getValues } = useFormProject()

    const steps = [
        {
            label: 'Proyectos',
            content: projects
        },
        {
            label: 'Plantillas',
            content: []
        },
        {
            label: "Previsualizar Proyecto",
            content: []
        }
    ]

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = async () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        const isValidProject = await trigger();

        if (!isValidProject && !selected) {
            return
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);


    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <WrapperStepper>
            <ProjectStepper
                activeStep={activeStep}
                customSteps={steps}
                isStepOptional={isStepOptional}
                isStepSkipped={isStepSkipped}
            />
            <ProjectStepperContent
                activeStep={activeStep}
                customSteps={steps}
                controlProject={control}
                getValues={getValues}
            />
            <ProjectStepperActions
                activeStep={activeStep}
                customSteps={steps}
                handleNext={handleNext}
                handleBack={handleBack}
                handleReset={handleReset}
                handleSkip={handleSkip}
                isStepOptional={isStepOptional}
            />
        </WrapperStepper>
    )
}

export default ProjectWrapperStepper