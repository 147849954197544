import { AppDispatch } from ".."
import api from "../../services/axios"
import { errorInFileExplorer, fileExplorerLoading, updateOnlyFolder } from "../slices/fileExplorer.slice"
import { loadFolder, loadRootFileExplorer } from "./recursos.actions"
import { Bucket } from "../schemas/buckets.schema"

interface DataSetSequence {
    flderId: string
    sequence: number
}

interface CreateFolder {
    name: string
    description?: string
    bucket_id: string
    parent_folder_id?: string | null
}

interface CreateFile {
    parent_id: string;
    bucket_id: string;
    files: File[]; // Cambiado de 'file' a 'files'
}



const setSequenceFolder = (data: DataSetSequence) => async (dispatch: AppDispatch) => {
    const { flderId, sequence } = data
    dispatch(fileExplorerLoading())

    const url = '/recursos/folders/sequence'
    try {

        const response = await api.post(url, {
            flderId,
            sequence
        })

        dispatch(updateOnlyFolder(response.data))
    } catch (error) {
        const { response } = error as { response: any };
        if (response) {
            dispatch(errorInFileExplorer((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
        } else {
            dispatch(errorInFileExplorer((error as { message: string }).message || 'Error al cargar los recursos'))
        }
    }
}

const createFolder = (folder: CreateFolder, selected: Bucket | null | undefined, opened: {
    id: string;
    name: string;
} | null | undefined) => async (dispatch: AppDispatch) => {

    const url = '/recursos/folders'

    try {
        const response = await api.post(url, folder);

        if (response.data) {

            if (!opened && selected) {
                dispatch(loadRootFileExplorer(selected.id!))
            } else {
                dispatch(loadFolder(selected!.project!.id!, opened!.id!))
            }
        }
    } catch (error) {
        const { response } = error as { response: any };
        if (response) {
            dispatch(errorInFileExplorer((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
        } else {
            dispatch(errorInFileExplorer((error as { message: string }).message || 'Error al cargar los recursos'))
        }
    }
}

const uploadFiles = (data: CreateFile, selected: Bucket | null | undefined, opened: { id: string; name: string } | null | undefined) => async (dispatch: AppDispatch) => {
    const url = '/recursos/attachment';
    dispatch(fileExplorerLoading())

    const formData = new FormData();
    data.files.forEach((file) => formData.append('files', file));
    formData.append('bucket_id', data.bucket_id);
    formData.append('parent_id', data.parent_id);

    try {
        const response = await api.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.data) {
            if (!opened && selected) {
                dispatch(loadRootFileExplorer(selected.id!));
            } else {
                dispatch(loadFolder(selected!.project!.id!, opened!.id!));
            }
        }
    } catch (error) {
        const { response } = error as { response: any };
        if (response) {
            dispatch(errorInFileExplorer((response.data as { msg: string }).msg || 'Error al cargar los recursos'));
        } else {
            dispatch(errorInFileExplorer((error as { message: string }).message || 'Error al cargar los recursos'));
        }
    }
};



const deleteFile = (fileId: number, selected: Bucket | null | undefined, opened: {
    id: string;
    name: string;
} | null | undefined) => async (dispatch: AppDispatch) => {
    try {
        const response = await api.delete(`/recursos/attachment/${fileId}`)

        if (response.status === 200) {

            dispatch(loadFolder(selected!.project!.id!, opened!.id!))
        }

    } catch (error) {

        const { response } = error as { response: any };
        if (response) {
            dispatch(errorInFileExplorer((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
        } else {
            dispatch(errorInFileExplorer((error as { message: string }).message || 'Error al cargar los recursos'))
        }
    }
}


const updateFolder = (folderId: string, folder: { name: string, description: string }, selected: Bucket | null | undefined, opened: {
    id: string;
    name: string;
} | null | undefined) => async (dispatch: AppDispatch) => {

    const url = '/recursos/folders/' + folderId

    try {
        const response = await api.put(url, folder);

        if (response.data && response.status === 200) {
            if (!opened && selected) {
                dispatch(loadRootFileExplorer(selected.id!))
            } else {
                dispatch(loadFolder(selected!.project!.id!, opened!.id!))
            }
        }
    } catch (error) {
        const { response } = error as { response: any };
        if (response) {
            dispatch(errorInFileExplorer((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
        } else {
            dispatch(errorInFileExplorer((error as { message: string }).message || 'Error al cargar los recursos'))
        }
    }
}


const deleteFolder = (folderId: string, selected: Bucket | null | undefined, opened: {
    id: string;
    name: string;
} | null | undefined) => async (dispatch: AppDispatch) => {
    try {
        const response = await api.delete(`/recursos/folders/${folderId}`)

        if (response.status === 200) {
            if (!opened && selected) {
                dispatch(loadRootFileExplorer(selected.id!))
            } else {
                dispatch(loadFolder(selected!.project!.id!, opened!.id!))
            }
        }

    } catch (error) {

        const { response } = error as { response: any };
        if (response) {
            dispatch(errorInFileExplorer((response.data as { msg: string }).msg || 'Error al eliminar la carpeta'))
        } else {
            dispatch(errorInFileExplorer((error as { message: string }).message || 'Error al eliminar la carpeta'))
        }
    }
}

export { setSequenceFolder, createFolder, uploadFiles, deleteFile, updateFolder, deleteFolder }