import { AppDispatch } from "..";
import api from "../../services/axios";
import { PaginateOptions } from "../schemas/buckets.schema";
import { errorInLoadBuckets, loadBuckets, processingApiBuckets, selectedBucket } from "../slices/buckets.slice";

interface FilterProps {
    businessUnit: string
    pagination: PaginateOptions
    search?: string
    project_state?: string
    from?: string
    to?: string
}

const getChargeBuckets = ({ businessUnit, pagination, search, project_state, from, to }: FilterProps) => async (dispatch: AppDispatch) => {
    dispatch(processingApiBuckets())

    let url = `/recursos/bucket?business_unit_id=${businessUnit}`
    if (pagination.page) url += `&page=${pagination.page}`
    if (pagination.limit) url += `&limit=${pagination.limit}`
    if (search) url += `&search=${search}`
    if (project_state) url += `&project_state=${project_state}`
    if (from) url += `&from=${from}`
    if (to) url += `&to=${to}`
    try {

        const response = await api.get(url);

        dispatch(loadBuckets({
            meta: response.data.meta,
            buckets: response.data.items,
        }))

    } catch (error) {
        const { response } = error as { response: any };
        if (response) {
            dispatch(errorInLoadBuckets((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
        } else {
            dispatch(errorInLoadBuckets((error as { message: string }).message || 'Error al cargar los recursos'))
        }
    }
}

const getOnlyOneBucket = (bucketId: string) => async (dispatch: AppDispatch) => {
    dispatch(processingApiBuckets())

    const url = `/recursos/bucket/${bucketId}`
    try {

        const { data } = await api.get(url);

        if (data) {
            const { id, name, business_unit_id, project } = data
            dispatch(selectedBucket(
                {
                    id,
                    business_unit_id,
                    name,
                    project_id: project.id,
                    project,
                    created_at: data.created_at,
                    updated_at: data.updated_at
                }
            ))
        }

    } catch (error) {
        const { response } = error as { response: any }

        if (response) {
            dispatch(errorInLoadBuckets((response.data as { msg: string }).msg || 'Error al cargar el bucket'))
        } else {
            dispatch(errorInLoadBuckets((error as { message: string }).message || 'Error al cargar el bucket'))
        }
    }
}

export {
    getChargeBuckets,
    getOnlyOneBucket
}