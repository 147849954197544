import { loadFolderWithoutProject } from "../../store/actions/recursos.actions"
import { useAppDispatch } from "../../store/hook/store.hook"


const useReloadFileExplorerWithout = ({
    folderId
}: { folderId: string }) => {

    const dispatch = useAppDispatch()

    const set = (init?: string | null) => {
        dispatch(loadFolderWithoutProject(folderId, init))
    }

    return {
        set,
        actualFolderId: folderId
    }
}

export default useReloadFileExplorerWithout