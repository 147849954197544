import { GridColDef, GridPaginationModel, GridRenderCellParams } from "@mui/x-data-grid-premium";
import { useAppDispatch, useAppSelector } from "../../../store/hook/store.hook";
import DataGridSSR from "../../moleculas/datagrids";
import { Bucket } from "../../../store/schemas/buckets.schema";
import { changePaginated } from "../../../store/slices/buckets.slice";
import { IconButton } from "@mui/material";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ActionsWrapper from "../../atomos/ActionWrapper";
import StyledLink from "../../atomos/MuiLink";
import { useNavigate } from "react-router-dom";
import useStateProject from "../../../hooks/projects/useStateProject";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";


dayjs.extend(utc);
dayjs.extend(timezone);

const DataGridBuckets = () => {
    const { buckets, meta, paginated, loading } = useAppSelector((state) => state.buckets);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { getName } = useStateProject()

    const columns: GridColDef<Bucket>[] = [
        {
            field: 'nombre',
            headerName: 'Nombre',
            flex: 1,
            renderCell: (params: GridRenderCellParams<Bucket>) => (
                <StyledLink to={"/dashboard/businessUnit/" + params.row.id}>
                    {params.row.project!.name}
                </StyledLink>
            )
        },
        {
            field: 'cliente',
            headerName: 'Cliente',
            flex: 1,
            // width: 200,
            renderCell: (params: GridRenderCellParams<Bucket>) => params.row.project!.cliente
        },
        {
            field: 'ga',
            headerName: 'GA',
            flex: 0.5,
            // width: 200,
            renderCell: (params: GridRenderCellParams<Bucket>) => {
                const { project } = params.row
                if (project && project.managements && project.managements.length > 0) {
                    return project.managements.map((management) => management.code).join(', ')
                }

                return params.row.project!.old_ga
            }
        },
        {
            field: 'estado',
            headerName: 'Estado',
            // width: 100,
            renderCell: (params: GridRenderCellParams<Bucket>) => getName(params.row.project!.status_project_id)
        },
        {
            field: 'created_at',
            headerName: 'Fecha de creación',
            // width: 150,
            flex: 0.5,
            renderCell: (params: GridRenderCellParams<Bucket>) =>
                params.row.project!.created_at
                    ? dayjs(params.row.project!.created_at)
                        .tz("America/Bogota")
                        .format("DD/MM/YYYY")
                    : "N/A"
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            headerAlign: 'center',
            renderHeader: () => '',
            flex: 0.5,
            renderCell: (params: GridRenderCellParams<Bucket>) => (
                <ActionsWrapper>
                    <IconButton title="editar" onClick={() => navigate(`/dashboard/project/${params.row.project!.id!}`)}>
                        <EditNoteOutlinedIcon />
                    </IconButton>
                    <IconButton disabled title="eliminar">
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </ActionsWrapper>
            )
        }

    ];

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        dispatch(changePaginated({
            page: model.page,
            limit: model.pageSize
        }))
    };

    return (
        <>
            <DataGridSSR rows={buckets || []} columns={columns}
                rowCount={meta && meta.totalItems ? meta.totalItems : 0}
                paginationModel={{
                    page: paginated!.page,
                    pageSize: paginated!.limit
                }}
                onPaginationModelChange={handlePaginationModelChange}
                loading={loading}
            />
        </>
    );
};

export default DataGridBuckets;
