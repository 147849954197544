
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAppDispatch } from '../../../store/hook/store.hook';
import { validateOtpVisiter } from '../../../store/actions/auth.actions';

const schemaOtp = z.object({
    codeOtp: z.string({
        required_error: "Debe ingresar el OTP.",
    }).min(4, "Debe ser un OTP valido."),
})

type FormDataOTP = z.infer<typeof schemaOtp>

const useOtpForm = ({ email }: { email?: string }) => {

    const dispatch = useAppDispatch();
    const { control: controlOTP, handleSubmit: handleSubmitOTP } = useForm<FormDataOTP>({
        resolver: zodResolver(schemaOtp),
    });


    const onSubmitOtp = (data: FormDataOTP) => {
        dispatch(validateOtpVisiter(data.codeOtp, email!))
    }

    return {
        controlOTP,
        handleSubmitOTP: handleSubmitOTP(onSubmitOtp)
    }
}

export default useOtpForm