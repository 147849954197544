const Terminos = () => {

    return (
        <div style={{
            padding: "1rem"
        }}>
            <h1>TÉRMINOS Y CONDICIONES DE USO DE QR CLOUD</h1>
            <p><strong>Última actualización:</strong> 09/01/2025</p>

            <p>Bienvenido a QR Cloud, una aplicación de propiedad de Industrias CTS S.A.S. Al acceder y utilizar nuestro sitio web, usted acepta los siguientes términos y condiciones. Si no está de acuerdo con estos términos, le pedimos que no utilice nuestra plataforma.</p>

            <h2>1. Descripción del Servicio</h2>
            <p>QR Cloud es una plataforma en línea desarrollada por Industrias CTS S.A.S., que permite el almacenamiento, gestión y descarga de archivos de proyectos. El acceso a los archivos se realiza mediante el escaneo de un código QR proporcionado por los administradores del sitio, seguido del proceso de registro. Los usuarios registrados pueden acceder a los archivos, visualizarlos y descargarlos.</p>

            <h2>2. Registro de Usuario</h2>
            <p>Para acceder a los archivos almacenados en QR Cloud y descargarlos, se requiere completar un formulario de registro. Este formulario solicita los siguientes datos:</p>

            <h3>Datos obligatorios:</h3>
            <ul>
                <li>Correo electrónico</li>
                <li>Nombre completo</li>
            </ul>

            <p>Adicionalmente, se pueden solicitar otros datos opcionales, como:</p>
            <ul>
                <li>Nombre de la empresa que representa</li>
                <li>Número de teléfono</li>
                <li>Ubicación</li>
            </ul>

            <p>Una vez registrado, se enviará un código OTP (One-Time Password) al correo electrónico proporcionado para autenticar la dirección de correo electrónico. Este código debe ser ingresado correctamente para completar el proceso de registro y acceder a la plataforma.</p>

            <h2>3. Autenticación por OTP</h2>
            <p>El proceso de autenticación por OTP garantiza la seguridad de su cuenta. El código OTP enviado a su correo electrónico tiene una validez limitada (1 minuto) y debe ser utilizado dentro de ese tiempo. Si no recibe el código OTP o si el código caduca, puede solicitar uno nuevo a través de la plataforma.</p>

            <h2>4. Privacidad y Protección de Datos</h2>
            <p>Nos tomamos muy en serio la protección de sus datos personales. Los datos recopilados a través del registro serán utilizados exclusivamente para fines de acceso a los archivos de la plataforma y no serán compartidos con terceros sin su consentimiento, salvo en los casos establecidos por la ley. Para más detalles, consulte nuestra <a href="politicas" target="_blank">Política de Privacidad</a>.</p>

            <h2>5. Acceso y Descarga de Archivos</h2>
            <p>El acceso a los archivos almacenados en QR Cloud está restringido a los usuarios que hayan completado correctamente el proceso de registro y escaneado el código QR correspondiente. Una vez registrado y autenticado, el usuario podrá acceder y descargar los archivos que le hayan sido autorizados.</p>

            <p>El uso de los archivos descargados está sujeto a las restricciones y condiciones acordadas en el momento de su acceso. Los archivos solo pueden ser utilizados de acuerdo con los fines establecidos por los administradores de la plataforma.</p>

            <h2>6. Uso Aceptable</h2>
            <p>Al utilizar la plataforma, se compromete a:</p>
            <ul>
                <li>No utilizar el sitio web para realizar actividades ilegales, dañinas o fraudulentas.</li>
                <li>No violar la propiedad intelectual ni los derechos de autor relacionados con los archivos almacenados.</li>
                <li>No compartir sus credenciales de acceso, códigos OTP u otros mecanismos de autenticación con terceros sin nuestra autorización explícita.</li>
            </ul>

            <h2>7. Propiedad Intelectual</h2>
            <p>Los archivos almacenados en QR Cloud son propiedad de sus respectivos creadores o de Industrias CTS S.A.S., según corresponda. El uso de la plataforma no otorga al usuario ningún derecho sobre dichos archivos, salvo el acceso y visualización autorizado. El usuario no puede redistribuir, modificar ni compartir los archivos sin la debida autorización.</p>

            <h2>8. Responsabilidad</h2>
            <p>QR Cloud no se hace responsable por:</p>
            <ul>
                <li>El uso no autorizado de los códigos QR o credenciales de acceso.</li>
                <li>Cualquier daño, pérdida o perjuicio que pueda surgir debido a la interrupción o mal funcionamiento de la plataforma.</li>
                <li>La exactitud de los datos proporcionados por el usuario.</li>
                <li>Cualquier problema relacionado con el proceso de autenticación por OTP o la recepción de códigos.</li>
            </ul>

            <h2>9. Modificaciones</h2>
            <p>Nos reservamos el derecho de modificar estos Términos y Condiciones en cualquier momento. Las modificaciones serán efectivas inmediatamente después de su publicación en el sitio web. Es responsabilidad del usuario revisar regularmente esta sección para mantenerse informado de cualquier cambio.</p>

            <h2>10. Terminación</h2>
            <p>Nos reservamos el derecho de suspender o cancelar el acceso de cualquier usuario que infrinja estos términos y condiciones o que realice actividades ilícitas.</p>

            <h2>11. Ley Aplicable y Jurisdicción</h2>
            <p>Estos Términos y Condiciones se regirán por las leyes de Colombia. Cualquier disputa relacionada con el uso de la plataforma será resuelta en los tribunales competentes de Santander.</p>

            <h2>12. Contacto</h2>
            <p>Si tiene preguntas o inquietudes sobre estos Términos y Condiciones, puede ponerse en contacto con nosotros a través del siguiente correo electrónico: <a href="mailto:info@industriascts.com">info@industriascts.com</a>.</p>
        </div>
    )
}

export default Terminos