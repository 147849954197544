import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState, { ProfileState } from "../schemas/profile.schema";

export const profileSlice = createSlice({
    name: 'profile',
    initialState: initialState,
    reducers: {
        loadProfile: (state, action: PayloadAction<ProfileState>) => {
            state.id = action.payload.id;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.rol = action.payload.rol;
        },
        updateProfile: (state, action: PayloadAction<ProfileState>) => {
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.rol = action.payload.rol;
        },
        updateLocation: (state, action: PayloadAction<{ latitud: number, longitud: number } | null>) => {
            if (action.payload) {
                state.location = {
                    latitud: action.payload.latitud,
                    longitud: action.payload.longitud
                }
            }
        },
        resetProfile: (state) => {
            state.id = null;
            state.name = '';
            state.email = '';
            state.rol = '';
        }
    }
})

export const { loadProfile, updateProfile, resetProfile, updateLocation } = profileSlice.actions;

export default profileSlice.reducer
