import { Container, styled, Typography } from "@mui/material"
import DataGridTemplates from "../../components/organismos/plantillas/DataGridTemplates"
import { useLoaderTemplates } from "../../hooks/templates/useLoaderTemplates"
import { useEffect } from "react"
import TemplateActionsController from "../../components/moleculas/templates/ActionsController"
import TemplateFilterController from "../../components/moleculas/templates/FilterController"

const Titled = styled(Typography)(({ }) => ({
    textTransform: 'capitalize',

}))

const Templates = () => {

    const { loadTemplates, paginated } = useLoaderTemplates()

    useEffect(() => {
        loadTemplates()
    }, [paginated])

    return (
        <Container>
            <Titled variant="h3">
                Plantillas
            </Titled>
            <TemplateFilterController />
            <TemplateActionsController />
            <DataGridTemplates />
        </Container>
    )
}

export default Templates