import { getBusinessUnits } from "../../store/actions/businessUnit.actions"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook"


export const useBusinessUnit = () => {

    const businessUnitState = useAppSelector((state) => state.businessUnit)
    const dispatch = useAppDispatch()

    const loadAllBusinessUnits = () => dispatch(getBusinessUnits())

    return {
        businessUnitState,
        loadAllBusinessUnits
    }
}