import { ModalProps, styled } from "@mui/material"
import { FC } from "react"
import ModalComponent from "../../moleculas/Modal"

const Wrap = styled('div')(({theme}) => ({
    height: '90vh',
    width: '70vw',
    [theme.breakpoints.down('sm')]: {
        width: 'auto'
    }
}))

interface IWrapperViewerProps extends ModalProps {
    childrens?: React.ReactNode[]
}

const WrapperViewer: FC<IWrapperViewerProps> = ({
    childrens,
    ...props
}) => {

    return (
        <ModalComponent {...props} padding={0}>
            <Wrap>
                {childrens}
            </Wrap>
        </ModalComponent>
    )
}

export default WrapperViewer