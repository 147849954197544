import { z } from "zod"
// import { useAppSelector } from "../../store/hook/store.hook";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const schemaUbication = z.object({
    direccion: z.string(),
    latitude: z.number().optional().nullable(),
    longitude: z.number().optional().nullable(),
}).optional().nullable()

const management = z.object({
    id: z.string().optional(),
    code: z.string(),
})

const schemaManagement = z.array(management, {required_error: "Debe ingresar al menos un codigo."}).min(1, "Debe ingresar al menos un codigo.")


const schema = z.object({
    name: z.string({
        required_error: "Debe ingresar el Nombre del Proyecto.",
    }).min(1, "Debe ingresar un Nombre valido."),
    cliente: z.string({
        required_error: "Debe ingresar el Cliente.",
    }).min(5, "Debe ingresar un Cliente valido."),
    status_project_id: z.number({
        required_error: "Debe ingresar el Status.",
    }),
    warranty: z.string().optional(),
    description: z.string().optional(),
    managements:schemaManagement,
    ubication: schemaUbication.optional()
})


export type FormDataEditableType = z.infer<typeof schema>

const useFormProject = () => {

    const {  formState, trigger, control, reset, getValues, setValue } = useForm<FormDataEditableType>({
        resolver: zodResolver(schema),
        defaultValues: {
            name: '',
            cliente: '',
            status_project_id: 3,
            warranty: '',
            description: '',
            managements: [],
            ubication: {
                direccion: '',
                latitude: 0,
                longitude: 0
            }
        }
    });

    return {
        control,
        formState,
        trigger,
        getValues,
        reset,
        errors: formState.errors,
        setValue
    };
}

export default useFormProject