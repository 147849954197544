import { useSortable } from "@dnd-kit/sortable"
import { CSS } from '@dnd-kit/utilities';
import { FC } from "react"
import WrapperButton from "../../atomos/explorer/wrapperButton";
import ControlItem from "../../file-explorer/entity/control";
import ButtonFolder from "../../atomos/explorer/ButtonFolder";
import LabelFolder from "../../atomos/explorer/LabelFolder";
import ItemWrapper from "../../atomos/dnd/ItemWrapper";

interface ISortableItemProps {
    sequence: number
    name: string
    idItem: string
    createdDate: string
    clickAccess: (id: string) => void
    isAdmin?: boolean
}

const SortableItem: FC<ISortableItemProps> = ({ sequence, name, idItem, createdDate, clickAccess, isAdmin }) => {

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        
        id: sequence,
    })

    const infoProps = { idItem, name, createdDate, type: 'folder' }

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const handleAllClick = () => {
        clickAccess(idItem)
    }

    return (
        <ItemWrapper ref={setNodeRef} style={style} {...attributes}>
            <WrapperButton title={name}>
                <ControlItem infoProps={infoProps} listeners={listeners} isAdmin={isAdmin}/>
                <ButtonFolder onClick={handleAllClick} />
                <LabelFolder onClick={handleAllClick}>
                    {sequence}. {name}
                </LabelFolder>
            </WrapperButton>
        </ItemWrapper>
    )

}

export default SortableItem