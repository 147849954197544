// src/slices/auth.actions.ts
import { AxiosResponse } from 'axios';
import { AppDispatch } from '..';
import api from '../../services/axios';
import { forceLoad, loginFailure, loginRequest, loginSuccess, shouldRegister, validateOtp } from '../slices/auth.slice';
import { loadProfile } from '../slices/profile.slice';

export const login = (email: string, password: string) => async (dispatch: AppDispatch) => {
  dispatch(loginRequest());

  try {
    const response = await api.post('/auth/login', { email, password });

    const { access_token, refresh_token } = response.data;

    // Guardar tokens en redux y localStorage
    dispatch(loginSuccess({ accessToken: access_token, refreshToken: refresh_token }));

    // Obtener info del perfil y cargarla en el slice de perfil
    dispatch(loadProfile({
      email: response.data.email,
      name: response.data.nombre,
      rol: response.data.rol,
      location: null
    }));
    dispatch(forceLoad(false));
  } catch (error: any) {
    dispatch(loginFailure(error.message));
  }
};

export const loginVisiter = (email: string, name?: string, empresa?: string, telefono?: string) => async (dispatch: AppDispatch) => {

  dispatch(loginRequest());
  let response: AxiosResponse<any, any>;
  try {


    response = await api.post('/auth/login-visit', { email, name, empresa, telefono });

    const { message } = response.data;

    if (message) {
      dispatch(validateOtp())
    }

    dispatch(forceLoad(false));
  } catch (error: any) {
    const { response } = error
    if (response) {

      if (response.data.type === 'QueryFailedError') {
        dispatch(shouldRegister())
      }

      dispatch(loginFailure(response.data.type));
    }

  }

}

export const validateOtpVisiter = (otp: string, email: string) => async (dispatch: AppDispatch) => {
  dispatch(loginRequest());

  try {
    const response = await api.get('/auth/otp-access/' + otp + "?email=" + email);

    const { access_token } = response.data;

    // Guardar tokens en redux y localStorage
    dispatch(loginSuccess({ accessToken: access_token }));

    // Obtener info del perfil y cargarla en el slice de perfil
    dispatch(loadProfile({
      email: response.data.email,
      name: response.data.name,
      rol: response.data.rol,
      location: null
    }));
    dispatch(forceLoad(false));
  } catch (error: any) {
    const { response } = error
    if (response && response.data.type === 'invalid_otp') {
      dispatch(loginFailure('El codigo es invalido, intentelo de nuevo.'));
    } else {
      dispatch(loginFailure(error.message));
    }

  }
}
