import { loadRecurso } from "../../store/actions/recursos.actions"
import { useAppDispatch } from "../../store/hook/store.hook"

interface RecursosLoaderProps {
    recursoId: string
    businessUnit?: string | null
}

const useRecursosLoader = ({ recursoId, businessUnit }: RecursosLoaderProps) => {

    const dispatch = useAppDispatch()

    const getRecurso = () => {
        if (businessUnit) {
            dispatch(loadRecurso(recursoId, businessUnit))
        } else {
            dispatch(loadRecurso(recursoId))
        }
    }

    return {
        getRecurso
    }
}

export default useRecursosLoader