import { useEffect, useState } from "react";
import api from "../../../services/axios";

interface IUseLoaderProps {
    fileProps: any;
}

const useFileLoader = ({ fileProps }: IUseLoaderProps) => {
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const loadFile = async () => {
        setLoading(true);
        setError(null);
        try {
            // Petición para obtener el enlace firmado
            const response = await api.get(`/recursos/attachment/${fileProps.filePath}`);
            const fileUrl = response.data; // Supongamos que la URL firmada está en `response.data`

            // Descarga del archivo a través del enlace firmado
            const fileResponse = await fetch(fileUrl);
            if (!fileResponse.ok) {
                throw new Error("Error al descargar el archivo");
            }

            const blob = await fileResponse.blob();
            const fileName = fileProps.fileName || "archivo_descargado";
            const downloadedFile = new File([blob], fileName, { type: blob.type });

            // Guardar el archivo en el estado
            setFile(downloadedFile);
        } catch (err: any) {
            setError(err.message || "Error al cargar el archivo");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const downloadFile = async () => {
        try {
            setLoading(true);
            setError(null);

            // Obtiene la URL firmada del servidor
            const response = await api.get(`/recursos/attachment/${fileProps.filePath}`);
            const fileUrl = response.data;

            // Realiza la solicitud para obtener el archivo como blob
            const fileResponse = await fetch(fileUrl);
            if (!fileResponse.ok) throw new Error("Error al descargar el archivo");

            const blob = await fileResponse.blob();

            // Crea un enlace temporal para forzar la descarga
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute("download", fileProps.fileName || "archivo_descargado");
            link.style.display = "none";

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Libera el objeto URL para evitar fugas de memoria
            URL.revokeObjectURL(url);
        } catch (err: any) {
            setError(err.message || "Error al descargar el archivo");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (fileProps?.filePath) {
            loadFile();
        }
    }, [fileProps?.filePath]);

    return {
        file,
        error,
        loading,
        downloadFile
    };
};

export default useFileLoader;
