import { Skeleton, Stack, styled, Typography } from "@mui/material";
import { FC } from "react";
import { useAppSelector } from "../../../store/hook/store.hook";


import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";


dayjs.extend(utc);
dayjs.extend(timezone);

const Bar = styled('div')(({ }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
}))

const TitledText = styled(Typography)(({ theme }) => ({
    lineHeight: ".8",
    fontWeight: 'bold',
    marginBottom: '1rem',
    fontSize: "2.5rem",
    textTransform: "uppercase",
    [theme.breakpoints.down('xl')]: {
        fontSize: "2.5rem",
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: "2.5rem",
    },
    [theme.breakpoints.down('md')]: {
        lineHeight: "1",
        fontSize: "2rem",
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: "1.5rem",
    },
}));

const TextWithSmallerFont = styled(Typography)(({ theme }) => ({
    fontSize: "1.2rem", // Estilo inicial que resta uno al tamaño base de TitledText
    lineHeight: "1.5",
    textTransform: "uppercase",
    [theme.breakpoints.down('xl')]: {
        fontSize: "1.5rem", // Calcula según el tamaño ajustado de TitledText
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: "1.5rem", // Calcula según el tamaño ajustado de TitledText
    },
    [theme.breakpoints.down('md')]: {
        fontSize: "1.3rem", // Calcula según el tamaño ajustado de TitledText
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: "1rem",
    }
}));

interface InfoBarProps {
}

const InfoBar: FC<InfoBarProps> = ({ }) => {

    const { selected: root, loading } = useAppSelector((state) => state.projects)
    const capitalizedProject = String(root?.name)?.charAt(0).toUpperCase() + String(root?.name)?.slice(1).toLowerCase();
    const capitalizedClient = String(root?.cliente)?.charAt(0).toUpperCase() + String(root?.cliente)?.slice(1).toLowerCase();

    if (loading || !root) {
        return (
            <Stack spacing={1}>
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" />
                <Skeleton variant="rounded" />
            </Stack>
        )
    }

    return (
        <Bar>
            <TitledText variant="h1" color="primary" fontFamily="TitilliumWeb" fontWeight={"bold"} >
                {capitalizedProject}
            </TitledText>
            <TextWithSmallerFont>
                <b>Cliente:</b> {capitalizedClient}
            </TextWithSmallerFont>
            <TextWithSmallerFont>
                <b>GA:</b> {
                    root.managements && root.managements.length > 0 ? root.managements.map((management) => management.code).join(", ") : root?.old_ga
                }
            </TextWithSmallerFont>
            <TextWithSmallerFont>
                <b>Fecha:</b> {
                    root.created_at
                        ? dayjs(root.created_at)
                            .tz("America/Bogota")
                            .format("DD/MM/YYYY")
                        : "N/A"
                }
            </TextWithSmallerFont>
        </Bar>
    )
}

export default InfoBar