
import { FC } from "react";
import { styled, Typography } from "@mui/material";
import FileForm from "../../moleculas/folders/fileform";
import useFileForm from "../../../hooks/folders/useFileForm";

const Wrap = styled('div')(({ }) => ({
    minWidth: '25vw',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
}))

interface IFolderCreatedProps {
    handleCloseModal: () => void
}

const FileCreated: FC<IFolderCreatedProps> = ({ handleCloseModal }) => {

    const { control, handleSubmit } = useFileForm()
    
    return (
        <Wrap>
            <Typography variant="h5" color="secondary">Información del archivo</Typography>
            <FileForm control={control} handleCloseModal={handleCloseModal} handleSubmit={handleSubmit}/>
        </Wrap>
    );
};

export default FileCreated