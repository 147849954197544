import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook";
import { updateFolder } from "../../store/actions/folders.action";


const schema = z.object({
    id: z.string({
        required_error: "Debe ingresar el ID de la Carpeta.",
    }),
    name: z.string({
        required_error: "Debe ingresar el Nombre de la Carpeta.",
    }).min(1, "Debe ingresar un Nombre valido."),
    description: z.string().optional(),
})


export type FolderEditedFormTypeSchema = z.infer<typeof schema>


interface FolderEditedFormProps {
    infoProps: any
}


const useEditedFolder = ({ infoProps }: FolderEditedFormProps) => {

    const dispatch = useAppDispatch()
    const { selected } = useAppSelector((state) => state.buckets)
    const { opened } = useAppSelector((state) => state.fileExplorer)

    const { control, handleSubmit, setValue } = useForm<FolderEditedFormTypeSchema>({
        resolver: zodResolver(schema),
        defaultValues: {
            id: '',
            name: '',
            description: '',
        }
    });

    useEffect(() => {
        if (infoProps) {
            setValue('name', infoProps.name)
            setValue('description', infoProps.description)
            setValue('id', infoProps.idItem)
        }
    }, [infoProps])

    const onSubmit = (data: FolderEditedFormTypeSchema) => {
        dispatch(updateFolder(data.id, {
            name: data.name,
            description: data.description || ''
        }, selected, opened))
    }

    return {
        control,
        handleSubmit: handleSubmit(onSubmit),
        setValue
    }
}

export default useEditedFolder