import { useEffect, useState, useCallback } from "react";
import { getInfiniteProjects } from "../../services/projects";
import { Project } from "../../store/schemas/project.schema";
import { useAppDispatch } from "../../store/hook/store.hook";
import { getAllTemplates } from "../../store/actions/templates.action";

const useGetProjectsAndTemplates = () => {
    const dispatch = useAppDispatch();
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState("");

    // Función para cargar proyectos
    const loadProjects = useCallback(async (currentPage: number, search?: string) => {
        setIsLoading(true);
        try {
            const params = new URLSearchParams();
            params.append("page", String(currentPage));
            params.append("limit", "15");
            search && params.append("search", search);

            const response = await getInfiniteProjects(params) || [];

            setProjects((prevProjects) => [...prevProjects, ...response]); // Agregar nuevos proyectos al estado
        } catch (error) {
            console.error("Error loading projects:", error);
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        dispatch(getAllTemplates())
    },[])

    // Efecto para cargar la primera página al montar
    useEffect(() => {
        if (search !== "") {
            setPage(1);
            setProjects([]);
            loadProjects(1, search);
        } else {
            loadProjects(page);
        }
    }, [page, search]);

    // Función para cargar la siguiente página
    const loadNextPage = () => {
        setPage(page + 1);
        // loadProjects(page);
    };

    return {
        projects,
        loadNextPage, // Nueva función para cargar la siguiente página
        page,
        isLoading,
        setSearch
    };
};

export default useGetProjectsAndTemplates;