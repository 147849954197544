import { styled } from "@mui/material";


const WrapperButton = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '7vw',
    transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
        transform: 'scale(1.1)',
    },
    [theme.breakpoints.down('lg')]: {
        width: 'auto'
    },
    [theme.breakpoints.down('md')]: {
        width: 'auto'
    },
}))


export default WrapperButton