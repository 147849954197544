import { Container } from "@mui/material"
import Folder from "./entity/folder"
import useFileExplorer from "../../hooks/fileExplorer/useFileExplorer"
import TargetsWrapper from "../atomos/TargetsWrap"
import TargeGridItem from "../atomos/TargetItem"
import { FC } from "react"
import { useNavigate } from "react-router-dom"



const FileExplorer: FC<{ proyectoId: string }> = ({ proyectoId }) => {

    const navigation = useNavigate()
    const { structure } = useFileExplorer()

    const handleClick = (folderId: string) => {
        navigation(`/dashboard/recursos/${proyectoId}/folders/${folderId}`)
    }

    return (
        <Container maxWidth={false} sx={{ padding: '0px !important'}}>
            <TargetsWrapper columns={{ xs: 2, sm: 6, md: 12, lg: 12, xl: 14 }}>
                {
                    structure.map((flder) => {

                        return (
                            <TargeGridItem size={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} key={flder.id}>
                                <Folder sequence={flder.sequence} name={flder.name} id={flder.id} createdDate={new Date().toString()} clickAccess={handleClick}/>
                            </TargeGridItem>
                        )
                    })
                }
            </TargetsWrapper>

        </Container>
    )
}

export default FileExplorer