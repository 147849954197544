import { Grid2, Grid2Props, styled } from "@mui/material";
import { FC } from "react";

const GridItem = styled(Grid2)(({ }) => ({
    textAlign: 'center',
}))


const TargeGridItem: FC<Grid2Props> = ({ children, ...props }) => {

    return (
        <GridItem size={{ xs: 12, sm: 2, md: 2, lg: 3, xl: 3 }} {...props} >
            {children}
        </GridItem>
    )
}

export default TargeGridItem
