import { Button, styled } from "@mui/material";
import { FC } from "react";
import { useAppDispatch } from "../../../../store/hook/store.hook";
import { clearSelectedProject } from "../../../../store/slices/project.slice";

const Wrap = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: theme.spacing(0, 1),
    gap: '1rem'
}))

interface IHeaderProjectActionProps {
    setOpen: (open: boolean) => void
}

const HeaderProjectAction: FC<IHeaderProjectActionProps> = ({setOpen}) => {

    const dispatch = useAppDispatch()

    return (
        <Wrap>
            <Button variant="outlined" onClick={() => setOpen(true)}>
                Seleccionar Proyecto
            </Button>
            <Button variant="outlined" onClick={() => dispatch(clearSelectedProject())}>
                Limpiar
            </Button>
        </Wrap>
    )
}

export default HeaderProjectAction