export type ubicationType = {
    id?: string
    direccion: string
    latitude?: number
    longitude?: number
}

export type ManagementsType = {
    code: string;
    id?: string | undefined;
}

export type StatusProject = {
    id: number;
    name: string
}

export type BusinessUnit = {
    id: string;
    name: string;
}

export type BucketType = {
    id: string;
    name?: string;
    business_unit?: BusinessUnit
}

export type Project = {
    id?: string;
    name: string;
    cliente: string;
    old_ga?: string;
    created_at?: Date;
    warranty?: string
    description?: string
    status_project_id: number
    buckets?: Array<BucketType>
    status?: StatusProject
    managements?: Array<ManagementsType> | []
    ubication?: ubicationType | null
    updated_at?: Date;
}

export interface ProjectState {
    projects?: Array<Project>
    loading?: boolean
    error?: string | null
    selected?: Project | null
}

const initialState: ProjectState = {
    projects: [],
    loading: false,
    error: null,
    selected: null
}

export default initialState