
const Politicas = () => {

    return (
        <div
            style={{
                padding: "1rem"
            }}
        >
            <h1>POLÍTICA DE PRIVACIDAD DE QR CLOUD</h1>
            <p><strong>Última actualización:</strong> 09/01/2025</p>

            <p>En QR Cloud, operado por Industrias CTS S.A.S., respetamos y protegemos la privacidad de nuestros usuarios. Esta Política de Privacidad tiene como objetivo informarle sobre cómo recopilamos, usamos, almacenamos y protegemos su información personal de acuerdo con la Ley 1581 de 2012 de Protección de Datos Personales en Colombia y otras normativas aplicables.</p>

            <h2>1. Información que Recopilamos</h2>
            <p>Para brindarle acceso a los archivos de proyectos y permitirle descargarlos, recopilamos la siguiente información personal:</p>

            <h3>Datos obligatorios:</h3>
            <ul>
                <li>Correo electrónico</li>
                <li>Nombre completo</li>
            </ul>

            <h3>Datos opcionales (pueden ser proporcionados voluntariamente):</h3>
            <ul>
                <li>Nombre de la empresa que representa</li>
                <li>Número de teléfono</li>
                <li>Ubicación</li>
            </ul>

            <p>Adicionalmente, se recopila información técnica automáticamente cuando accede a nuestro sitio web, como direcciones IP, tipo de dispositivo, sistema operativo, navegador y otras estadísticas relacionadas con el uso del sitio.</p>

            <h2>2. Uso de la Información</h2>
            <p>Recopilamos y utilizamos su información personal para los siguientes fines:</p>
            <ul>
                <li>Para permitir el registro y autenticación de usuarios.</li>
                <li>Para garantizar la seguridad de su cuenta, incluyendo la autenticación mediante código OTP (One-Time Password).</li>
                <li>Para gestionar el acceso y la descarga de los archivos que almacena y comparte en QR Cloud.</li>
                <li>Para enviar notificaciones relacionadas con el funcionamiento de la plataforma, como actualizaciones y cambios en los términos y condiciones.</li>
                <li>Para responder a consultas, proporcionar asistencia al usuario y mejorar nuestra plataforma.</li>
            </ul>

            <h2>3. Autenticación de Correo Electrónico mediante OTP</h2>
            <p>Como parte del proceso de registro, le enviaremos un código OTP a su correo electrónico para autenticar su dirección de correo. Este código es de un solo uso y tiene un tiempo de validez limitado. Recopilamos su correo electrónico con el fin de garantizar la seguridad y protección de su cuenta en QR Cloud.</p>

            <h2>4. Protección de Datos Personales</h2>
            <p>Nos comprometemos a proteger su información personal con el más alto nivel de seguridad. Hemos implementado medidas técnicas y organizativas para evitar el acceso no autorizado, la alteración, divulgación o destrucción de sus datos personales.</p>

            <p>Sin embargo, debe tener en cuenta que ningún sistema de transmisión de datos a través de Internet o almacenamiento electrónico es completamente seguro, y no podemos garantizar la seguridad absoluta de los datos.</p>

            <h2>5. Principios para el Tratamiento de Datos Personales</h2>
            <p>En cumplimiento con la Ley 1581 de 2012 y sus reglamentaciones, los datos personales que recopilamos se tratarán conforme a los siguientes principios:</p>
            <ul>
                <li><strong>Legalidad:</strong> El tratamiento de sus datos personales se realizará conforme a la ley.</li>
                <li><strong>Finalidad:</strong> Los datos personales serán recolectados con fines específicos, legítimos y no serán tratados de manera incompatible con esos fines.</li>
                <li><strong>Libertad:</strong> Su información personal solo será tratada con su consentimiento, salvo en los casos permitidos por la ley.</li>
                <li><strong>Veracidad:</strong> Los datos personales serán exactos, completos y actualizados.</li>
                <li><strong>Accesibilidad:</strong> Los datos podrán ser consultados por usted en cualquier momento.</li>
                <li><strong>Confidencialidad:</strong> La información personal será tratada con la máxima confidencialidad y no será divulgada sin su consentimiento, salvo por requerimientos legales.</li>
            </ul>

            <h2>6. Derechos de los Titulares de los Datos</h2>
            <p>De acuerdo con la Ley 1581 de 2012, usted tiene los siguientes derechos sobre sus datos personales:</p>
            <ul>
                <li><strong>Derecho de acceso:</strong> Puede consultar en cualquier momento los datos personales que hemos recopilado sobre usted.</li>
                <li><strong>Derecho de actualización, rectificación y supresión:</strong> Puede solicitar que se actualicen, rectifiquen o eliminen sus datos personales si son incorrectos, inexactos o incompletos.</li>
                <li><strong>Derecho de revocación del consentimiento:</strong> Puede revocar su consentimiento para el tratamiento de sus datos en cualquier momento, lo que implicará la eliminación de su cuenta y de la información asociada.</li>
                <li><strong>Derecho de oposición:</strong> Puede oponerse al tratamiento de sus datos personales cuando lo considere necesario, en situaciones específicas permitidas por la ley.</li>
                <li><strong>Derecho a solicitar prueba de la autorización:</strong> Puede solicitar que le demos prueba de que ha otorgado su consentimiento para el tratamiento de sus datos personales.</li>
            </ul>

            <p>Para ejercer cualquiera de estos derechos, por favor comuníquese con nosotros utilizando los medios proporcionados en la sección de contacto.</p>

            <h2>7. Compartir y Divulgar Información</h2>
            <p>No compartimos ni vendemos su información personal a terceros. Sin embargo, podemos divulgar su información personal en los siguientes casos:</p>
            <ul>
                <li>Cuando sea necesario para cumplir con la ley o un proceso legal, como una orden judicial.</li>
                <li>Para proteger los derechos, propiedad o seguridad de Industrias CTS S.A.S., nuestros usuarios o el público en general.</li>
                <li>A nuestros proveedores de servicios de confianza que nos ayudan en el funcionamiento de la plataforma, siempre que estén sujetos a las mismas obligaciones de protección de datos que nosotros.</li>
            </ul>

            <h2>8. Uso de Cookies</h2>
            <p>QR Cloud utiliza cookies y tecnologías similares para mejorar la experiencia del usuario en la plataforma. Las cookies son pequeños archivos de texto que se almacenan en su dispositivo para recopilar información sobre el uso de la plataforma. Usted puede ajustar la configuración de su navegador para rechazar las cookies, aunque esto puede afectar su experiencia de uso.</p>

            <h2>9. Modificaciones a esta Política de Privacidad</h2>
            <p>Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Las modificaciones entrarán en vigor cuando se publiquen en el sitio web de QR Cloud. Le recomendamos que revise esta política regularmente para estar informado sobre cómo protegemos su información.</p>

            <h2>10. Contacto</h2>
            <p>Si tiene preguntas o inquietudes sobre esta Política de Privacidad o sobre el tratamiento de su información personal, puede ponerse en contacto con nosotros a través del siguiente correo electrónico: <a href="mailto:info@industriascts.com">info@industriascts.com</a>.</p>

        </div>
    )
}

export default Politicas