import { styled } from "@mui/material";

const LabelFolder = styled('p')(({  }) => ({
  width: '100%',
  overflow: 'hidden',
  // color: theme.palette.primary.main,
  // fontWeight: 'bold',
  margin: 0,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis', // Mantiene compatibilidad con textos largos.
  maskImage: 'linear-gradient(to right, black 90%, transparent 100%)',
  WebkitMaskImage: 'linear-gradient(to right, black 90%, transparent 100%)',
  cursor: 'pointer',
}));

export default LabelFolder;
