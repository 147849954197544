import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState, { Bucket, BucketState } from "../schemas/buckets.schema";



const bucketSlice = createSlice({
    name: 'buckets',
    initialState: initialState,
    reducers: {
        loadBuckets: (state, action: PayloadAction<BucketState>) => {
            state.buckets = action.payload.buckets
            state.meta = action.payload.meta
            state.loading = false
            state.error = null
            // state.paginated = state.paginated
        },
        errorInLoadBuckets: (state, action: PayloadAction<string>) => {
            state.buckets = []
            state.loading = false
            state.meta = null
            state.error = action.payload
        },
        changePaginated: (state, action: PayloadAction<{ page: number, limit: number }>) => {
            state.paginated = action.payload
            state.loading = false
        },
        selectedBucket: (state, action: PayloadAction<Bucket>) => {
            state.selected = action.payload
            state.loading = false
        },
        processingApiBuckets: (state) => {
            state.loading = true
            state.error = null
            state.selected = null
        }
    }
})


export const { loadBuckets, errorInLoadBuckets, changePaginated, processingApiBuckets, selectedBucket } = bucketSlice.actions
export default bucketSlice.reducer