import { styled } from "@mui/material";

const ItemWrapper = styled('div')(() => ({
    touchAction: 'none',
    height: 'auto',
    width: 'auto',
    textAlign: 'center',
    placeSelf: 'center',
}))

export default ItemWrapper