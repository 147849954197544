import { FC } from 'react';
import ButtonAtom from '../../atomos/Button';
import LoginBox from '../../atomos/login/LoginBox';
import WrapperBox from '../../atomos/login/Wrapper';
import { Box, LinearProgress, styled, Typography } from '@mui/material';


const TitledText = styled(Typography)(({ theme }) => ({
    fontSize: "5rem",
    lineHeight: ".8",
    [theme.breakpoints.down('xl')]: {
        fontSize: "4.5rem",
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: "3.5rem",
    },
}));

const TextWithSmallerFont = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem", // Estilo inicial que resta uno al tamaño base de TitledText
    lineHeight: "1.2",
    [theme.breakpoints.down('xl')]: {
        fontSize: "1.5rem", // Calcula según el tamaño ajustado de TitledText
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: "1.5rem", // Calcula según el tamaño ajustado de TitledText
    },
    [theme.breakpoints.down('md')]: {
        fontSize: "1.3rem", // Calcula según el tamaño ajustado de TitledText
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: "1.5rem",
    }
}));

const LoginContent: FC<{loader: boolean, progress: number}> = ({loader, progress}) => (
    <LoginBox>
        <WrapperBox position="flex-start">
            <TitledText variant="h1" fontFamily="TitilliumWeb">QR Cloud</TitledText>
            <TitledText variant="h1" color="primary" fontFamily="BoldTitilliumWeb">Industrias CTS</TitledText>
            <TextWithSmallerFont fontFamily="TitilliumWeb" color="secondary" mt={3}>
                Accede a toda la documentación de tus proyectos en un solo lugar.
            </TextWithSmallerFont>
            <ButtonAtom sx={{ width: '20%', marginTop: '3%' }} onClick={() => window.open('https://industriascts.com/contacto')}>Más info</ButtonAtom>
        </WrapperBox>
        <Box sx={{ width: '100%', display: loader ? 'block' : 'none' }}>
            <LinearProgress  variant='determinate' value={progress}/>
        </Box>
    </LoginBox>
);

export default LoginContent;
