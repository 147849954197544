import { Control, Controller } from "react-hook-form"
import { FC } from "react"
import { Autocomplete, Chip, Divider, Grid2, MenuItem, styled, TextField } from "@mui/material"
import useStateProject from "../../../../hooks/projects/useStateProject"
import { FormDataEditableType } from "../../../../hooks/projects/useFormProject"

const Item = styled(Grid2)(({ }) => ({
    placeItems: 'center'
}))

const FieldItem = styled(TextField)(({ }) => ({
    width: '80%',
}))

const SelectItem = styled(MenuItem)(({ }) => ({

}))
const AutocompleteField = styled(Autocomplete)(({ }) => ({
    width: '80%',
}))

interface IProjectFormProps {
    control: Control<FormDataEditableType, any>
}


const EditableProjectForm: FC<IProjectFormProps> = ({ control }) => {

    const { getAll } = useStateProject()
    const allStates = getAll()

    return (
        <>
            <Item size={1}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <FieldItem
                                label="Nombre del Proyecto"
                                error={!!fieldState.error}
                                {...field}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                            />
                        )
                    }}
                />
            </Item>
            <Item size={1}>
                <Controller
                    name="cliente"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <FieldItem
                                label="Nombre del Cliente"
                                error={!!fieldState.error}
                                {...field}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                            />
                        )
                    }}
                />
            </Item>
            <Item size={1}>
                <Controller
                    name="status_project_id"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <FieldItem
                                select
                                label="Estado del Proyecto"
                                error={!!fieldState.error}
                                {...field}
                                value={String(field.value)}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                            >
                                {
                                    allStates.map((state) => (
                                        <SelectItem key={state.id} value={state.id}>{state.name}</SelectItem>
                                    ))
                                }
                            </FieldItem>
                        )
                    }}
                />
            </Item>
            <Item size={1}>
                <Controller
                    name="warranty"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <FieldItem
                                label="Garantia del proyecto"
                                error={!!fieldState.error}
                                {...field}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                            />
                        )
                    }}
                />
            </Item>
            <Item size={1}>
                <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <FieldItem
                                label="Descripcion del proyecto"
                                error={!!fieldState.error}
                                {...field}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                            />
                        )
                    }}
                />
            </Item>
            <Item size={1}>
                <Controller
                    name="ubication.direccion"
                    control={control}
                    render={({ field, fieldState }) => {
                        return (
                            <FieldItem
                                label="Ubicacion del proyecto"
                                error={!!fieldState.error}
                                {...field}
                                helperText={fieldState.error ? fieldState.error.message : ''}
                            />
                        )
                    }}
                />
            </Item>
            <Item size={2}>
                <Divider />
            </Item>
            <Item size={1}>
                <Controller
                    name="managements"
                    control={control}
                    defaultValue={[]} // Inicializamos como array vacío
                    render={({ field, fieldState }) => (
                        <AutocompleteField
                            multiple
                            freeSolo // Permite valores definidos por el usuario
                            id="tags-managements"
                            options={[]} // Sin opciones predefinidas
                            value={field.value} // Conecta el estado con react-hook-form
                            onChange={(...props) => {
                                const newValue = props[1] as any; // El array actualizado de valores
                        
                                // Procesamos los valores
                                const processedValues = newValue.map((item: any) => {
                                    // Si el valor es una cadena (nuevo valor ingresado), lo transformamos a un objeto
                                    if (typeof item === "string") {
                                        return {
                                            code: item.toUpperCase(), // Convertimos el texto a mayúsculas
                                        };
                                    }
                        
                                    // Si el valor es un objeto, convertimos solo el `code` a mayúsculas
                                    return {
                                        id: item.id, // Conservamos el `id` si existe
                                        code: item.code.toUpperCase(),
                                    };
                                });
                        
                                // Actualizamos el estado
                                field.onChange(processedValues);
                            }}
                            renderTags={(value: any[], getTagProps) =>
                                value.map((option, index) => {
                                    const { key, ...tagProps } = getTagProps({ index });
                                    return (
                                        <Chip
                                            variant="outlined"
                                            label={option.code}
                                            {...tagProps}
                                        />
                                    );
                                })
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Gestiones"
                                    placeholder="Agregar valores"
                                    InputProps={{
                                        ...params.InputProps,
                                        inputProps: {
                                            ...params.inputProps,
                                            onInput: (event: React.ChangeEvent<HTMLInputElement>) => {
                                                event.target.value = event.target.value.toUpperCase();
                                            },
                                        },
                                    }}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error ? fieldState.error.message : ''}
                                />
                            )}
                        />
                    )}
                />
            </Item>
        </>
    )
}

export default EditableProjectForm