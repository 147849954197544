import Grid, { Grid2Props } from '@mui/material/Grid2';
import { FC, ReactNode } from 'react';

interface CustomLoginGridProps extends Grid2Props {
  children?: ReactNode
}

const LoginGrid: FC<CustomLoginGridProps> = ({ children, ...props }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        flex: 1,
        height: '100%',
        alignItems: 'stretch',
      }}
      {...props}
    >
      {
        children
      }
    </Grid>
  );
};

export default LoginGrid;
