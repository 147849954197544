import { Input, List as ListWrapper, ListItem as ListItemStyled, styled } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../store/hook/store.hook";
import { getInfoProject } from "../../../store/actions/projects.action";

const List = styled(ListWrapper)(() => ({
    padding: 0,
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "90%",
    minWidth: "50vw",
}));

const ListItem = styled(ListItemStyled)(({ theme }) => ({
    ":hover": {
        backgroundColor: theme.palette.action.hover,
        cursor: "pointer",
    },
}));

const WrapperInfiniteLoader = styled("div")(() => ({
    width: "100%",
    height: "50vh",
    padding: 0
}));

interface IInfiniteLoaderProps {
    projects: Array<any>;
    loadNextPage: () => void;
    isLoading?: boolean
    setSearch: (value: string) => void
    handleClose: () => void
}

const InfiniteLoader: FC<IInfiniteLoaderProps> = ({ projects, loadNextPage, ...props }) => {
    const { isLoading, setSearch, handleClose } = props
    const [page, setPage] = useState(1);
    const loaderRef = useRef<HTMLUListElement | null>(null);
    const dispatch = useAppDispatch()

    const handleScroll = async () => {
        if (loaderRef.current && !isLoading) {
            const { scrollTop, scrollHeight, clientHeight } = loaderRef.current;

            // Verificar si el usuario llegó completamente al final
            if (scrollTop + clientHeight >= scrollHeight) {
                loadNextPage();
                setPage(page + 1); // Incrementar la página
            }
        }
    };

    useEffect(() => {
        const currentRef = loaderRef.current;
        if (currentRef) {
            currentRef.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener("scroll", handleScroll);
            }
        };
    }, [handleScroll]);


    const keyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            setSearch((event.target as HTMLInputElement).value); // Actualizar búsqueda con el valor del input
        }
    };
    
    const focusEvent = (event: React.FocusEvent<HTMLInputElement>) => {
        const { value } = event.target; // Obtener el valor del input
        setSearch(value); // Actualizar búsqueda con el valor del input
    };

    const handleClick = (id: string) => {
        const params  = new URLSearchParams()
        params.set('statusProject', 'true')
        params.set('managements', 'true')
        params.set('ubication', 'true')
        dispatch(getInfoProject(id, params))
        handleClose()
    }

    return (
        <WrapperInfiniteLoader>
            <Input placeholder="Buscar Proyecto" onKeyDown={keyDownEvent} onBlur={focusEvent}/>
            <List ref={loaderRef}>
                {projects && projects.length > 0 && projects.map((vl) => (
                    <ListItem key={vl.id} onClick={() => handleClick(vl.id)}>
                        {vl.name}
                    </ListItem>
                ))}
            </List>
            {isLoading && <div>Cargando más proyectos...</div>}
        </WrapperInfiniteLoader>
    );
};

export default InfiniteLoader;
