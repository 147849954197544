import { FC } from "react";
import FolderForm from "../../moleculas/folders/folderform";
import useForlderForm from "../../../hooks/folders/useFolderForm";
import { styled, Typography } from "@mui/material";

const Wrap = styled('div')(({ }) => ({
    minWidth: '25vw',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
}))

interface IFolderCreatedProps {
    handleCloseModal: () => void
}

const FolderCreated: FC<IFolderCreatedProps> = ({handleCloseModal }) => {

    const { control, handleSubmit } = useForlderForm()
    return (
        <Wrap>
            <Typography variant="h5" color="secondary">Información de la carpeta</Typography>
            <FolderForm control={control} handleSubmit={handleSubmit} handleCloseModal={handleCloseModal}/>
        </Wrap>
    );
};

export default FolderCreated