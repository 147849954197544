import { FC, useEffect, useState } from 'react';
import GridItem from '../../components/atomos/grid/ItemGrid';
import LoginGrid from '../../components/atomos/grid/LoginGrid';
import LoginContent from '../../components/moleculas/login/LoginContent';
import LoginForm from '../../components/organismos/login/LoginForm';
import Item from '../../components/atomos/Item';
import useLoginForm from '../../hooks/login/useLoginForm';
import LoginFormVisiter from '../../components/organismos/login/LoginFormVisiter';
import useLoginFormVisiter from '../../hooks/login/visiter/useLoginFormVisiter';
import { useAppSelector } from '../../store/hook/store.hook';
import { AuthType } from '../../store/schemas/auth.schema';
import useOtpForm from '../../hooks/login/visiter/useOtpForm';
import { useTheme, useMediaQuery } from '@mui/material';
import { QrICon } from '../../assets/icons/qricon';


const LoginPage: FC = () => {
    const [hidden, setHidden] = useState(false);
    const [isFadingOut, setIsFadingOut] = useState(false);
    const theme = useTheme();
    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
    const [progress, setProgress] = useState(0);

    const { handleSubmit, control } = useLoginForm();
    const { type } = useAppSelector((state) => state.auth);
    const { ...props2 } = useLoginFormVisiter();
    const { controlOTP, handleSubmitOTP } = useOtpForm({});

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (isMobileOrTablet) {

            // Iniciar un intervalo para simular el progreso
            const progressInterval = setInterval(() => {
                setProgress((prevProgress) =>
                    prevProgress >= 100 ? 100 : prevProgress + 10
                );
            }, 200);

            // Controlar la transición y ocultar la columna
            const timeout = setTimeout(() => {
                setIsFadingOut(true);

                setTimeout(() => {
                    setHidden(true);
                    clearInterval(progressInterval); // Detener el progreso
                }, 500);
            }, 2000);

            return () => {
                clearTimeout(timeout);
                clearInterval(progressInterval);
            };
        }
    }, [isMobileOrTablet]);

    return (
        <LoginGrid id='loginContainerGrid'>
            {
                hidden && (

                    <QrICon sx={{ fontSize: '3rem', position: 'absolute', top: 20, right: 20 }} />
                )
            }
            <GridItem
                size={{ xs: 12, md: 6 }}
                sx={[
                    {
                        display: hidden ? 'none' : 'flex',
                        opacity: isFadingOut ? 0 : 1,
                        transition: 'opacity 0.5s ease-in-out',
                    },
                ]}
            >
                <Item>
                    <LoginContent loader={isMobileOrTablet} progress={progress} />
                </Item>
            </GridItem>
            <GridItem size={{ xs: 12, md: 6 }} sx={[
                {
                    display: isMobileOrTablet && !hidden ? 'none' : 'block',
                    height: {
                        xl: 'auto',
                        lg: 'auto',
                        md: 'auto',
                        xs: '70vh',
                    },
                }
            ]}>
                <Item>
                    {AuthType.ADMIN === type ? (
                        <LoginForm handleSubmit={handleSubmit} control={control} />
                    ) : (
                        <LoginFormVisiter
                            controlOTP={controlOTP}
                            handleSubmitOTP={handleSubmitOTP}
                            {...props2}
                        />
                    )}
                </Item>
            </GridItem>
        </LoginGrid>
    );
};

export default LoginPage;
