import { Checkbox, CheckboxProps, styled } from "@mui/material";
import { FC } from "react";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form";
import MessageError from "../atomos/MessageError";


const Wrapper = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
}))

const InputWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: "center",
    gap: "5px"
}))

const Label = styled('a')(() => ({
    textDecorationLine: "none",
    color: "#0065bb",
    ':hover': {
        textDecorationLine: "underline"
    }
}))


interface TempProps extends CheckboxProps {
    field: ControllerRenderProps<any, any>;
    fieldState: ControllerFieldState;
}


const CheckerMessage: FC<TempProps> = ({ field, fieldState, ...props }) => {
    // Desestructuramos las propiedades que no deben ir al CheckBox

    return (
        <Wrapper>
            <InputWrapper>
                <Label href="https://cloud.industriascts.com/terminos" target="_blank">{props["aria-label"]}</Label>
                <Checkbox

                    {...field}
                    checked={field.value}
                    {...props}
                    sx={{ width: "0%" }}
                />
            </InputWrapper>
            <MessageError sx={{width: 'auto'}}>{fieldState.error?.message}</MessageError>
        </Wrapper>
    )
}

export default CheckerMessage