
const useStateProject = () => {

    const mockStateProjects = [
        {
            id: 3,
            name: 'En proceso',
        },
        {
            id: 2,
            name: "En curso"
        },
        {
            id: 6,
            name: "Finalizado"
        }
    ]

    const getName = (id: number | undefined) => mockStateProjects.find((stateProject) => stateProject.id === id)?.name || 'Desconocido'

    const getAll = () => mockStateProjects.map(state => state)

    return {
        getName,
        getAll
    }
}

export default useStateProject