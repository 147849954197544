import { FC } from "react"
import WrapperFilterContainer from "../../atomos/WrapperFilterContainer"
import { Divider, IconButton } from "@mui/material"
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useNavigate } from "react-router-dom";

const TemplateActionsController: FC = () => {

    const navigate = useNavigate()

    const handleCreateTemplate = () => {
        navigate('/dashboard/templates/new')
    }

    return (
        <WrapperFilterContainer sx={{ textAlign: 'right', margin: 0, display: 'inline-block' }}>
            <Divider />
            <IconButton title="Crear Plantilla" onClick={handleCreateTemplate}>
                <AddBoxOutlinedIcon />
            </IconButton>
        </WrapperFilterContainer>
    )
}

export default TemplateActionsController