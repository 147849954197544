import { FC } from "react"
import WrapperFilterContainer from "../../atomos/WrapperFilterContainer"
import { Divider, IconButton } from "@mui/material"
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { useNavigate } from "react-router-dom";

const BucketActionsController: FC = () => {

    const navigate = useNavigate()

    const handleCreateProject = () => {
        navigate('/dashboard/project/new')
    }

    return (
        <WrapperFilterContainer sx={{ textAlign: 'right', margin: 0, display: 'inline-block' }}>
            <Divider />
            <IconButton title="Crear Proyecto" onClick={handleCreateProject}>
                <AddBoxOutlinedIcon />
            </IconButton>
        </WrapperFilterContainer>
    )
}

export default BucketActionsController