import { createBrowserRouter, Navigate, useNavigate, useParams } from "react-router-dom";
import App from "../layouts/app.layout";
import Login from "../pages/authentication/Login";
import PrivateRoute from "./private.router";
import PublicRoute from "./public.router";
import DashboardLayout from "../layouts/dashboard.layout";
import Recurso from "../pages/recursos/proyecto";
import { useEffect } from "react";
import Home from "../pages/app/Home";
import FolderResource from "../pages/recursos/folder";
import BusinessUnit from "../pages/businessUnit";
import Bucket from "../pages/businessUnit/bucket";
import FolderBucket from "../pages/businessUnit/bucket/folderBucket";
import NewProject from "../pages/project/new";
import ProjectComponent from "../pages/project";
import Templates from "../pages/templates";
import EditedTemplateContainer from "../pages/templates/edit";
import NewTemplate from "../pages/templates/new";
import VisitedMonitored from "../pages/specials/VisitedMonitored";
import AdminMonitored from "../pages/specials/AdminMonitored";
import Terminos from "../pages/specials/Terminos";
import Politicas from "../pages/specials/Politicas";
import SettingsApp from "../pages/settings-app";
import FolderWithoutProject from "../pages/recursos/folderWithoutProject";

const RedirectToResource = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirigir cuando el parámetro 'id' cambie
    if (id) {
      navigate(`/dashboard/recursos/${id}`, { replace: true });
    }
  }, [id, navigate]);

  return <div>Redirigiendo...</div>; // El componente no renderiza nada directamente
};

const RedirectToResourceFolder = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirigir cuando el parámetro 'id' cambie
    if (id) {
      navigate(`/dashboard/recursos/${id}`, { replace: true });
    }
  }, [id, navigate]);

  return <div>Redirigiendo...</div>; // El componente no renderiza nada directamente
};

const rootRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" replace={true} />
      },
      // Rutas Públicas (Login)
      {
        path: '/login',
        element: <PublicRoute />, // Usamos el componente PublicRoute para el login
        children: [
          {
            path: '/login',
            element: <Login />
          }
        ]
      },
      // Rutas Privadas
      {
        path: '/dashboard',
        element: <PrivateRoute />, // Componente que protege rutas privadas
        children: [
          {
            element: <DashboardLayout />,
            children: [
              {
                element: <Home />,
                index: true
              },
              {
                path: 'recursos/:recursoId',
                element: <Recurso />,
              },
              {
                path: 'recursos/:recursoId/folders/:folderId',
                element: <FolderResource />
              },
              {
                path: 'folder/:folderId',
                element: <FolderWithoutProject />
              },
              {
                path: 'businessUnit',
                element: <BusinessUnit />
              },
              {
                path: 'businessUnit/:bucketId',
                element: <Bucket />
              },
              {
                path: 'businessUnit/:bucketId/folder/:folderId',
                element: <FolderBucket />
              },
              {
                path: 'project/new',
                element: <NewProject />
              },
              {
                path: 'project/:projectId',
                element: <ProjectComponent />
              },
              {
                path: 'templates',
                element: <Templates />
              },
              {
                path: 'templates/new',
                element: <NewTemplate />
              },
              {
                path: 'templates/:templateId',
                element: <EditedTemplateContainer />
              },
              {
                path: 'visitedMonitored',
                element: <VisitedMonitored />
              },
              {
                path: 'adminMonitored',
                element: <AdminMonitored />
              },
              {
                path: 'settings',
                element: <SettingsApp />
              }
            ]
          },
        ],
      },
      // Redirección dinámica de proyectos/:id y uos/:id a dashboard/recursos/:id
      {
        path: "proyectos/:id",
        element: <PrivateRoute />,
        children: [
          {
            path: "",
            element: <RedirectToResource /> // Componente que redirige dinámicamente
          }
        ]
      },
      // Redirección dinámica de proyectos/:id y uos/:id a dashboard/recursos/:id
      {
        path: "uom/:id",
        element: <PrivateRoute />,
        children: [
          {
            path: "",
            element: <RedirectToResourceFolder /> // Componente que redirige dinámicamente
          }
        ]
      },
      {
        path: "terminos",
        element: <Terminos />
      },
      {
        path: "politicas",
        element: <Politicas />
      }
    ],
    errorElement: <div>Not Found</div>,
  }
])

export default rootRouter