import { FC } from "react";
import ButtonFolder from "../../atomos/explorer/ButtonFolder";
import LabelFolder from "../../atomos/explorer/LabelFolder";
import WrapperButton from "../../atomos/explorer/wrapperButton";
import ControlItem from "./control";


interface IFolderProps {
    name: string
    id: string
    sequence: number
    createdDate: string
    clickAccess: (id: string) => void
    isAdmin?: boolean
}

const Folder: FC<IFolderProps> = ({ sequence, name, id, createdDate, clickAccess, isAdmin }) => {

    const infoProps = { id, name, createdDate, type: 'folder' }

    const handleAllClick = () => {
        clickAccess(id)
    }

    return (
        <WrapperButton title={name}>
            <ControlItem infoProps={infoProps} isAdmin={isAdmin}/>
            <ButtonFolder onClick={handleAllClick} />
            <LabelFolder onClick={handleAllClick}>
                {sequence}. {name}
            </LabelFolder>
        </WrapperButton>
    )
}

export default Folder