
export type ConfigTemplate = {
    icon: string
    text: string
    color: string
    children: Array<ConfigTemplate>
    description: string
}
export type PaginateOptions = {
    page: number
    limit: number
}
export type Meta = {
    totalItems: number
    itemCount: number
    itemsPerPage: number
    totalPages: number
    currentPage: number
}

export type Template = {
    id: number,
    name: string
    config: Array<ConfigTemplate>
}

export interface TemplateSstate {
    templates: Array<Template>,
    loading?: boolean,
    error?: string | null,
    selected?: Template | null
    paginated?: PaginateOptions
    meta?: Meta | null
}

const initialState: TemplateSstate = {
    templates: [],
    loading: false,
    error: null,
    selected: null,
    paginated: {
        page: 0,
        limit: 5
    },
    meta: null
}


export default initialState