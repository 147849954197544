import { styled, TextField, TextFieldProps } from "@mui/material";
import React from "react";

// Estilo personalizado para el TextField
const CtsTextField = styled(TextField)(({ theme }) => ({
    width: '100%',
    position: 'relative', // Necesario para el posicionamiento del pseudo-elemento,
    backgroundColor: 'rgba(200, 200, 200, 0.2)', // Color de fondo con opacidad
    '&::after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: '2%', // Ancho del borde azul
        backgroundColor: theme.palette.primary.main, // Color del borde azul
    },
    '&::before': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: '2%', // Ancho del borde azul
        backgroundColor: theme.palette.primary.main, // Color del borde azul
    },
    // Ajusta el label cuando el TextField está enfocado
    '&:focus-within .MuiInputLabel-root': {
        marginLeft: '0%', // Elimina el margen izquierdo al enfocar
    },
    // Estilo por defecto del label
    '& .MuiInputLabel-root': {
        marginLeft: '0%', // Ajusta la distancia del label a la izquierda por defecto
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            // Mantener el borde normal
            transition: theme.transitions.create(['border-color', 'border-width']),
            borderColor: 'transparent'
        },
        '&:hover fieldset': {
            borderColor: 'transparent'
            // borderColor: theme.palette.primary.main, // Cambia el color del borde en hover
        },
        '&.Mui-focused fieldset': {
            borderColor: 'transparent',
            // borderColor: theme.palette.primary.main, // Color del borde cuando está enfocado
            borderWidth: '2px',  // Opción: cambia el grosor al enfocar
        },
    },
    // No ajustamos manualmente el transform del label aquí
    '& .MuiInputLabel-shrink': {
        // Cambiamos el legend para que no se vea cortado el borde
        '& + .MuiOutlinedInput-root fieldset': {
            legend: {
                maxWidth: '100px', // Ajusta el tamaño del legend (evita el corte abrupto)
            },
        },
    },

    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },

}));

// Props personalizadas del componente
const CustomTextField = React.forwardRef<HTMLDivElement, TextFieldProps>((props, ref) => {

    return (
        <CtsTextField
            autoComplete="false"
            ref={ref} // Pasamos la referencia al componente CtsTextField
            InputLabelProps={{
                sx: {
                    marginLeft: '3%', // Ajusta la distancia del label a la izquierda
                },
            }}
            {...props}
        />
    );
});

CustomTextField.displayName = 'CustomTextField'; // Nombre del componente para la depuración

export default CustomTextField;
