import { Avatar, Box, CssBaseline, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, MenuItem, useTheme } from "@mui/material";
import React, { FC } from "react";
import AppBar from "../../atomos/navs/appbar";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from "../../atomos/drawer/drawerBar";
import DrawerHeader from "../../atomos/drawer/drawerheader";
import { Drawer as DrawerMobile } from "@mui/material"


import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { drawerWidth } from "../../atomos/styles/constantes";
import { BusinessUnitSchema } from "../../../store/schemas/businessUnit.schema";
import useNavigationVisibility from "../../../hooks/useNavigationVisibility";
import { useAppDispatch, useAppSelector } from "../../../store/hook/store.hook";
import { NavLink } from "react-router-dom";
import HomeIcon from '@mui/icons-material/HomeOutlined';
import { SettingsIcon } from "../../../assets/icons/white/settings";
import MenuProfile from "../../atomos/MenuProfile";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { resetSlices } from "../../../store";
import { QrICon } from "../../../assets/icons/qricon"
import ToolbarStyled from "../../atomos/navs/toolbar";
import FolderCopyOutlinedIcon from '@mui/icons-material/FolderCopyOutlined';

interface IDashboardDrawerProps extends React.PropsWithChildren<{
    window?: () => Window;
}> {
    children: React.ReactElement[] | null
    businessUnits: BusinessUnitSchema[]
}

const DashboardDrawer: FC<IDashboardDrawerProps> = ({ children }) => {

    const profile = useAppSelector((state) => state.profile)
    const dispatch = useAppDispatch()

    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);

    // const allowedRoles = ['superadmin', 'admin'];
    const allowedForViewers = ['viewer'];
    const allowerSuperAdmin = ['superadmin'];

    // Usa el hook para determinar si debe mostrar la navegación
    // const showNavigation = useNavigationVisibility(profile.rol, allowedRoles);
    const showNavigationForViewers = useNavigationVisibility(profile.rol, allowedForViewers);
    const showNavigationForSuperAdmin = useNavigationVisibility(profile.rol, allowerSuperAdmin);

    const handleMobileDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setMobileOpen(!mobileOpen);
        }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openMenuProfile = Boolean(anchorEl);
    const handleMenuOpen = (event: any) => {
        setAnchorEl(event.currentTarget); // Usa el avatar como referencia para el menú
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <ToolbarStyled sx={[
                    open && {
                        justifyContent: {
                            xl: 'flex-end',
                            lg: 'flex-end',
                        }
                    }
                ]}>
                    <IconButton
                        aria-label="open drawer"
                        onClick={() => {
                            handleDrawerToggle()
                            handleDrawerOpen()
                        }}
                        edge="start"
                        sx={[

                            {
                                mr: 2,
                            },
                            open && {
                                display: {
                                    xl: 'none',
                                    lg: 'none',
                                }
                            },
                            {
                                color: {
                                    xl: 'white',
                                    lg: 'white',
                                    md: theme.palette.primary.main,
                                    sm: theme.palette.primary.main,
                                    xs: theme.palette.primary.main,
                                }
                            }
                        ]}
                    >
                        <MenuIcon sx={{ width: 30, height: 30 }} />
                    </IconButton>
                    <QrICon sx={{ fontSize: '3rem' }} />
                </ToolbarStyled>
            </AppBar>
            <DrawerMobile
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={handleDrawerTransitionEnd}
                onClose={handleMobileDrawerClose}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    width: drawerWidth + 10,
                    display: {
                        xs: 'block', sm: 'block', md: 'block', xl: 'none', lg: 'none'
                    },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', backgroundColor: theme.palette.primary.main, width: drawerWidth + 10 },
                }}
            >
                <Divider />
                <List sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <ListItem
                        key={'Home'}
                        disablePadding sx={[
                            showNavigationForViewers && {
                                display: 'none'
                            }
                        ]}>
                        <ListItemButton
                            component={NavLink}
                            to={'/dashboard'}
                            onClick={handleMobileDrawerClose}
                        >
                            <ListItemIcon sx={{ color: "white" }}>
                                <HomeIcon sx={{ width: 30, height: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={'Home'} sx={{ color: "white", fontWeight: "bold" }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem
                        key={'Templates'}
                        disablePadding sx={[
                            showNavigationForViewers && {
                                display: 'none'
                            }
                        ]}>
                        <ListItemButton
                            component={NavLink}
                            to={'/dashboard/templates'}
                            onClick={handleMobileDrawerClose}
                        >
                            <ListItemIcon sx={{ color: "white" }}>
                                <FolderCopyOutlinedIcon sx={{ width: 30, height: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={'Plantillas'} sx={{ color: "white", fontWeight: "bold" }} />
                        </ListItemButton>
                    </ListItem>

                    <Box sx={{ marginTop: 'auto' }}>
                        {showNavigationForSuperAdmin && (<ListItem key={'Settings'} disablePadding>
                            <ListItemButton component={NavLink} to={'/dashboard/settings'}>
                                <ListItemIcon sx={{ color: "white" }}>
                                    <SettingsIcon sx={{ width: 30, height: 30 }} />
                                </ListItemIcon>
                                <ListItemText primary={'Configuración'} sx={{ color: 'white', fontWeight: 'bold' }} />
                            </ListItemButton>
                        </ListItem>)}
                        <ListItem key={'Profile'} disablePadding>
                            <ListItemButton  >
                                <ListItemAvatar>
                                    <Avatar
                                        sx={{ bgcolor: 'white', color: theme.palette.primary.main, width: 30, height: 30 }}
                                        onClick={handleMenuOpen}
                                    >
                                        {profile?.name?.charAt(0) || 'I'}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={'Perfil'} sx={{ color: 'white', fontWeight: 'bold' }}
                                    onClick={handleMenuOpen} />
                                <MenuProfile
                                    id="account-menu"
                                    anchorEl={anchorEl}
                                    open={mobileOpen && openMenuProfile}
                                    onClose={handleMenuClose}
                                    onClick={handleMenuClose}
                                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={handleMenuClose}>
                                        {profile.email}
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleMenuClose()
                                        dispatch(resetSlices());
                                    }}>
                                        <IconButton>
                                            <LogoutOutlinedIcon />
                                        </IconButton>
                                    </MenuItem>
                                </MenuProfile>
                            </ListItemButton>
                        </ListItem>
                    </Box>
                </List>
            </DrawerMobile>
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                    },
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose} sx={[!open && { display: 'none' }]}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ color: 'white', width: 30, height: 30 }} /> : <ChevronLeftIcon sx={{ color: 'white', width: 30, height: 30 }} />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <ListItem key={'Home'} disablePadding sx={[
                        showNavigationForViewers && {
                            display: 'none'
                        }
                    ]}>
                        <ListItemButton
                            component={NavLink}
                            to={'/dashboard'}
                        >
                            <ListItemIcon sx={{ color: "white" }}>
                                <HomeIcon sx={{ width: 30, height: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={'Home'} sx={{ color: "white", fontWeight: "bold" }} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem key={'Templates'} disablePadding sx={[
                        showNavigationForViewers && {
                            display: 'none'
                        }
                    ]}>
                        <ListItemButton
                            component={NavLink}
                            to={'/dashboard/templates'}
                        >
                            <ListItemIcon sx={{ color: "white" }}>
                                <FolderCopyOutlinedIcon sx={{ width: 30, height: 30 }} />
                            </ListItemIcon>
                            <ListItemText primary={'Plantillas'} sx={{ color: "white", fontWeight: "bold" }} />
                        </ListItemButton>
                    </ListItem>
                    <Box sx={{ marginTop: 'auto' }}>
                        {showNavigationForSuperAdmin && (<ListItem key={'Settings'} disablePadding>
                            <ListItemButton component={NavLink} to={'/dashboard/settings'}>

                                <ListItemIcon sx={{ color: "white" }}>
                                    <SettingsIcon sx={{ width: 30, height: 30 }} />
                                </ListItemIcon>
                                <ListItemText primary={'Configuración'} sx={{ color: 'white', fontWeight: 'bold' }} />
                            </ListItemButton>
                        </ListItem>)}
                        <ListItem key={'Profile'} disablePadding>
                            <ListItemButton>
                                <ListItemAvatar >
                                    <Avatar
                                        sx={{ bgcolor: 'white', color: theme.palette.primary.main, width: 30, height: 30 }}
                                        onClick={handleMenuOpen}
                                    >
                                        {profile?.name?.charAt(0) || 'I'}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={'Perfil'} sx={{ color: 'white', fontWeight: 'bold' }}
                                    onClick={handleMenuOpen} />
                                <MenuProfile
                                    id="account-menu"
                                    anchorEl={anchorEl}
                                    open={openMenuProfile}
                                    onClose={handleMenuClose}
                                    onClick={handleMenuClose}
                                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={handleMenuClose}>
                                        {profile.email}
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleMenuClose()
                                        dispatch(resetSlices());
                                    }}>
                                        <IconButton>
                                            <LogoutOutlinedIcon />
                                        </IconButton>
                                    </MenuItem>
                                </MenuProfile>
                            </ListItemButton>
                        </ListItem>
                    </Box>
                </List>
            </Drawer>
            <Box component={"main"} sx={{
                height: '100%', width: '100%', overflowY: 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none', // Para Chrome, Safari y Edge
                },
            }} >
                <DrawerHeader sx={{ marginBottom: '2rem' }} />
                {children}
            </Box>
        </Box>

    )
}

export default DashboardDrawer