import { Step, StepLabel, Stepper, StepperProps, styled, Typography } from "@mui/material";
import { FC } from "react";

const StyledStepper = styled(Stepper)(({ }) => ({
    width: '100%'
}))

interface IProjectStepperProps extends StepperProps {
    customSteps: Array<{
        label: string
        content: Array<any>
    }>
    isStepOptional: (step: number) => boolean
    isStepSkipped: (step: number) => boolean
}

const ProjectStepper: FC<IProjectStepperProps> = ({ customSteps, isStepOptional, isStepSkipped, ...props }) => {


    return (
        <StyledStepper {...props}>
            {
                customSteps.map(({ label }, idx) => {

                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};

                    if (isStepOptional(idx)) {
                      labelProps.optional = (
                        <Typography variant="caption">(Opcional)</Typography>
                      );
                    }
                    if (isStepSkipped(idx)) {
                      stepProps.completed = false;
                    }


                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    )
                })
            }
        </StyledStepper>
    )
}

export default ProjectStepper