import { Project } from "./project.schema"

export type Bucket = {
    id: string,
    name: string
    business_unit_id: string
    project_id: string
    project?: Project
    created_at: Date
    updated_at: Date
}

export type PaginateOptions = {
    page: number
    limit: number
}
export type Meta = {
    totalItems: number
    itemCount: number
    itemsPerPage: number
    totalPages: number
    currentPage: number
}

export interface BucketState {
    buckets?: Array<Bucket>
    loading?: boolean
    error?: string | null
    paginated?: PaginateOptions
    selected?: Bucket | null
    meta?: Meta | null
}

const initialState: BucketState = {
    buckets: [],
    loading: false,
    error: null,
    paginated: {
        page: 0,
        limit: 5
    },
    selected: null,
    meta: null
}

export default initialState