import { ButtonBase, ButtonBaseProps, styled } from "@mui/material";
import AttachmentSVG from "../../../assets/svg/attachment.svg"
import BlueAttachment from "../../../assets/svg/blueAttachment.svg"

import { FC, useState } from "react";


const Image = styled('span')(({ }) => ({
    display: 'inline-block',
    '& svg': {
        width: '100%',
        height: '100%',
        fill: 'currentColor',
    },

}))

const EnchancedButton = styled(ButtonBase)(({ }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

const CustomImg = styled('img')(({ }) => ({
    zIndex: '1 !important',
    position: 'relative'
}))

const ButtonAtachment: FC<ButtonBaseProps> = ({ ...props }) => {

    const [isHovered, setIsHovered] = useState(false);

    return (
        <EnchancedButton {...props}>
            <Image
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <CustomImg src={isHovered ? BlueAttachment : AttachmentSVG} alt="Attachment Icon" style={{ width: '100%' }} />
            </Image>
        </EnchancedButton>
    )
}

export default ButtonAtachment