interface BucketType {
    id: string,
    name: string,
    business_unit_id: string
    created_at: Date
    project: {
        id: string
        name: string
        cliente: string
        old_ga: string
    }
}

interface AttachmentType {
    id: string,
    fileName: string,
    filePath: string
    fileSize: number
    fileType: string
}

export interface FolderType {
    id: string,
    name: string,
    sequence: number,
    icon: string
    color: string
    children: FolderType[]
    attachments: AttachmentType[]
    parent: FolderType | null
}


export interface RecursosState {
    root: BucketType | null,
    structure: FolderType[],
    generalFiles: AttachmentType[] | []
    loading?: boolean
    error?: string | null
}

const initialState: RecursosState = {
    root: null,
    structure: [],
    generalFiles: [],
    loading: false
};

export default initialState