import { FC } from "react";

import PageDndWrapper from "../../../components/atomos/dnd/PageDndWrapper";
import InfoBar from "../../../components/moleculas/recursos/InfoBar";
import FoldersBucket from "../../../components/organismos/buckets/FoldersBucket";
import BucketBreadcrumb from "../../../components/moleculas/bucket/Breadcrumb";
import FoldersActions from "../../../components/organismos/buckets/FoldersActions";
import ModalComponent from "../../../components/moleculas/Modal";
import useModal from "../../../hooks/useModal";
import FolderCreated from "../../../components/organismos/folders/created";
import FileCreated from "../../../components/organismos/folders/CreatedFile";
import QRCreated from "../../../components/organismos/qr/QRCreated";


const FolderBucket: FC = () => {
    const { handleClose: handleCloseModal, handleOpen: handleOpen, open: openModal } = useModal()
    const { handleClose: hadleCloseFileModal, handleOpen: handleFileOpen, open: openFileModal } = useModal()
    const { handleClose: handleCloseQRModal, handleOpen: handleQROpen, open: openQrModal } = useModal()

    return (
        <>
            <InfoBar />
            <BucketBreadcrumb />
            <FoldersActions handleOpen={handleOpen} handleFileOpen={handleFileOpen} handleQRCode={handleQROpen}/>
            <PageDndWrapper>
                <FoldersBucket />
            </PageDndWrapper>
            <ModalComponent open={openModal} onClose={handleCloseModal}>
                <FolderCreated handleCloseModal={handleCloseModal} />
            </ModalComponent>
            <ModalComponent open={openFileModal} onClose={hadleCloseFileModal}>
                <FileCreated handleCloseModal={hadleCloseFileModal} />
            </ModalComponent>
            <ModalComponent open={openQrModal} onClose={handleCloseQRModal}>
                <QRCreated handleCloseModal={handleCloseQRModal}/>
            </ModalComponent>
        </>
    )
}

export default FolderBucket