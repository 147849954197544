import { styled } from "@mui/material";
import useFileLoader from "../../../../hooks/fileExplorer/ext/useFileLoader";
import { FC, useState } from "react";
import PDFViewer from "../ext/PDFExtension";
import ImageViewer from "../ext/ImageExtension";
import Spinner from "../../../atomos/Spinner";

const Div = styled('div')(() => ({
    height: '90%',
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ErrorMessage = styled('div')(() => ({
    color: 'red',
    fontSize: '16px',
    textAlign: 'center',
}));

interface IRenderedProps {
    fileProps: any;
    toolbarPlugin: any
}

const Rendered: FC<IRenderedProps> = ({ fileProps, toolbarPlugin }) => {
    const { file } = useFileLoader({ fileProps });
    const [error] = useState<string | null>(null);

    const renderFileContent = () => {
        if (!file) return <Spinner  isLoad/>;

        const fileType = file?.type;

        if (fileType === 'application/pdf') {
            return <PDFViewer file={file} toolbarPlugin={toolbarPlugin} />;
        }

        if (fileType === 'image/jpeg' || fileType === 'image/png') {
            return <ImageViewer file={file} />;
        }

        return <ErrorMessage>File type not supported</ErrorMessage>;
    };

    return (
        <Div
            sx={[fileProps.fileType === 'image/jpeg' || fileProps.fileType === 'image/png' && { height: '100%' }]}>
            {error ? <ErrorMessage>{error}</ErrorMessage> : renderFileContent()}
        </Div>
    );
};

export default Rendered;
