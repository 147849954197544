import { AppDispatch } from "..";
import api from "../../services/axios";
import { changeFolder, errorInFileExplorer, fileExplorerLoading, loadOpened } from "../slices/fileExplorer.slice";
import { loadSelectedProject } from "../slices/project.slice";
import { clearRecursoState, errorInLoad, loadedRecursoState, loadRecursos } from "../slices/recursos.slice";


export const loadRecurso = (recursoId: string, businessUnitId?: string) => async (dispatch: AppDispatch) => {
  dispatch(clearRecursoState())
  dispatch(loadRecursos())

  const url = '/recursos/general/' + recursoId + (businessUnitId ? '?businessUnit=' + businessUnitId : '');
  try {
    const response = await api.get(url);

    if (response.data.length > 0) {

      dispatch(
        loadedRecursoState({
          root: response.data[0].bucket,
          structure: response.data || [],
          generalFiles: [],
        })
      )
    } else {
      dispatch(
        loadedRecursoState({
          root: null,
          structure: [],
          generalFiles: [],
        })
      )
    }
  } catch (error: any) {
    const { response } = error
    if (response) {
      dispatch(errorInLoad((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
    } else {
      dispatch(errorInLoad((error as { message: string }).message || 'Error al cargar los recursos'))
    }
  }
};


export const loadFolder = (projectId: string, folderId: string) => async (dispatch: AppDispatch) => {
  dispatch(fileExplorerLoading())

  const url = `/recursos/general/${projectId}/folders/${folderId}`;
  try {
    const response = await api.get(url);

    let parent = null

    if (response.data.parent) {
      parent = { id: response.data.parent.id, name: response.data.parent.name }
    }

    dispatch(
      changeFolder({
        folders: response.data.children || [],
        parent: parent,
        attachments: response.data.attachments || [],
      })
    )
  } catch (error: any) {
    const { response } = error
    if (response) {
      dispatch(errorInFileExplorer((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
    } else {
      dispatch(errorInFileExplorer((error as { message: string }).message || 'Error al cargar los recursos'))
    }
  }
}

export const loadFolderWithoutProject = (folderId: string, init?: string | null) => async (dispatch: AppDispatch) => {
  dispatch(fileExplorerLoading())

  const url = `/recursos/folders/${folderId}`;
  try {
    const response = await api.get(url);

    let parent = null

    if (response.data.parent) {
      parent = { id: response.data.parent.id, name: response.data.parent.name }
    }
    
    dispatch(
      changeFolder({
        folders: response.data.children || [],
        parent: init ? null : parent,
        attachments: response.data.attachments || [],
      })
    )
    const bucket = response.data.bucket

    if (bucket) {
      const project = bucket.project
      if (project) {
        dispatch(
          loadSelectedProject({
            id: project.id,
            name: project.name,
            cliente: project.cliente,
            old_ga: project.old_ga,
            managements: project.managements,
            status_project_id: project.status_project_id,
            created_at: project.created_at,
          })
        )
      }
    }
  } catch (error: any) {
    const { response } = error
    if (response) {
      dispatch(errorInFileExplorer((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
    } else {
      dispatch(errorInFileExplorer((error as { message: string }).message || 'Error al cargar los recursos'))
    }
  }
}

export const setInfoFolder = (folderId: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await api.get(`/recursos/folders/${folderId}`);

    dispatch(
      loadOpened({ id: response.data.id, name: response.data.name })
    )

  } catch (error: any) {
    const { response } = error
    if (response) {
      dispatch(errorInFileExplorer((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
    } else {
      dispatch(errorInFileExplorer((error as { message: string }).message || 'Error al cargar los recursos'))
    }
  }
}

export const loadRootFileExplorer = (projectId: string) => async (dispatch: AppDispatch) => {
  dispatch(fileExplorerLoading())

  const url = `/recursos/bucket/${projectId}/root`

  try {

    const response = await api.get(url);

    dispatch(changeFolder({
      folders: response.data || [],
      attachments: []
    }))

  } catch (error: any) {
    const { response } = error
    if (response) {
      dispatch(errorInFileExplorer((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
    } else {
      dispatch(errorInFileExplorer((error as { message: string }).message || 'Error al cargar los recursos'))
    }
  }
}