import { Container, styled, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import ProjectWrapperStepper from "../../components/organismos/projects/WrapperStepper";
import useGetProjectsAndTemplates from "../../hooks/projects/useGetProjectsAndTemplates";
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook";
import { clearSelectedProject } from "../../store/slices/project.slice";
import useNavigationVisibility from "../../hooks/useNavigationVisibility";
import { resetTemplates } from "../../store/slices/templates.slice";


const Titled = styled(Typography)(({ }) => ({
    textTransform: 'capitalize',

}))
const NewProject: FC = () => {


    const profile = useAppSelector((state) => state.profile)
    const allowerSuperAdmin = ['superadmin', 'admin'];
    
    const showNavigationForSuperAdmin = useNavigationVisibility(profile.rol, allowerSuperAdmin);

    const dispatch = useAppDispatch()
    const { projects, loadNextPage, ...props } = useGetProjectsAndTemplates()

    useEffect(() => {
        dispatch(clearSelectedProject())
        dispatch(resetTemplates())
    }, [])

    if (!showNavigationForSuperAdmin) {
        return (
            <>
                ACCESO DENEGADO
            </>
        )
    }

    return (
        <Container>
            <Titled variant="h5">
                Nuevo Proyecto
            </Titled>
            <ProjectWrapperStepper projects={projects} loadNextPage={loadNextPage} {...props} />
        </Container>
    )
}

export default NewProject