import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthType, initialState } from "../schemas/auth.schema";

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        loginRequest(state) {
            state.loading = true;
            state.error = null;
        },
        loginSuccess(state, action: PayloadAction<any>) {
            state.accessToken = action.payload.accessToken;
            if (action.payload.refreshToken) {
                state.refreshToken = action.payload.refreshToken;
            }
            state.error = null;
            state.loading = false;
        },
        loginFailure(state, action: PayloadAction<string>) {
            state.loading = false;
            state.error = action.payload;
        },
        logout(state) {
            state.accessToken = null;
            state.refreshToken = null;
            state.error = null;
            state.message = null;
            state.loading = false;
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        },
        changeAuthType(state, action: PayloadAction<{
            type: AuthType,
            futurePath?: string
        }>) {
            state.type = action.payload.type;
            state.futurePath = action.payload.futurePath || null;
        },
        forceLoad(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        unMount(state) {
            state.refreshToken = null;
            state.error = null;
            state.message = null;
            state.loading = false;
            state.type = null;
            state.registerOtp = false
            state.validateOtp = false
        },
        shouldRegister(state) {
            state.registerOtp = true
            state.validateOtp = false
        },
        clearRegisterOtp(state) {
            state.registerOtp = false
            state.validateOtp = false
        },
        validateOtp(state) {
            state.validateOtp = true
        }
    }
})

export const { loginRequest, loginSuccess, loginFailure, forceLoad, changeAuthType, unMount, shouldRegister, clearRegisterOtp, logout, validateOtp } = authSlice.actions;

export default authSlice.reducer