import { Box, Button, styled } from "@mui/material";
import { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/hook/store.hook";
import { createProjectXBucket } from "../../../store/actions/projects.action";
import { useNavigate } from "react-router-dom";

const WrapperContent = styled(Box)(({ }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
}))

interface IProjectStepperActionsProps {
    customSteps: Array<{
        label: string
        content: Array<any>
    }>
    activeStep: number
    handleNext: () => void
    handleBack: () => void
    handleSkip: () => void
    handleReset: () => void
    isStepOptional: (step: number) => boolean
}

const ProjectStepperActions: FC<IProjectStepperActionsProps> = ({
    customSteps,
    activeStep,
    handleNext,
    handleBack,
    handleReset,
    handleSkip,
    isStepOptional,
}) => {

    const isActive = activeStep === customSteps.length

    const { selected } = useAppSelector(state => state.projects)
    const { selected: selectedTemplate } = useAppSelector(state => state.templates)
    const { selected: selectedBU } = useAppSelector(state => state.businessUnit)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const handleSave = async () => {
        
        dispatch(createProjectXBucket(selected!, selectedTemplate!, selectedBU!.id))
        navigate('/dashboard/businessUnit', {
            state: {
                businessUnit: selectedBU
            }
        })
    }

    return isActive ? (
        <WrapperContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    onClick={handleReset}
                >Salir</Button>
            </Box>
        </WrapperContent>
    ) : (
        <WrapperContent>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Atras
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {isStepOptional(activeStep) && (
                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                        Saltar
                    </Button>
                )}
                <Button
                    onClick={activeStep === customSteps.length - 1 ? handleSave : handleNext}
                >
                    {activeStep === customSteps.length - 1 ? 'Guardar' : 'Siguiente'}
                </Button>
            </Box>
        </WrapperContent>
    )
}

export default ProjectStepperActions
