import Grid, { Grid2Props } from '@mui/material/Grid2';
import { FC, ReactNode } from 'react';

interface GridItemProps extends Grid2Props {
    children: ReactNode;
}

const GridItem: FC<GridItemProps> = ({ children, size, ...props }) => {
    return (
        <Grid size={size} {...props} >
            {children}
        </Grid>
    );
};

export default GridItem;
