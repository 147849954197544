import { Breadcrumbs, Link, styled } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../store/hook/store.hook"
import { useNavigate } from "react-router-dom"
import { loadOpened } from "../../../store/slices/fileExplorer.slice"

const BrCr = styled(Breadcrumbs)(({ }) => ({
    marginBottom: '2rem'
}))

const Lk = styled(Link)(({ }) => ({
    cursor: 'pointer'
}))


const RecursosBreadcrumb = () => {

    const dispatch = useAppDispatch()
    const businessUnitState = useAppSelector((state) => state.businessUnit)
    const fileExplorerState = useAppSelector((state) => state.fileExplorer)
    const projectState = useAppSelector((state) => state.projects)
    const navigation = useNavigate()

    const handleClickInit = () => {
        return fileExplorerState.rootPath && navigation(fileExplorerState.rootPath)
    }

    const handleClickBusinessUnit = () => {
        dispatch(loadOpened())
        return businessUnitState.selected && navigation(`${fileExplorerState.rootPath}?businessUnit=${businessUnitState.selected.id}`)
    }

    const handleClickBefore = () => {
        return (fileExplorerState.rootPath) ? navigation(`${fileExplorerState.rootPath}/folders/${fileExplorerState.parent?.id}`) :
            navigation(`${fileExplorerState.parent?.id}`)
    }

    return (
        <BrCr aria-label="breadcrumb">

            {
                (projectState.selected && projectState.selected.buckets && projectState.selected.buckets.length! > 1) && (
                    <Lk underline="hover" onClick={handleClickInit}>
                        Inicio
                    </Lk>
                )
            }
            {
                businessUnitState.selected && (
                    <Lk underline="hover" onClick={handleClickBusinessUnit} >
                        {businessUnitState.selected.name}
                    </Lk>
                )
            }
            {
                fileExplorerState.parent && (
                    <Lk underline="hover" onClick={handleClickBefore}>
                        {fileExplorerState.parent.name}
                    </Lk>
                )
            }
            {
                fileExplorerState.opened && (
                    <Lk color="secondary" sx={{ cursor: 'text', textDecoration: 'none' }}>
                        {fileExplorerState.opened.name}
                    </Lk>
                )
            }
        </BrCr>
    )
}

export default RecursosBreadcrumb