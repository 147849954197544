import { FC } from "react";
import { styled, Typography } from "@mui/material";
import EditedForm from "../../moleculas/folders/EditedForm";
import useEditedFolder from "../../../hooks/folders/useEditedFolder";

const Wrap = styled('div')(({ }) => ({
    minWidth: '25vw',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
}))

interface IFolderCreatedProps {
    handleCloseModal: () => void
    infoProps?: any
}

const FolderEdited: FC<IFolderCreatedProps> = ({ handleCloseModal, infoProps }) => {

    const { control, handleSubmit } = useEditedFolder({ infoProps })

    return (
        <Wrap>
            <Typography variant="h5" color="secondary">Editar Carpeta</Typography>
            <EditedForm control={control} handleSubmit={handleSubmit} handleCloseModal={handleCloseModal} />
        </Wrap>
    );
};

export default FolderEdited