import { AppDispatch } from "..";
import api from "../../services/axios";
import { errorInLoadTemplate, loadTemplates, selectedTemplate } from "../slices/templates.slice";

interface Options {
    page?: number
    limit?: number
    search?: string
}

const getAllTemplates = (options?: Options) => async (dispatch: AppDispatch) => {

    const url = 'settings-app/templates'
    const tmpParams = new URLSearchParams()
    tmpParams.set('page', options?.page?.toString() || '1')
    tmpParams.set('limit', options?.limit?.toString() || '5')

    if (options?.search) {
        tmpParams.set('search', options.search)
    }

    try {

        const response = await api.get(url, { params: tmpParams });

        dispatch(loadTemplates({
            templates: response.data.items || [],
            meta: response.data.meta || null
        }))

    } catch (error: any) {
        const { response } = error

        if (response) {
            dispatch(errorInLoadTemplate((response.data as { msg: string }).msg || 'Error al cargar las Plantillas'))
        } else {
            dispatch(errorInLoadTemplate((error as { message: string }).message || 'Error al cargar las Plantillas'))
        }
    }
}

const getOneTemplate = (id: number) => async (dispatch: AppDispatch) => {

    const url = 'settings-app/templates/' + id

    try {

        const response = await api.get(url);

        dispatch(selectedTemplate(response.data))

    } catch (error: any) {
        const { response } = error

        if (response) {
            dispatch(errorInLoadTemplate((response.data as { msg: string }).msg || 'Error al cargar las Plantillas'))
        } else {
            dispatch(errorInLoadTemplate((error as { message: string }).message || 'Error al cargar las Plantillas'))
        }
    }
}


export {
    getAllTemplates,
    getOneTemplate
}