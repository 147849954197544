import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import useActionsBucket from "../../../hooks/bucket/useBucket";

import PageDndWrapper from "../../../components/atomos/dnd/PageDndWrapper";
import BucketExplorer from "../../../components/organismos/buckets";
import InfoBar from "../../../components/moleculas/recursos/InfoBar";
import BucketBreadcrumb from "../../../components/moleculas/bucket/Breadcrumb";
import FoldersActions from "../../../components/organismos/buckets/FoldersActions";
import ModalComponent from "../../../components/moleculas/Modal";
import useModal from "../../../hooks/useModal";
import FolderCreated from "../../../components/organismos/folders/created";
import FileCreated from "../../../components/organismos/folders/CreatedFile";
import QRCreated from "../../../components/organismos/qr/QRCreated";
import { useAppSelector } from "../../../store/hook/store.hook";
import useNavigationVisibility from "../../../hooks/useNavigationVisibility";

const Bucket: FC = () => {
    const { bucketId } = useParams<{ bucketId: string }>();
    const { loadBucket, loadRootBucket } = useActionsBucket();
    const { handleClose: handleCloseModal, handleOpen: handleOpen, open: openModal } = useModal()
    const { handleClose: hadleCloseFileModal, handleOpen: handleFileOpen, open: openFileModal } = useModal()
    const { handleClose: handleCloseQRModal, handleOpen: handleQROpen, open: openQrModal } = useModal()

    const profile = useAppSelector((state) => state.profile)
    const allowerSuperAdmin = ['superadmin', 'admin'];
    
    const showNavigationForSuperAdmin = useNavigationVisibility(profile.rol, allowerSuperAdmin);

    useEffect(() => {
        if (bucketId) {
            loadBucket(bucketId);
            loadRootBucket(bucketId);
        }
    }, [bucketId]);


    if (!showNavigationForSuperAdmin) {
        return (
            <>
                ACCESO DENEGADO
            </>
        )
    }


    return (
        <>
            <InfoBar />
            <BucketBreadcrumb />
            <FoldersActions handleOpen={handleOpen} handleFileOpen={handleFileOpen} handleQRCode={handleQROpen}/>
            <PageDndWrapper>
                <BucketExplorer />
            </PageDndWrapper>
            <ModalComponent open={openModal} onClose={handleCloseModal}>
                <FolderCreated handleCloseModal={handleCloseModal} />
            </ModalComponent>
            <ModalComponent open={openFileModal} onClose={hadleCloseFileModal}>
                <FileCreated handleCloseModal={hadleCloseFileModal} />
            </ModalComponent>
            <ModalComponent open={openQrModal} onClose={handleCloseQRModal}>
                <QRCreated handleCloseModal={handleCloseQRModal} />
            </ModalComponent>
        </>
    );
};

export default Bucket;
