export type QRVisited = {
    id: string;
    dateConsult: string
    routePath: string
    latitud: number
    longitud: number
    visitante: {
        email: string
        name: string
    }
    bucket?: {
        id: string
        name: string
    }
}

export type AdminHistory = {
    id: string;
    access_date: string;
    routePath: string;
    action: string;
    user: {
        id: number;
        name: string;
    }
    business_unit: {
        id: string;
        name: string
    }
}

export interface IPagination {
    page: number;
    limit: number;
}

export interface IMeta {
    totalItems: number;
    itemCount: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
}


export interface AdminMonitoringSchema {

    allData: Array<AdminHistory>;
    dataSelected: AdminHistory | null;
    pagination: IPagination;
    meta: IMeta
}

export interface VisitedMonitoringSchema {
    allData: Array<QRVisited>;
    dataSelected: QRVisited | null;
    pagination: IPagination;
    meta: IMeta
}


export interface MonitoringSchema {
    admin: AdminMonitoringSchema;
    visited: VisitedMonitoringSchema;
    error: any;
    loading: boolean;
}


const initialState: MonitoringSchema = {
    admin: {
        allData: [],
        dataSelected: null,
        pagination: {
            page: 0,
            limit: 5
        },
        meta: {
            totalItems: 0,
            itemCount: 0,
            itemsPerPage: 0,
            totalPages: 0,
            currentPage: 0
        }
    },
    visited: {
        allData: [],
        dataSelected: null,
        pagination: {
            page: 0,
            limit: 5
        },
        meta: {
            totalItems: 0,
            itemCount: 0,
            itemsPerPage: 0,
            totalPages: 0,
            currentPage: 0
        }
    },
    error: null,
    loading: false
}

export default initialState;