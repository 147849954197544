
import CircularProgress from '@mui/material/CircularProgress';
import { FC } from "react";

interface ISpinnerProps {
    isLoad: boolean
}

const Spinner: FC<ISpinnerProps> = ({ isLoad }) => {

    return (
        <div style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'rgb(255,255,255, .3)',
            display: isLoad ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10000,
            left: 0,
            top: 0
        }}>
            <CircularProgress size={'3%'} color='primary'/>
        </div>
    )
}

export default Spinner