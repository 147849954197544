import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAppDispatch } from '../../../store/hook/store.hook';
import { loginVisiter } from '../../../store/actions/auth.actions';
import { useState } from 'react';


const schemaAuth = z.object({
    email: z.string({
        required_error: "Debe ingresar el Email.",
    })
        .min(1, "Debe ingresar el Email.")
        .email("Debe ser un correo valido"),
})

const schemaRegister = z.object({
    email: z.string({
        required_error: "Debe ingresar el Email.",
    })
        .min(1, "Debe ingresar el Email.")
        .email("Debe ser un correo valido"),
    name: z.string({
        required_error: "Debe ingresar el Nombre.",
    })
        .min(1, "Debe ingresar el Nombre."),
    empresa: z.string()
        .optional(),
    telefono: z.string()
        .optional(),
    approve: z.boolean({
        required_error: "Aceptar Terminos y condiciones"
    }).default(false).refine(val => val === true, {
        message: "Debes aceptar los términos y condiciones"
    }),
})

type FormDataAuth = z.infer<typeof schemaAuth>
type FormDataRegister = z.infer<typeof schemaRegister>

const useLoginFormVisiter = () => {

    const dispatch = useAppDispatch();
    const { control: controlAuth, handleSubmit: handleSubmitAuth } = useForm<FormDataAuth>({
        resolver: zodResolver(schemaAuth),
    });
    const { control: controlRegister, handleSubmit: handleSubmitRegister } = useForm<FormDataRegister>({
        resolver: zodResolver(schemaRegister),
    });
    const [emailRegister, setEmailRegister] = useState('');


    const onSubmitAuth = (data: FormDataAuth) => {
        // Lógica para el manejo del envío del formulario
        setEmailRegister(data.email)
        dispatch(loginVisiter(data.email))
    };

    const onSubmitRegister = (data: FormDataRegister) => {
        // Lógica para el manejo del envío del formulario
        dispatch(loginVisiter(data.email, data.name, data.empresa, data.telefono))
    };

    return {
        controlAuth,
        handleSubmitAuth: handleSubmitAuth(onSubmitAuth),
        controlRegister,
        handleSubmitRegister: handleSubmitRegister(onSubmitRegister),
        emailRegister
    };
};

export default useLoginFormVisiter;
