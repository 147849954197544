// import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import rootRouter from './routes/app.router'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from '@mui/material'
import theme from './themes/app.theme'
import { PortalProvider } from './contexts/PortalContainerContext'
import { LicenseInfo } from '@mui/x-license'

LicenseInfo.setLicenseKey('e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y')

createRoot(document.getElementById('root')!).render(
  // <StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <PortalProvider>
          <RouterProvider router={rootRouter} />
        </PortalProvider>
      </ThemeProvider>
    </PersistGate>
  </Provider>
  // </StrictMode>,
)
