import React, { createContext, useRef, FC } from "react";

interface PortalContextProps {
    portalContainerRef: React.RefObject<HTMLDivElement>;
}

// Inicializamos el contexto con un valor vacío para evitar undefined
export const PortalContext = createContext<PortalContextProps>({
    portalContainerRef: { current: null },
});

export const PortalProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const portalContainerRef = useRef<HTMLDivElement>(null);

    return (
        <PortalContext.Provider value={{ portalContainerRef }}>
            {children}
        </PortalContext.Provider>
    );
};
