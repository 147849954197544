import { Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

interface TextAtomProps extends TypographyProps{
  children: React.ReactNode;
  color?: string;
  fontSize?: string;
  fontFamily?: string;
}

const TextAtom: FC<TextAtomProps> = ({ variant, children, color, fontSize, fontFamily, ...props }) => (
  <Typography variant={variant} color={color} fontSize={fontSize} fontFamily={fontFamily} {...props}>
    {children}
  </Typography>
);

export default TextAtom;
