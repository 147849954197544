import VisitedGrid from "../../components/organismos/specials/VisitedGrid";
import useVisitedMonitoring from "../../hooks/monitoring/useVisitedMonitorings";
import { Container, Typography } from "@mui/material";


const VisitedMonitored = () => {
    const { ...props } = useVisitedMonitoring()
    return (
        <Container>
            <Typography variant="h3"  gutterBottom>Visitas QR</Typography>
            <VisitedGrid {...props} />
        </Container>
    )
}

export default VisitedMonitored;