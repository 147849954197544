import { Button, styled } from "@mui/material"
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import { FC } from "react";
import { useAppSelector } from "../../../store/hook/store.hook";
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';

const Wrapper = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '2rem',
    marginBottom: '1rem'
}))

interface IFoldersActionsProps {
    handleOpen: () => void
    handleFileOpen: () => void
    handleQRCode: () => void
}

const FoldersActions: FC<IFoldersActionsProps> = ({ handleOpen, handleFileOpen, handleQRCode }) => {

    const { opened } = useAppSelector(state => state.fileExplorer)

    const handleNewFolder = async () => {
        handleOpen()
    }

    return (
        <Wrapper>
            <Button
                component={"label"}
                role={undefined}
                variant="contained"
                startIcon={<CreateNewFolderOutlinedIcon />}
                onClick={handleNewFolder}
            >
                Crear Carpeta
            </Button>
            {
                opened && (
                    <Button
                        component={"label"}
                        role={undefined}
                        variant="contained"
                        startIcon={<NoteAddOutlinedIcon />}
                        onClick={handleFileOpen}
                    >
                        Subir Archivo
                    </Button>
                )
            }
            <Button
                component={"label"}
                role={undefined}
                variant="contained"
                startIcon={<QrCodeScannerOutlinedIcon />}
                onClick={handleQRCode}
                >
                    QR
            </Button>
        </Wrapper>
    )
}

export default FoldersActions