import { FC, useEffect } from 'react';
import CustomPaper from '../../atomos/Paper';
import { Control, Controller } from 'react-hook-form';
import LoginBox from '../../atomos/login/LoginBox';
import WrapperBox from '../../atomos/login/Wrapper';
import HeaderBox from '../../atomos/login/HeaderBox';
import TextAtom from '../../atomos/Text';
import Form from '../../atomos/Form';
import ButtonAtom from '../../atomos/Button';
import InputMessage from '../../moleculas/InputMessage';
import { useAppDispatch, useAppSelector } from '../../../store/hook/store.hook';
import { clearRegisterOtp, unMount } from '../../../store/slices/auth.slice';
import { Box, CircularProgress } from '@mui/material';
import ValidateOtpComponent from './ValidateOtp';
import CheckerMessage from '../../moleculas/CheckerMessage';

interface IPropsLoginForm {
    controlAuth: Control<{
        email: string;
    }>
    handleSubmitAuth: (e?: React.BaseSyntheticEvent) => Promise<void>
    controlRegister: Control<{
        email: string;
        name: string;
        empresa?: string;
        telefono?: string;
        approve: boolean
    }>
    handleSubmitRegister: (e?: React.BaseSyntheticEvent) => Promise<void>
    controlOTP: Control<{
        codeOtp: string
    }>
    handleSubmitOTP: (e?: React.BaseSyntheticEvent) => Promise<void>
    emailRegister: string
}

const LoginForm: FC<IPropsLoginForm> = ({ handleSubmitAuth, controlAuth, handleSubmitRegister, controlRegister, emailRegister }) => {

    const dispatch = useAppDispatch();
    const {
        validateOtp,
        registerOtp,
        loading
    } = useAppSelector(state => state.auth);


    useEffect(() => {
        dispatch(unMount())

        return () => {
            dispatch(unMount())
        }

    }, [])

    return (
        <LoginBox>
            <WrapperBox position='flex-end'>
                <CustomPaper elevation={0}>


                    <CircularProgress size={'3%'} sx={{ display: loading ? 'flex' : 'none' }} />
                    {
                        !validateOtp ?
                            <>
                                <HeaderBox>
                                    {

                                        registerOtp ?
                                            (
                                                <>
                                                    <TextAtom variant="h4" color="primary" fontFamily='TitilliumWeb'>
                                                        <b>Registro</b>
                                                    </TextAtom>
                                                    <TextAtom variant="body2" color="textSecondary" sx={{ marginTop: '2%' }}>
                                                        Completa los siguientes datos para continuar con el proceso
                                                    </TextAtom>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <TextAtom variant="h4" color="primary" fontFamily='TitilliumWeb' mb={7}>
                                                        <b>Ingresa</b> a tu cuenta
                                                    </TextAtom>
                                                    <TextAtom variant="body2" color="textSecondary">
                                                        Bienvenido. Ingresa tu email para acceder a la documentación de tu proyecto
                                                    </TextAtom>
                                                </>
                                            )

                                    }
                                </HeaderBox>
                                {
                                    !registerOtp && (
                                        <Form onSubmit={handleSubmitAuth}>
                                            <Controller
                                                name='email'
                                                control={controlAuth}
                                                render={({ field, fieldState }) => (
                                                    <InputMessage
                                                        label="Email"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        field={field}
                                                        fieldState={fieldState}
                                                    />
                                                )
                                                }
                                            />
                                            <ButtonAtom type='submit' sx={{ mt: 5, alignSelf: 'flex-end' }}>Ingresar</ButtonAtom>
                                        </Form>
                                    )
                                }
                                {
                                    registerOtp && (
                                        <Form onSubmit={handleSubmitRegister}>
                                            <Controller
                                                name='email'
                                                control={controlRegister}
                                                defaultValue={emailRegister}
                                                render={({ field, fieldState }) => (
                                                    <InputMessage
                                                        label="Email *"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        field={field}
                                                        fieldState={fieldState}
                                                    />
                                                )
                                                }
                                            />
                                            <Controller
                                                name='name'
                                                control={controlRegister}
                                                render={({ field, fieldState }) => (
                                                    <InputMessage
                                                        label="Nombre *"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        field={field}
                                                        fieldState={fieldState}
                                                    />
                                                )
                                                }
                                            />
                                            <Controller
                                                name='empresa'
                                                control={controlRegister}
                                                render={({ field, fieldState }) => (
                                                    <InputMessage
                                                        label="Empresa"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        field={field}
                                                        fieldState={fieldState}
                                                    />
                                                )
                                                }
                                            />
                                            <Controller
                                                name='telefono'
                                                control={controlRegister}
                                                render={({ field, fieldState }) => (
                                                    <InputMessage
                                                        label="Telefono"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="normal"
                                                        field={field}
                                                        fieldState={fieldState}
                                                    />
                                                )
                                                }
                                            />
                                            <Controller
                                                name='approve'
                                                control={controlRegister}
                                                render={({ field, fieldState }) => (
                                                    <CheckerMessage
                                                        aria-label='Términos y condiciones.'
                                                        field={field}
                                                        fieldState={fieldState}
                                                    />
                                                )
                                                }
                                            />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                <ButtonAtom sx={{ mt: 5, alignSelf: 'flex-start' }} onClick={() => dispatch(clearRegisterOtp())}>Regresar</ButtonAtom>
                                                <ButtonAtom type='submit' sx={{ mt: 5, alignSelf: 'flex-end' }}>Ingresar</ButtonAtom>
                                            </Box>
                                        </Form>
                                    )
                                }
                            </>
                            :
                            <ValidateOtpComponent handleSubmitAuth={handleSubmitAuth} emailRegister={emailRegister} />
                    }

                </CustomPaper>
            </WrapperBox>
        </LoginBox>
    );
};

export default LoginForm;
