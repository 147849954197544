import { styled } from '@mui/material/styles';
import { Paper } from "@mui/material";


const CustomPaper = styled(Paper)(({ theme }) => ({
    borderRadius: '5%',
    height: 'auto',
    width: '70%',
    display: 'flex',
    rowGap: '4%',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '4% 5% 4% 5%;',
    minHeight: '60%',
    [theme.breakpoints.down('xl')]: {
        width: '70%'
    },
    [theme.breakpoints.down('lg')]: {
        width: '80%'
    },
    [theme.breakpoints.down('md')]: {
        alignSelf: 'center',
        width: '70%',
    },
    [theme.breakpoints.down('sm')]: {
        width: '95%',
        rowGap: '20px',
        alignSelf: 'center',
    },
}))

export default CustomPaper