
import { LinkProps, Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { FC } from 'react';


interface MuiLinkProps extends LinkProps {
    children: React.ReactNode
}

const StyledLink: FC<MuiLinkProps> = ({children, ...props}) => {

    return  (
        <Link component={RouterLink} {...props}>
            {
                children
            }
        </Link>
    )
}
export default StyledLink;