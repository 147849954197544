
import { styled } from "@mui/material";


const WrapperControl = styled('div')(({}) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
}))

export default WrapperControl