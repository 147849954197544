
const CreatedTemplateForm = () => {

  return (
    <div>
      
    </div>
  );

};

export default CreatedTemplateForm;
