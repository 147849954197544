import { Breadcrumbs, Link, styled } from "@mui/material"
import { useAppSelector } from "../../../store/hook/store.hook"
import { useNavigate, useParams } from "react-router-dom"
const BrCr = styled(Breadcrumbs)(({ }) => ({
    marginBottom: '2rem'
}))

const Lk = styled(Link)(({ }) => ({
    cursor: 'pointer'
}))


const CustomRecursosBreadcrumb = () => {

    const fileExplorerState = useAppSelector((state) => state.fileExplorer)
    const { folderId } = useParams<{ folderId: string }>()
    const navigation = useNavigate()
    const equal = folderId === fileExplorerState.rootPath?.split('/')[3]


    const handleClickBefore = () => {
        return navigation(`/dashboard/folder/${fileExplorerState.parent?.id}`)
    }

    return (
        <BrCr aria-label="breadcrumb">
            {
                fileExplorerState.parent && !equal && (
                    <Lk underline="hover" onClick={handleClickBefore}>
                        {fileExplorerState.parent.name}
                    </Lk>
                )
            }
            {
                fileExplorerState.opened && (
                    <Lk color="secondary" sx={{ cursor: 'text', textDecoration: 'none' }}>
                        {fileExplorerState.opened.name}
                    </Lk>
                )
            }
        </BrCr>
    )
}

export default CustomRecursosBreadcrumb