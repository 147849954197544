import { TextField } from "@mui/material"
import WrapperFilterContainer from "../../atomos/WrapperFilterContainer"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../store/hook/store.hook"
import { getAllTemplates } from "../../../store/actions/templates.action"


const TemplateFilterController = () => {

    const dispatch = useAppDispatch()
    const { paginated } = useAppSelector(state => state.templates)
    const [search, setSearch] = useState<string>("")

    useEffect(() => {
        dispatch(getAllTemplates({
            page: paginated!.page === 0 ? 1 : paginated!.page,
            limit: paginated!.limit,
            search
        }))
    }, [search])

    return (
        <WrapperFilterContainer>
            <TextField value={search} onChange={(e) => setSearch(e.target.value)} type="search" label="Buscar" sx={{ width: '40%' }} />
        </WrapperFilterContainer>
    )
}

export default TemplateFilterController