import { FC } from "react"
import WrapperViewer from "../wrappers/WrapperViewer"
import ToolTipNav from "./tools/nav"
import Rendered from "./tools/rendered"
import { toolbarPlugin } from "@react-pdf-viewer/toolbar"

interface IViewerProps {
    open: boolean
    onClose: () => void
    fileProps: any
}

const Viewer: FC<IViewerProps> = ({ open, onClose, fileProps }) => {

    const toolbarPluginInstance = toolbarPlugin();

    return (
        <WrapperViewer
            open={open}
            onClose={onClose}
            childrens={[
                <ToolTipNav toolbarPlugin={toolbarPluginInstance} fileProps={fileProps} onClose={onClose} key={"nav"}/>,
                <Rendered fileProps={fileProps} toolbarPlugin={toolbarPluginInstance} key={"rendered"}/>
            ]}>
            <></>
        </WrapperViewer>
    )
}

export default Viewer