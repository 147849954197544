import { Container, Typography } from "@mui/material"
import TemplateCreatedForm from "../../components/organismos/plantillas/forms/CreatedForm"


const NewTemplate = () => {
    

    return (
        <Container>
            <Typography variant="h3"  gutterBottom>Nueva Plantilla</Typography>

            <TemplateCreatedForm />
        </Container>
    )
}

export default NewTemplate