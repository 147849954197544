import { useNavigate, useParams } from "react-router-dom";
import useReloadFileExplorer from "../../hooks/fileExplorer/useReloadFileExplorer";
import { useEffect } from "react";
import ResourceExplorer from "../../components/file-explorer/Resource";
import InfoBar from "../../components/moleculas/recursos/InfoBar";
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook";
import { resetFileExplorer } from "../../store/slices/fileExplorer.slice";
import Spinner from "../../components/atomos/Spinner";
import { Typography } from "@mui/material";
import RecursosBreadcrumb from "../../components/moleculas/recursos/Breadcrumb";
import { setInfoFolder } from "../../store/actions/recursos.actions";

const FolderResource = () => {

    const navigate = useNavigate()

    const { recursoId, folderId } = useParams<{ recursoId: string, folderId: string }>()

    const { set } = useReloadFileExplorer({ proyectoId: recursoId!, folderId: folderId! })
    const fileExplorerState = useAppSelector((state) => state.fileExplorer)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setInfoFolder(folderId!))
        set()
        return () => {
            dispatch(resetFileExplorer())
        }
    }, [folderId, recursoId])

    if (recursoId === undefined || recursoId === "undefined") {
        navigate("/dashboard/folder/" + folderId, {
            state: {
                from: "/dashboard/folder/" + folderId
            }
        })
    }

    return (
        <>
            <Spinner isLoad={fileExplorerState.loading || false} />
            {
                !fileExplorerState.loading && !fileExplorerState.error && (
                    <>
                        <InfoBar>
                            Recursos
                        </InfoBar>
                        <RecursosBreadcrumb />
                        <ResourceExplorer proyectoId={recursoId!} />
                    </>
                )
            }
            <Typography variant="h6" color="error">
                {fileExplorerState.error}
            </Typography>
        </>
    );
}

export default FolderResource;
