import { Control, Controller } from "react-hook-form";
import { FileFormTypeSchema } from "../../../hooks/folders/useFileForm";
import { FC } from "react";
import Form from "../../atomos/Form";
import { MuiFileInput } from "mui-file-input";
import { Box, Button } from "@mui/material";

interface FileFormProps {
    control: Control<FileFormTypeSchema>;
    handleSubmit: any;
    handleCloseModal: () => void;
}

const FileForm: FC<FileFormProps> = ({ control, handleSubmit, handleCloseModal }) => {
    return (
        <Form style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Controller
                name="files"
                control={control}
                render={({ field, fieldState }) => (
                    <MuiFileInput
                        multiple
                        label="Archivos"
                        {...field}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        inputProps={{
                            accept: "application/pdf, image/jpeg, image/png, image/webp",
                        }}
                    />
                )}
            />

            <Box sx={{ display: 'flex', gap: '1rem', width: '100%', justifyContent: 'flex-end' }}>
                <Button type="submit" variant="contained" onClick={() => {
                    handleSubmit()
                    handleCloseModal()
                }}>Guardar</Button>
                <Button onClick={handleCloseModal} variant="contained">Cancelar</Button>
            </Box>
        </Form>
    );
};

export default FileForm;
