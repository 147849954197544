import { useEffect, useState } from "react"
import { getInfoProject } from "../../store/actions/projects.action"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook"
import { BusinessUnit } from "../../store/schemas/project.schema"

type paramsLoadProject = {
    businessUnit?: boolean
}

const useProjects = (projectId: string) => {

    const dispatch = useAppDispatch()
    const { selected } = useAppSelector(state => state.projects)
    const [bssUnits, setBssUnits] = useState<Array<BusinessUnit>>([])

    useEffect(() => {
        if (selected) {
            setBssUnits(
                selected.buckets ? selected.buckets.map(bu => bu.business_unit!) : []
            )
        }
    }, [selected])

    const loadProject = (params: paramsLoadProject) => {
        const queryParams = new URLSearchParams();
        queryParams.append("managements", "true");
        if (params.businessUnit) {
            queryParams.set('businessUnit', 'true');
        }

        dispatch(getInfoProject(projectId, queryParams))
    }


    return {
        loadProject,
        bssUnits
    }
}

export default useProjects