import { Button, ButtonProps, styled } from '@mui/material';
import React, { ReactNode } from 'react';

interface ButtonAtomProps extends ButtonProps {
  children: ReactNode;
  onClick?: () => void;
  sx?: object;
  fullWidth?: boolean;
}

const StyledBuutton = styled(Button)(({ theme }) => ({

  width: '30%',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  [theme.breakpoints.down('xl')]: {
    width: '30%',
  },
  [theme.breakpoints.down('md')]: {
    width: '30%',
    fontSize: '1.3rem',
    marginTop: '5%',
  },
  [theme.breakpoints.down('xs')]: {
    width: '30%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '30%',
    fontSize: '.9rem',
  }
}))

// Usamos forwardRef para permitir el uso de referencias en ButtonAtom
const ButtonAtom = React.forwardRef<HTMLButtonElement, ButtonAtomProps>(
  ({ children, onClick, sx, fullWidth = false, ...props }, ref) => (
    <StyledBuutton
      variant="contained"
      color="primary"
      fullWidth={fullWidth}
      sx={sx}
      onClick={onClick}
      ref={ref} // Pasamos la referencia al componente Button
      {...props} // Asegúrate de pasar las props restantes
    >
      {children}
    </StyledBuutton>
  )
);

// Nombre del componente para la depuración
ButtonAtom.displayName = 'ButtonAtom';

export default ButtonAtom;
