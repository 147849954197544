import { useEffect } from "react"
import { getOnlyOneBucket } from "../../store/actions/buckets.action"
import { loadRootFileExplorer } from "../../store/actions/recursos.actions"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook"
import { loadOpened } from "../../store/slices/fileExplorer.slice"
import { getInfoProject } from "../../store/actions/projects.action"


const useActionsBucket = () => {

    const dispatch = useAppDispatch()
    const { selected } = useAppSelector(state => state.buckets)

    useEffect(() => {
        const tmpParams = new URLSearchParams()
        tmpParams.append("managements", "true")
        if (selected) {
            dispatch(getInfoProject(selected!.project_id, tmpParams))
        }
        dispatch(loadOpened())
    }, [selected])

    const loadBucket = (id: string) => {
        dispatch(getOnlyOneBucket(id))

    }

    const loadRootBucket = (id: string) => {
        dispatch(loadRootFileExplorer(id))
    }

    return {
        loadBucket,
        loadRootBucket
    }
}

export default useActionsBucket