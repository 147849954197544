import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState, { Project } from "../schemas/project.schema";

const projectSlice = createSlice({
    name: 'project',
    initialState: initialState,
    reducers: {
        loadSelectedProject: (state, action: PayloadAction<Project>) => {
            state.selected = action.payload
            state.loading = false
            state.error = null
            state.projects = []
        },
        eventError: (state, action: PayloadAction<string>) => {
            state.error = action.payload
            state.loading = false
        },
        processingApi: (state) => {
            state.loading = true
            state.error = null
            state.selected = null
            state.projects = []
        },
        clearSelectedProject: (state) => {
            state.selected = null
            state.loading = false
        },
        errorInLoadProject: (state, action: PayloadAction<string>) => {
            state.projects = []
            state.selected = null
            state.loading = false
            state.error = action.payload
        },
        clearError: (state) => {
            state.error = null
        }
    }
})


export const { processingApi, loadSelectedProject, eventError, clearSelectedProject, errorInLoadProject, clearError } = projectSlice.actions;

export default projectSlice.reducer