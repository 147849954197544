import { styled } from "@mui/material";

const PageDndWrapper = styled('div')(() => ({
    display: 'block',
    width: '100%',
    height: 'auto',
    alignItems: 'center',
    overflow: 'hidden',
    padding: '1rem',
}))

export default PageDndWrapper