import { DataGridPremium, GridColDef, GridValidRowModel, GridPaginationModel } from "@mui/x-data-grid-premium";
import { esES } from "@mui/x-data-grid-premium/locales";

interface DataGridSSRProps<T extends GridValidRowModel> {
    rows: T[];
    columns: GridColDef<T>[];
    rowCount: number; // Total de filas desde el backend
    onPaginationModelChange: (model: GridPaginationModel) => void; // Cambios de página y tamaño
    paginationModel: GridPaginationModel; // Estado actual de la paginación
    loading?: boolean
}

const DataGridSSR = <T extends GridValidRowModel>({
    rows,
    columns,
    rowCount,
    onPaginationModelChange,
    paginationModel,
    loading,
    ...props
}: DataGridSSRProps<T>) => {

    return (
        <DataGridPremium
            columns={columns}
            rows={rows}
            pagination
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            disableColumnFilter
            disableColumnMenu
            disableColumnSorting
            loading={loading}
            slots={{
                toolbar: () => null,
            }}
            pageSizeOptions={[5, 10, 20]}
            rowCount={rowCount}
            paginationModel={paginationModel}
            onPaginationModelChange={onPaginationModelChange}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            {...props}
        />
    );
};

export default DataGridSSR;
