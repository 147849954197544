import { Button, Grid2, styled } from "@mui/material"
import React, { FC } from "react"
import { Control, UseFormGetValues, UseFormTrigger } from "react-hook-form"
import EditableProjectForm from "../../../moleculas/projects/form/EditableForm"
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import { useNavigate, useParams } from "react-router-dom";
import { FormDataEditableType } from "../../../../hooks/projects/useFormProject";
import { useAppDispatch } from "../../../../store/hook/store.hook";
import { updateProject } from "../../../../store/actions/projects.action";
import { clearSelectedProject } from "../../../../store/slices/project.slice";

const WrapResponsiveProjectForm = styled(Grid2)(({ }) => ({
    width: '100%',
    textAlign: 'center',
}))

const Actions = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem'
}))


interface IProjectFormContainerProps {
    control: Control<FormDataEditableType>,
    trigger: UseFormTrigger<FormDataEditableType>,
    getValues: UseFormGetValues<FormDataEditableType>,
    errors: any
}



const EditableProjectFormContainer: FC<IProjectFormContainerProps> = ({ control, trigger, getValues }) => {

    const navigate = useNavigate()
    const { projectId } = useParams()
    const dispatch = useAppDispatch()

    const handleSave = async () => {
        try {
            const isValid = await trigger();

            if (isValid) {
                const values = getValues();

                projectId && dispatch(updateProject(projectId, {
                    name: values.name,
                    cliente: values.cliente,
                    status_project_id: values.status_project_id,
                    warranty: values.warranty,
                    description: values.description,
                    ubication: values.ubication && {
                        direccion: values.ubication.direccion,
                    } || null,
                    managements: values.managements || []
                }))

                dispatch(clearSelectedProject())
                navigate(-1)
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <React.Fragment>
            <WrapResponsiveProjectForm container spacing={2} columns={2}>
                <EditableProjectForm control={control} />
            </WrapResponsiveProjectForm>
            <Actions>
                <Button
                    component="label"
                    variant="contained"
                    startIcon={<DoDisturbAltOutlinedIcon />}
                    onClick={() => {
                        dispatch(clearSelectedProject())
                        navigate(-1)
                    }}
                >
                    Cancelar
                </Button>
                <Button
                    component="label"
                    variant="contained"
                    startIcon={<SaveOutlinedIcon />}
                    onClick={handleSave}
                >
                    Guardar
                </Button>
            </Actions>
        </React.Fragment>
    )
}

export default EditableProjectFormContainer