import { FC } from "react"
import { Project as ProjectSchema } from "../../../store/schemas/project.schema"
import { Chip, Divider, Grid2, styled, TextField } from "@mui/material"
import useStateProject from "../../../hooks/projects/useStateProject"


const Item = styled(Grid2)(({ }) => ({
    justifyContent: 'center',
    placeItems: 'center'
}))

const FieldItem = styled(TextField)(({ }) => ({
    width: '70%',
}))

const WrapCodes = styled('div')(() => ({
    width: '100%',
    
}))

interface IProjectProps {
    project: ProjectSchema
}

const Project: FC<IProjectProps> = ({ project }) => {

    const { name, cliente, status_project_id, warranty, description, ubication, managements, old_ga } = project
    const { getName } = useStateProject()

    return (
        <>
            <Item size={1}>
                <WrapCodes>
                    {
                        managements && managements.length >= 1 ? (
                            <>
                                Identificador:
                                {
                                    managements.map((management, index) => (
                                        <Chip
                                            key={index}
                                            label={management.code}
                                            sx={{borderRadius: 1, marginLeft: 1}}
                                            variant="outlined"
                                            color="primary"
                                        />
                                    ))
                                }
                            </>
                        ) : (
                            <>
                                Identificador:
                                <Chip
                                    label={old_ga}
                                    sx={{borderRadius: 1, marginLeft: 1}}
                                    variant="outlined"
                                    color="primary"
                                />
                            </>
                        )
                    }
                </WrapCodes>
            </Item>
            <Divider />
            <Item size={1}>
                <FieldItem
                    label="Nombre del Proyecto"
                    variant="standard"
                    value={name || ""}
                    disabled
                />
            </Item>
            <Item size={1}>
                <FieldItem
                    label="Cliente del Proyecto"
                    variant="standard"
                    value={cliente || ""}
                    disabled
                />
            </Item>
            <Item size={1}>
                <FieldItem
                    label="Garantia"
                    variant="standard"
                    value={warranty || "No Definido."}
                    disabled
                />
            </Item>
            <Item size={1}>
                <FieldItem
                    label="Descripción del Proyecto"
                    variant="standard"
                    value={description || "Vacío."}
                    disabled
                />
            </Item>
            <Item size={1}>
                <FieldItem
                    label="Estado del Proyecto"
                    variant="standard"
                    value={getName(status_project_id)}
                    disabled
                />
            </Item>
            <Item size={1}>
                <FieldItem
                    label="Ubicación del Proyecto"
                    variant="standard"
                    value={ubication?.direccion || "No Definido."}
                    disabled
                />
            </Item>
        </>
    )
}

export default Project