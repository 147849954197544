import { FC } from 'react';
import CustomPaper from '../../atomos/Paper';
import ButtonAtom from '../../atomos/Button';
import TextAtom from '../../atomos/Text';
import { Control, Controller } from 'react-hook-form';
import Form from '../../atomos/Form';
import LoginBox from '../../atomos/login/LoginBox';
import HeaderBox from '../../atomos/login/HeaderBox';
import WrapperBox from '../../atomos/login/Wrapper';
import InputMessage from '../../moleculas/InputMessage';

interface IPropsLoginForm {
    handleSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
    control: Control<{
        email: string;
        password: string;
    }, any>
}

const LoginForm: FC<IPropsLoginForm> = ({ handleSubmit, control }) => {

    return (
        <LoginBox>
            <WrapperBox position='flex-end'>
                <CustomPaper elevation={0}>
                    <HeaderBox>
                        <TextAtom variant="h4" color="primary" fontFamily='TitilliumWeb' mb={3}>
                            <b>Ingresa</b> a tu cuenta
                        </TextAtom>
                        <TextAtom variant="body2" color="textSecondary">
                            Por favor, ingresa tus credenciales de administrador para acceder al sistema.
                        </TextAtom>
                        <TextAtom variant="body2" color="textSecondary">
                            Si no tienes una cuenta o has olvidado tus credenciales, contacta con el soporte para obtener asistencia.
                        </TextAtom>
                    </HeaderBox>
                    <Form onSubmit={handleSubmit}>
                        <Controller
                            name='email'
                            control={control}
                            render={({ field, fieldState }) => (
                                <InputMessage
                                    label="Email *"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    field={field}
                                    fieldState={fieldState}
                                />
                            )}
                        />

                        <Controller
                            name='password'
                            control={control}
                            render={({ field, fieldState }) => (
                                <InputMessage
                                    label="Contraseña *"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                    type='password'
                                    field={field}
                                    fieldState={fieldState}
                                />
                            )}
                        />

                        <ButtonAtom type='submit' sx={{ mt: 2, alignSelf: 'flex-end' }}>Ingresar</ButtonAtom>
                    </Form>
                </CustomPaper>
            </WrapperBox>
        </LoginBox>

    )
};

export default LoginForm;
