import { FC } from "react";
import LabelFolder from "../../atomos/explorer/LabelFolder";
import WrapperButton from "../../atomos/explorer/wrapperButton";
import ButtonAtachment from "../../atomos/explorer/ButtonAttachment";
import ControlItem from "./control";


interface IAttachmentProps {
    fileName: string
    id: string
    filePath: string
    fileSize: number
    fileType: string
    created_at?: string
    onClick: () => void
    isAdmin?: boolean
}

const Attachment: FC<IAttachmentProps> = ({ id, fileName, filePath, fileSize, fileType, created_at, onClick, isAdmin }) => {

    const infoProps = {
        id,
        fileName,
        filePath,
        fileSize,
        fileType,
        createdDate: created_at,
        type: 'attachment'
    }

    return (
        <WrapperButton title={fileName}>
            <ControlItem infoProps={infoProps} isAdmin={isAdmin}/>
            <ButtonAtachment  onClick={onClick}/>
            <LabelFolder >
                {fileName}
            </LabelFolder>
        </WrapperButton>
    )
}

export default Attachment