import { GridColDef, GridPaginationModel, GridRenderCellParams } from "@mui/x-data-grid-premium";
import DataGridSSR from "../../moleculas/datagrids";
import { IconButton } from "@mui/material";
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ActionsWrapper from "../../atomos/ActionWrapper";
import { Template } from "../../../store/schemas/template.schema";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hook/store.hook";
import { changePaginated } from "../../../store/slices/templates.slice";

const DataGridTemplates = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { templates, meta, paginated, loading } = useAppSelector((state) => state.templates);

    const columns: GridColDef<Template>[] = [
        {
            field: "id",
            headerName: "ID",
        },
        {
            field: "Nombre",
            headerName: "Nombre",
            flex: 1,
            renderCell: (params: GridRenderCellParams<Template>) => params.row.name
        },
        {
            field: 'created_at',
            headerName: 'Fecha Creación',
            flex: 0.5,
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            headerAlign: 'center',
            renderHeader: () => '',
            flex: 0.5,
            renderCell: (params: GridRenderCellParams<Template>) => (
                <ActionsWrapper>
                    <IconButton title="editar"
                     onClick={() => navigate(`/dashboard/templates/${params.row.id}`)}
                    >
                        <EditNoteOutlinedIcon />
                    </IconButton>
                    <IconButton disabled title="eliminar">
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                </ActionsWrapper>
            )
        }

    ];

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        dispatch(changePaginated({
            page: model.page,
            limit: model.pageSize
        }))
    };


    return (
        <>
            <DataGridSSR rows={templates || []} columns={columns}
                rowCount={meta && meta.totalItems ? meta.totalItems : 0}
                paginationModel={{
                    page: paginated!.page,
                    pageSize: paginated!.limit
                }}
                onPaginationModelChange={handlePaginationModelChange}
                loading={loading}
            />
        </>
    );
};

export default DataGridTemplates;
