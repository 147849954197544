import { Container, CssBaseline } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import DashboardDrawer from "../components/organismos/dashboard/dashboardDrawer";
import { useBusinessUnit } from "../hooks/businessUnit/useBusinessUnit";
import Spinner from "../components/atomos/Spinner";


const DashboardLayout: FC = () => {

  const { loadAllBusinessUnits, businessUnitState } = useBusinessUnit()

  useEffect(() => {
    loadAllBusinessUnits()
  }, [])

  return (
    <React.Fragment>
      <CssBaseline />
      <Container sx={{ margin: 0 }} maxWidth={false} >
        <DashboardDrawer businessUnits={businessUnitState.businessUnits}>
          <Spinner isLoad={businessUnitState.loading || false} />
          <Outlet />
        </DashboardDrawer>
      </Container>
    </React.Fragment>
  );
};

export default DashboardLayout;
