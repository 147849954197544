import { Grid2, styled } from "@mui/material"
import { NavLink } from "react-router-dom"


const TmpGrid = styled(Grid2)(({ theme }) => ({
    height: '100%',
    'a': {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
        fontSize: '1.2rem',
        '&:hover': {
            textDecoration: 'underline'
        },
        fontWeight: 'bold'
    }
}))

const SettingsApp = () => {

    return (
        <TmpGrid container>
            <TmpGrid size={12}>
                <NavLink to={'/dashboard/visitedMonitored'}>Historial visitantes QR</NavLink>
            </TmpGrid>
            <TmpGrid size={12}>
                <NavLink to={'/dashboard/adminMonitored'}>Historial Administrador</NavLink>
            </TmpGrid>
        </TmpGrid>
    )
}

export default SettingsApp