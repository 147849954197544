import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import { ProjectIcon } from '../assets/icons/grey/proyectosGris';
import { ServiciosIcon } from '../assets/icons/grey/serviciosGris';
import { DigitalIcon } from '../assets/icons/grey/digitalGris';
import { EnergyIcon } from '../assets/icons/grey/energyGris';
import { SolucionesGrisIcon } from '../assets/icons/grey/solucionesGris';
import { InsideIcon } from '../assets/icons/grey/insideGris';

const useIconLoader = () => {

    const getIcon = (nameIcon: string, width?: number, height?: number) => {

        const sx = {
            width: width || 30,
            height: height || 30
        }

        if (nameIcon === 'proyectos') {
            return <ProjectIcon sx={sx}/>
        }

        if (nameIcon === 'servicios') {
            return <ServiciosIcon sx={sx}/>
        }

        if (nameIcon === 'digital') {
            return <DigitalIcon sx={sx}/>
        }

        if (nameIcon === 'energy') {
            return <EnergyIcon sx={sx}/>
        }

        if (nameIcon === 'soluciones') {
            return <SolucionesGrisIcon sx={sx}/>
        }

        if (nameIcon === 'inside sales') {
            return <InsideIcon sx={sx}/>
        }

        return <ConstructionOutlinedIcon sx={sx}/>

    }

    return {
        getIcon
    }
}

export default useIconLoader