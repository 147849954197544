import { Container } from "@mui/material"
import TargetsWrapper from "../atomos/TargetsWrap"
import TargeGridItem from "../atomos/TargetItem"
import { FC, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../store/hook/store.hook"
import Attachment from "./entity/attachment"
import Folder from "./entity/folder"
import Viewer from "./viewers"
import useModal from "../../hooks/useModal"


const ResourceExplorer: FC<{ proyectoId: string, }> = ({ proyectoId }) => {


    const navigation = useNavigate()
    const [fileProps, setFileProps] = useState({})

    const { handleClose: handleCloseModal, handleOpen: handleOpen, open: openModal } = useModal()
    const { folders, attachments } = useAppSelector((state) => state.fileExplorer)

    const handleClick = (folderId: string) => {
        if (proyectoId) {
            navigation(`/dashboard/recursos/${proyectoId}/folders/${folderId}`)
        } else {
            navigation(`/dashboard/folder/${folderId}`)
        }
    }

    const handleClickAttachment = (fileProps: any) => {
        setFileProps(fileProps)
        handleOpen()
    }
    return (
        <Container maxWidth={false} sx={{ padding: '0px !important' }} >
            <TargetsWrapper columns={{ xs: 2, sm: 6, md: 12, lg: 12, xl: 14 }}>
                {
                    folders.map((flder) => {

                        return (
                            <TargeGridItem size={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} key={flder.id}>
                                <Folder sequence={flder.sequence} name={flder.name} id={flder.id} createdDate={new Date().toString()} clickAccess={handleClick} />
                            </TargeGridItem>
                        )
                    })
                }
                {
                    attachments.map((at) => {

                        return (
                            <TargeGridItem size={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} key={at.id}>
                                <Attachment
                                    id={at.id}
                                    fileName={at.fileName}
                                    fileType={at.fileType}
                                    fileSize={at.fileSize}
                                    filePath={at.filePath}
                                    onClick={() => handleClickAttachment(at)}
                                />
                            </TargeGridItem>
                        )
                    })
                }
                <Viewer open={openModal} onClose={handleCloseModal} fileProps={fileProps} />
            </TargetsWrapper>

        </Container>
    )
}

export default ResourceExplorer