import { styled } from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../../store/hook/store.hook"
import { ConfigTemplate } from "../../../store/schemas/template.schema"
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view-pro"
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { getAllTemplates } from "../../../store/actions/templates.action";

const WrapperViewerTemplate = styled('div')(({ }) => ({
    width: '100%',
    minHeight: '25vh',
    maxHeight: 'auto',
    textAlign: 'center',
}))

const ViewerTemplate = () => {

    const { loading, selected } = useAppSelector(state => state.templates)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getAllTemplates())
    }, [])

    if (loading) {
        return (
            <WrapperViewerTemplate>
                Cargando ...
            </WrapperViewerTemplate>
        )
    }
    if (!selected || !selected.config) {

        return (
            <WrapperViewerTemplate>
                No se ha seleccionado ninguna plantilla
            </WrapperViewerTemplate>
        )
    }

    const transformTreeData = (data: Array<ConfigTemplate>): any => {
        if (typeof data === 'string') {
            data = JSON.parse(data)
        }
        return data.map(
            (
                { text, children, ...rest }
            ) => (
                <TreeItem itemId={uuidv4()} key={uuidv4()} label={text} {...rest}>
                    {children ? transformTreeData(children) : []}
                </TreeItem>
            ));
    };


    // Transforma los datos antes de pasarlos al componente Tree
    const treeData = transformTreeData(selected.config);

    return (
        <WrapperViewerTemplate>
            <SimpleTreeView>
                {treeData}
            </SimpleTreeView>
        </WrapperViewerTemplate>
    )
}

export default ViewerTemplate