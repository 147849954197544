import { FC } from "react";
import ModalComponent from "../../../moleculas/Modal";
import InfiniteLoader from "../../../moleculas/infiniteloader/InfiniteLoader";
import useGetProjectsAndTemplates from "../../../../hooks/projects/useGetProjectsAndTemplates";

interface IModalProjectSelectorProps {
    projects: Array<any>
    openModal: boolean
    handleClose: () => void
}

const ModalProjectSelector: FC<IModalProjectSelectorProps> = ({  openModal, handleClose }) => {

    const { loadNextPage, setSearch, isLoading, projects } = useGetProjectsAndTemplates()


    return (
        <ModalComponent open={openModal} onClose={handleClose}>
            <InfiniteLoader projects={projects} loadNextPage={loadNextPage} setSearch={setSearch} isLoading={isLoading} handleClose={handleClose} />
        </ModalComponent>
    )
}

export default ModalProjectSelector