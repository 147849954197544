import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook";
import { uploadFiles } from "../../store/actions/folders.action";


const VALID_MIME_TYPES = ["application/pdf", "image/jpeg", "image/png", "image/webp"];

const schema = z.object({
    files: z
        .array(z.instanceof(File, { message: "Debe ingresar archivos válidos." }))
        .nonempty("Debe ingresar al menos un archivo.")
        .refine(
            (files) => files.every((file) => VALID_MIME_TYPES.includes(file.type)),
            { message: "Todos los archivos deben ser PDF o imágenes (JPEG, PNG, WEBP)." }
        ),
    bucket_id: z.string({ required_error: "Debe ingresar el Bucket." }).min(1, "Debe ingresar un Bucket válido."),
    parent_folder_id: z.string({ required_error: "No se puede crear una carpeta sin una carpeta padre." }),
});


export type FileFormTypeSchema = z.infer<typeof schema>;

const useFileForm = () => {
    const dispatch = useAppDispatch()
    const { opened } = useAppSelector(state => state.fileExplorer)
    const { selected } = useAppSelector(state => state.buckets)
    const { control, handleSubmit, setValue } = useForm<FileFormTypeSchema>({
        resolver: zodResolver(schema),
        defaultValues: {

        }
    });

    useEffect(() => {
        if (selected) {
            setValue('bucket_id', selected.id)
        }
        if (opened) {
            setValue('parent_folder_id', opened.id)
        }
    }, [opened])

    const onSubmit: SubmitHandler<FileFormTypeSchema> = (data: FileFormTypeSchema) => {
        dispatch(uploadFiles(
            {
                files: data.files,
                parent_id: data.parent_folder_id,
                bucket_id: data.bucket_id,
            },
            selected,
            opened
        ));
    };

    return {
        control,
        handleSubmit: handleSubmit(onSubmit),
    }
}

export default useFileForm