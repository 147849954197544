interface AttachmentType {
    id: string,
    fileName: string,
    filePath: string
    fileSize: number
    fileType: string
}

export interface FileExplorerSchema {
    parent?: {
        id: string,
        name: string
    } | null
    folders: Array<{
        id: string,
        name: string,
        sequence: number
        created_at: string
    }>,
    attachments: AttachmentType[],
    loading?: boolean
    error?: string | null
    rootPath?: string
    opened?: {
        id: string,
        name: string
    } | null
}

const initialState: FileExplorerSchema = {
    parent: null,
    folders: [],
    attachments: [],
    loading: false,
    error: null,
    rootPath: '',
    opened: null
};

export default initialState