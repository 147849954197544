import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState, { FileExplorerSchema } from "../schemas/fileExplorer.schema";


export const FileExplorerSlice = createSlice({
    name: 'fileExplorer',
    initialState: initialState,
    reducers: {
        changeFolder: (state, action: PayloadAction<FileExplorerSchema>) => {
            state.parent = action.payload.parent || null
            state.folders = action.payload.folders
            state.attachments = action.payload.attachments
            state.loading = false
            state.error = null
        },
        resetFileExplorer: (state) => {
            state.parent = null
            state.folders = []
            state.attachments = []
            state.loading = false
            state.error = null
        },
        fileExplorerLoading: (state) => {
            state.loading = true
        },
        updateRootPath: (state, action: PayloadAction<string>) => {
            state.rootPath = action.payload
        },
        loadOpened: (state, action: PayloadAction<{ id: string, name: string } | undefined>) => {
            state.opened = action.payload || null
            state.loading = false
            state.error = null
        },
        updateOnlyFolder: (state, action: PayloadAction<Array<{
            id: string,
            name: string,
            sequence: number
            created_at: string
        }>>) => {
            state.folders = action.payload
            state.loading = false
        },
        errorInFileExplorer: (state, action: PayloadAction<string>) => {
            state.parent = null
            state.folders = []
            state.attachments = []
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { changeFolder, resetFileExplorer, fileExplorerLoading, errorInFileExplorer, updateRootPath, loadOpened, updateOnlyFolder } = FileExplorerSlice.actions
export default FileExplorerSlice.reducer