import { createSlice, PayloadAction, } from "@reduxjs/toolkit";
import initialState, { RecursosState } from "../schemas/recursos.schema";

export const recursosSlice = createSlice({
    name: 'recursos',
    initialState: initialState,
    reducers: {
        loadedRecursoState(state, action: PayloadAction<RecursosState>) {
            state.root = action.payload.root
            state.structure = action.payload.structure
            state.generalFiles = action.payload.generalFiles
            state.loading = false
            state.error = null
        },
        clearRecursoState(state) {
            state.root = null
            state.structure = []
            state.generalFiles = []
            state.loading = false
            state.error = null
        },
        loadRecursos(state) {
            state.loading = true
        },
        errorInLoad(state, action: PayloadAction<string>) {
            state.root = null
            state.structure = []
            state.generalFiles = []
            state.loading = false
            state.error = action.payload
        },
        clearError(state) {
            state.error = null
            state.loading = false
        }
    }
})

export const { loadedRecursoState, clearRecursoState, loadRecursos, errorInLoad, clearError } = recursosSlice.actions;

export default recursosSlice.reducer
