import { Container, Typography } from "@mui/material";
import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook";
import ModuleSelector from "../../components/organismos/recursos/ModuleSelector";
import ModuleLoader from "../../components/organismos/recursos/ModuleLoader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useRecursosLoader from "../../hooks/recursos/useRecursosLoader";
import InfoBar from "../../components/moleculas/recursos/InfoBar";
import Spinner from "../../components/atomos/Spinner";
import RecursosBreadcrumb from "../../components/moleculas/recursos/Breadcrumb";
import { updateRootPath } from "../../store/slices/fileExplorer.slice";
import { selectBusinessUnit } from "../../store/slices/businessUnit.slice";
import useProjects from "../../hooks/projects/useProjects";
import { clearError } from "../../store/slices/recursos.slice";

const Recurso: FC = () => {

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const businessUnit = searchParams.get('businessUnit')

    const dispatch = useAppDispatch()
    const businessUnitsState = useAppSelector((state) => state.businessUnit)
    const recursoState = useAppSelector((state) => state.recursos)

    const { recursoId } = useParams<{ recursoId: string, folderId: string }>()
    console.log(recursoId);

    const { getRecurso } = useRecursosLoader({
        recursoId: recursoId || '00',
        businessUnit
    })

    

    const { loadProject, bssUnits } = useProjects(recursoId!)

    useEffect(() => {
        if (bssUnits.length === 1) {
            navigate(`?businessUnit=${bssUnits[0].id}`)
        }
    }, [bssUnits])


    useEffect(() => {
        window.scrollTo({ top: 0 });

        loadProject({
            businessUnit: true
        })

        if (businessUnit) {
            const selected = businessUnitsState.businessUnits.find((bu: any) => bu.id === businessUnit)
            dispatch(selectBusinessUnit(selected!))
            getRecurso()
        } else {
            dispatch(clearError())
            dispatch(selectBusinessUnit())
        }

        dispatch(updateRootPath(`/dashboard/recursos/${recursoId}`))

    }, [businessUnit])

    return (
        <Container sx={{ padding: 0, margin: 0, flexGrow: 1 }} maxWidth={false} >
            <Spinner isLoad={recursoState.loading || false} />
            {
                !recursoState.loading && !recursoState.error && (
                    <>
                        <InfoBar>
                            Recursos
                        </InfoBar>
                        <RecursosBreadcrumb />
                        {
                            !businessUnit ? <ModuleSelector modules={bssUnits} /> : <ModuleLoader proyectoId={recursoId!} />
                        }
                    </>
                )
            }
            <Typography variant="h6" color="error">
                {recursoState.error}
            </Typography>
        </Container>
    )
}

export default Recurso