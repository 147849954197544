import { IconButton, IconButtonProps, styled } from '@mui/material';
import { FC } from 'react';
import ControlCameraOutlinedIcon from '@mui/icons-material/ControlCameraOutlined';

const Btn = styled(IconButton)(({ }) => ({
    padding: 0,
    touchAction: 'none'
}))

interface IDragBtn extends IconButtonProps { 
    listeners: any
}

const DragBtn: FC<IDragBtn> = ({ listeners, ...props }) => {
    return (
        <Btn sx={{ touchAction: 'none' }} aria-label='drag-handle' color='primary' title='drag-handle' {...props} {...listeners}>
            <ControlCameraOutlinedIcon />
        </Btn>
    )
}

export default DragBtn