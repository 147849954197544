import { AppDispatch } from "..";
import api from "../../services/axios";
import { errorInLoad, loadBusinessUnit, loadedBusinessUnitState } from "../slices/businessUnit.slice";


export const getBusinessUnits = () => async (dispatch: AppDispatch) => {
    dispatch(loadBusinessUnit())
    try {
        const response = await api.get('/business-unit');

        dispatch(loadedBusinessUnitState({
            businessUnits: response.data,
        }));
    } catch (error) {
        dispatch(errorInLoad((error as { message: string }).message || 'Error al cargar las unidades de negocio'))
    }
}