import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useAppDispatch } from '../../store/hook/store.hook';
import { login } from '../../store/actions/auth.actions';


const schema = z.object({
    email: z.string({
        required_error: "Debe ingresar el Email.",
    }).min(1, "Debe ingresar el Email.").email("Debe ser un correo valido"),
    password: z.string({
        required_error: "Debe ingresar una Contraseña.",
    }).min(1, "Debe ingresar una Contraseña.")
})

type FormData = z.infer<typeof schema>

const useLoginForm = () => {

    const dispatch = useAppDispatch();
    const { control, handleSubmit } = useForm<FormData>({
        resolver: zodResolver(schema),
        
    });

    const onSubmit = (data: FormData) => {
        // Lógica para el manejo del envío del formulario
        dispatch(login(data.email, data.password))
    };

    return {
        control,
        handleSubmit: handleSubmit(onSubmit),

    };
};

export default useLoginForm;
