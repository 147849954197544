import { Box, styled } from "@mui/material";
import { FC } from "react";
import ProjectInfo from "../../organismos/projects/ProjectInfo";
import { useAppSelector } from "../../../store/hook/store.hook";
import ProjectFormContainer from "../../organismos/projects/forms";
import { Control, UseFormGetValues } from "react-hook-form";
import HeaderProjectAction from "../../organismos/projects/header/HeaderProjectAction";
import ModalProjectSelector from "../../organismos/projects/Modals/ProjectSelectorModal";
import useModal from "../../../hooks/useModal";
import HeaderTemplateAction from "../../organismos/plantillas/header/HeaderTemplateAction";
import ViewerTemplate from "../../organismos/plantillas/viewerTemplate";
import PreviewProjectForm from "../../organismos/projects/Preview";
import { FormDataEditableType } from "../../../hooks/projects/useFormProject";

const WrapperContent = styled(Box)(({ }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: '30vh',
    flexDirection: 'column',
    gap: '1rem',
    alignItems: 'center'
}))

interface IProjectStepperContentProps {
    customSteps: Array<{
        label: string
        content: Array<any>
    }>
    activeStep: number
    controlProject: Control<any>
    getValues: UseFormGetValues<FormDataEditableType>
}

const ProjectStepperContent: FC<IProjectStepperContentProps> = ({ customSteps, activeStep, controlProject, getValues }) => {

    const { selected: projectSelected } = useAppSelector(state => state.projects)

    const { handleClose: handleCloseModal, handleOpen: handleOpen, open: openModal } = useModal()

    const tmpProject = projectSelected ? projectSelected : {
        name: getValues('name'),
        cliente: getValues('cliente'),
        status_project_id: getValues('status_project_id'),
        warranty: getValues('warranty'),
        description: getValues('description'),
        ubication: { direccion: getValues('ubication.direccion') || 'Vacío' },
        managements: getValues('managements') && getValues('managements').map((mn) => ({ code: mn.code, id: mn.id }))
    }

    return (
        <WrapperContent>
            {
                activeStep === 0 && (
                    <>
                        <HeaderProjectAction setOpen={handleOpen} />
                        {
                            projectSelected ? <ProjectInfo /> : <ProjectFormContainer control={controlProject} />
                        }
                        <ModalProjectSelector projects={customSteps[0].content} openModal={openModal} handleClose={handleCloseModal} />
                    </>
                )
            }
            {
                activeStep === 1 && (
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'flex-start', height: '100%' }}>
                            <HeaderTemplateAction />
                            <ViewerTemplate />
                        </Box>
                    </>
                )
            }
            {
                activeStep === 2 && (
                    <>
                        <PreviewProjectForm project={tmpProject} />
                    </>
                )
            }
        </WrapperContent>
    )
}

export default ProjectStepperContent
