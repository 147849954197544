import { Grid2, styled } from "@mui/material"
import React, { FC } from "react"
import ProjectForm from "../../../moleculas/projects/form"
import { Control } from "react-hook-form"
import { FormDataEditableType } from "../../../../hooks/projects/useFormProject"


const WrapResponsiveProjectForm = styled(Grid2)(({ }) => ({
    width: '80%',
    textAlign: 'center',
}))

interface IProjectFormContainerProps {
    control: Control<FormDataEditableType>
}

const ProjectFormContainer: FC<IProjectFormContainerProps> = ({ control }) => {

    return (
        <React.Fragment>
            <WrapResponsiveProjectForm container spacing={2} columns={2}>
                <ProjectForm control={control} />
            </WrapResponsiveProjectForm>
        </React.Fragment>
    )
}

export default ProjectFormContainer