import { styled } from '@mui/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Import styles
import '@react-pdf-viewer/toolbar/lib/styles/index.css';


// Configuración del worker de pdf.js
const Div = styled('div')(() => ({
    maxHeight: '90vh',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
}));


const PDFViewer = ({ file, toolbarPlugin: toolbarPluginInstance }: any) => {

    return (
        <Div>
            <Worker workerUrl="/pdf.worker.js" >
                <Viewer
                    fileUrl={URL.createObjectURL(file)}
                    plugins={[toolbarPluginInstance]}
                    pageLayout={{
                        buildPageStyles: () => ({
                            height: '100%', // Ajusta la altura al contenedor
                            display: 'flex',
                            justifyContent: 'center',
                        }),
                        transformSize: ({ size }) => ({
                            width: size.width, // Mantén la proporción original de la página
                            height: size.height, // Altura original de la página
                        }),
                    }}
                />
            </Worker>
        </Div>
    );
};

export default PDFViewer;
