import { useMemo } from 'react';

const useNavigationVisibility = (currentRole: string, allowedRoles: string[]) => {
    // Memoiza el resultado para optimizar el rendimiento y evitar cálculos innecesarios
    
    return useMemo(() => {
        return allowedRoles.includes(currentRole);
    }, [currentRole, allowedRoles]);
};

export default useNavigationVisibility;