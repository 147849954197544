import { GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import DataGridSSR from "../../moleculas/datagrids"
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import useAdminHistoryMonitoring from "../../../hooks/monitoring/useAdminHistoryMonitoring";
import { AdminHistory } from "../../../store/schemas/monitoring.schema";


dayjs.extend(utc);
dayjs.extend(timezone);

const AdminHistoryGrid = () => {

    const { pagination, allData, meta, loading, changePagination } = useAdminHistoryMonitoring()

    const columns: GridColDef<AdminHistory>[] = [
        {
            field: "Nombre",
            headerName: "Nombre",
            flex: 1,
            renderCell: (params: GridRenderCellParams<AdminHistory>) => params.row.user.name
        },
        {
            field: "Accion",
            headerName: "Acción",
            renderCell: (params: GridRenderCellParams<AdminHistory>) => params.row.action,
            flex: 1
        },
        {
            field: "Unidad de Negocio",
            headerName: "Unit de Negocio",
            renderCell: (params: GridRenderCellParams<AdminHistory>) => params.row.business_unit?.name || "No especificado.",
            flex: 1
        },
        {
            field: "Ruta",
            headerName: "Ruta",
            renderCell: (params: GridRenderCellParams<AdminHistory>) => params.row.routePath,
            flex: 1
        },
        {
            field: "Fecha",
            headerName: "Fecha Ingreso",
            renderCell: (params: GridRenderCellParams<AdminHistory>) => dayjs(params.row.access_date).format("DD/MM/YYYY HH:mm"),
            flex: 0.5
        }
    ]

    const handlePaginationModelChange = (model: GridPaginationModel) => {
        changePagination(model.page, model.pageSize)
    };

    return (
        <DataGridSSR rows={allData || []} columns={columns}
            rowCount={meta && meta.totalItems ? meta.totalItems : 0}
            paginationModel={{
                page: pagination!.page,
                pageSize: pagination!.limit
            }}
            onPaginationModelChange={handlePaginationModelChange}
            loading={loading}
        />
    )
}

export default AdminHistoryGrid