import { Grid2, Grid2Props, styled } from "@mui/material";
import { FC } from "react";


const GridContainer = styled(Grid2)(({ }) => ({
}))

const TargetsWrapper: FC<Grid2Props> = ({ children, ...props }) => {

    return (
        <GridContainer
            container
            spacing={3}
            columns={{ xs: 2, sm: 4, md: 8, lg: 12, xl: 12 }}
            {...props}
        >
            {children}
        </GridContainer>
    )
}

export default TargetsWrapper