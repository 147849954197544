import HeaderBox from "../../atomos/login/HeaderBox"
import TextAtom from "../../atomos/Text"
import { Controller } from "react-hook-form"
import InputMessage from "../../moleculas/InputMessage"
import ButtonAtom from "../../atomos/Button"
import useOtpForm from "../../../hooks/login/visiter/useOtpForm"
import { FC, useEffect, useState } from "react"
import Form from "../../atomos/Form"
import MessageError from "../../atomos/MessageError"
import { useAppSelector } from "../../../store/hook/store.hook"
import { styled } from "@mui/material"

const Wrapper = styled('div')(() => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}))

export const ValidateOtpComponent: FC<{ handleSubmitAuth: () => void, emailRegister: string }> = ({ handleSubmitAuth, emailRegister }) => {
    const [time, setTime] = useState(60) // Tiempo inicial en segundos
    const { error } = useAppSelector((state) => state.auth)
    const {
        controlOTP,
        handleSubmitOTP
    } = useOtpForm({email: emailRegister})


    useEffect(() => {
        // Si el tiempo es mayor a 0, iniciar el temporizador
        if (time > 0) {
            const timer = setInterval(() => {
                setTime((prevTime) => prevTime - 1) // Reducir el tiempo en 1
            }, 1000)

            return () => clearInterval(timer) // Limpiar el intervalo al desmontar
        }
    }, [time]) // Recalcular si cambia el tiempo


    const handleResendCode = () => {
        setTime(60) // Reiniciar el tiempo
        handleSubmitAuth() // Acción para reenviar el código
    }

    return (
        <>
            <HeaderBox>
                <TextAtom variant="h4" color="primary" fontFamily='TitilliumWeb'>
                    <b>Ingresa</b> tu código
                </TextAtom>
                <TextAtom variant="body2" color="textSecondary" sx={{ marginTop: '2%' }}>
                    Inserta el código de verificación enviado a tu correo
                </TextAtom>
            </HeaderBox>

            <Form onSubmit={handleSubmitOTP}>
                <Controller
                    name='codeOtp'
                    control={controlOTP}
                    render={({ field, fieldState }) => (
                        <InputMessage
                            label="Código OTP"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            field={field}
                            fieldState={fieldState}
                        />
                    )}
                />
                <MessageError>
                    {error && error}
                </MessageError>
                <Wrapper>
                    <a
                        onClick={time === 0 ? handleResendCode : undefined}
                        style={{
                            pointerEvents: time === 0 ? "auto" : "none",
                            color: time === 0 ? "#0065BB" : "gray",
                            cursor: time === 0 ? "pointer" : "default"
                        }}
                    >
                        Reenviar código
                    </a>
                    <TextAtom variant="body2" color="textSecondary">
                        {time > 0
                            ? `${time}s`
                            : `¿No recibiste el código?`}
                    </TextAtom>
                </Wrapper>
                <ButtonAtom type='submit' sx={{ mt: 5, alignSelf: 'flex-end' }}>
                    Ingresar
                </ButtonAtom>
            </Form>
        </>
    )
}

export default ValidateOtpComponent
