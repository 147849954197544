import React  from "react"
import { useAppSelector } from "../../../store/hook/store.hook"
import { Grid2, styled, Typography } from "@mui/material"
import Project from "../../moleculas/projects/project"

const WrapResponsiveProjectInfo = styled(Grid2)(({ }) => ({
    width: '100%',
    textAlign: 'center'
}))

const ProjectInfo = () => {

    const { selected } = useAppSelector(state => state.projects)

    if (!selected) {
        return (
            <React.Fragment>
                <Typography>
                    No se ha seleccionado ningun proyecto
                </Typography>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <WrapResponsiveProjectInfo container spacing={2} columns={2}>
                <Project project={selected} />
            </WrapResponsiveProjectInfo>
        </React.Fragment>
    )
}

export default ProjectInfo