import { getChargeBuckets } from "../../store/actions/buckets.action"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook"
import { selectBusinessUnit } from "../../store/slices/businessUnit.slice"


export const useLoaderBusinessUnit = () => {

    const dispatch = useAppDispatch()
    const { buckets, meta, paginated } = useAppSelector(state => state.buckets)
    const businessUnitsState = useAppSelector((state) => state.businessUnit)

    const loadBuckets = (businessUnit: string) => {
        dispatch(getChargeBuckets(
            {
                businessUnit,
                pagination: {
                    page: paginated!.page + 1,
                    limit: paginated!.limit
                }
            }
        ))
        const selected = businessUnitsState.businessUnits.find((bu) => bu.id === businessUnit)
        dispatch(selectBusinessUnit(selected!))
    }


    return {
        loadBuckets,
        buckets,
        paginated,
        meta
    }
}