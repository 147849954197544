import { FC } from "react";
import FileExplorer from "../../file-explorer";


interface IModuleLoaderProps {
    proyectoId: string
}

const ModuleLoader: FC<IModuleLoaderProps> = ({ proyectoId }) => {

    return (
        <>
            <FileExplorer proyectoId={proyectoId}/>
        </>
    )
}

export default ModuleLoader