import { createSlice, PayloadAction, } from "@reduxjs/toolkit";
import { BusinessUnitSchema, BusinessUnitState, initialState } from "../schemas/businessUnit.schema";


export const businessUnitSlice = createSlice({
    name: 'businessUnit',
    initialState: initialState,
    reducers: {
        loadedBusinessUnitState(state, action: PayloadAction<BusinessUnitState>) {
            state.businessUnits = action.payload.businessUnits
            state.loading = false
            state.error = null
        },
        selectBusinessUnit(state, action: PayloadAction<BusinessUnitSchema | undefined>) {
            state.selected = action?.payload || null
        },
        clearBusinessUnitState(state) {
            state.businessUnits = []
            state.loading = false
            state.error = null
        },
        loadBusinessUnit(state) {
            state.loading = true
        },
        errorInLoad(state, action: PayloadAction<string>) {
            state.businessUnits = []
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { loadBusinessUnit, loadedBusinessUnitState, clearBusinessUnitState, errorInLoad, selectBusinessUnit } = businessUnitSlice.actions;

export default businessUnitSlice.reducer
