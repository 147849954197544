import { Breadcrumbs, Link, styled } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../../store/hook/store.hook"

const BrCr = styled(Breadcrumbs)(({ }) => ({
    marginBottom: '2rem'
}))

const Lk = styled(Link)(({ }) => ({
    cursor: 'pointer'
}))


const BucketBreadcrumb = () => {

    const navigate = useNavigate()
    const { selected } = useAppSelector(state => state.businessUnit)
    const { selected: bucketSelected } = useAppSelector(state => state.buckets)
    const fileExplorerState = useAppSelector((state) => state.fileExplorer)

    const navigateToBusinessUnit = () => {
        navigate('/dashboard/businessUnit', {
            state: {
                businessUnit: {
                    ...selected
                }
            }
        })
    }

    const navigateInit = () => {
        navigate('/dashboard/businessUnit/' + bucketSelected?.id)
    }

    
    const handleClickBefore = () => {
        return navigate(`/dashboard/businessUnit/${bucketSelected?.id}/folder/${fileExplorerState.parent?.id}`)
    }

    return (
        <BrCr aria-label="breadcrumb">
            <Lk underline="hover" color="primary" onClick={navigateToBusinessUnit} >
                {selected?.name}
            </Lk>
            <Lk underline="hover" color="primary" onClick={navigateInit} >
                Inicio
            </Lk>

            
            {
                fileExplorerState.parent && (
                    <Lk underline="hover" onClick={handleClickBefore}>
                        {fileExplorerState.parent.name}
                    </Lk>
                )
            }
            {
                fileExplorerState.opened && (
                    <Lk color="secondary" sx={{ cursor: 'text', textDecoration: 'none' }}>
                        {fileExplorerState.opened.name}
                    </Lk>
                )
            }
        </BrCr>
    )
}

export default BucketBreadcrumb