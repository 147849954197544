import { FC, useState } from "react"
import WrapperFilterContainer from "../../atomos/WrapperFilterContainer"
import { MenuItem, styled, TextField as TextFieldMui } from "@mui/material"
import BasicRangeShortcuts from "../DataRangePicker"
import { DateRange } from "@mui/x-date-pickers-pro/models"
import { Dayjs } from "dayjs"

const TextField = styled(TextFieldMui)(({ }) => ({
    width: '40%',
}))

interface IBucketFilterControllerProps {
    search: string
    setSearch: (value: string) => void
    project_state: string
    setProjectState: (value: string) => void
    setFrom: (value: string) => void
    setTo: (value: string) => void
}

const BucketFilterController: FC<IBucketFilterControllerProps> = ({ search, setSearch, project_state, setProjectState, setFrom, setTo }) => {
    const [dateRange, setDateRange] = useState<DateRange<Dayjs> | undefined>([null, null]);



    const handleDateRange = (value: DateRange<Dayjs> | undefined) => {
        if (value) {
            setDateRange(value)
            setFrom(value[0]?.format('YYYY-MM-DD') || '')
            setTo(value[1]?.format('YYYY-MM-DD') || '')
        }
    }

    return (
        <WrapperFilterContainer>
            <TextField value={search} onChange={(e) => setSearch(e.target.value)} type="search" label="Buscar" />
            <TextField
                select
                label="Estado Proyecto"
                value={project_state}
                onChange={(e) => setProjectState(e.target.value)}
                sx={{ width: '20%' }}
            >
                <MenuItem>
                    Todos
                </MenuItem>
                <MenuItem value="En proceso">
                    En proceso
                </MenuItem>
                <MenuItem value="En Curso">
                    En Curso
                </MenuItem>
                <MenuItem value="Finalizado">
                    Finalizado
                </MenuItem>
            </TextField>
            <BasicRangeShortcuts dateRange={dateRange} onChange={handleDateRange} />
        </WrapperFilterContainer>
    )
}

export default BucketFilterController