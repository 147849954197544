import { FC, useRef } from 'react';
import QRCode from 'react-qr-code';
import { Button, Box } from '@mui/material';

interface QRViewProps {
    tmpURI: string
    tmpName?: string
    handleCloseModal: () => void
}

const QRView: FC<QRViewProps> = ({ tmpURI, tmpName, handleCloseModal }) => {
    const qrRef = useRef<HTMLDivElement>(null);

    const downloadQRCode = () => {
        if (!qrRef.current) {
            console.error("qrRef.current is undefined");
            return;
        }

        const svg = qrRef.current.querySelector('svg');
        if (!svg) {
            console.error("SVG element not found in qrRef");
            return;
        }

        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const img = new Image();
        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);

        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx?.drawImage(img, 0, 0);
            URL.revokeObjectURL(url);

            const imgURL = canvas.toDataURL('image/png');
            const a = document.createElement('a');
            a.href = imgURL;
            a.download = tmpName ? `${tmpName}.png` : 'qr-code.png';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        };

        img.src = url;
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{ padding: 4 }}
        >
            {/* QR Code */}
            <Box ref={qrRef} sx={{ marginBottom: 2 }}>
                <QRCode value={tmpURI} size={256} />
            </Box>
            
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCloseModal}>Cerrar</Button>
                {/* Download Button */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={downloadQRCode}
                >
                    Descargar QR
                </Button>
            </Box>

        </Box>
    );
};

export default QRView;
