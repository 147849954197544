import { createSvgIcon } from "@mui/material";

export const EnergyIcon = createSvgIcon(
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 55.3807C0.3 53.1507 0.529994 50.9107 0.899994 48.7007C2.78999 37.4507 7.58 27.5807 15.06 19.0007C15.32 18.7107 15.58 18.4207 15.83 18.1207C15.87 18.0707 15.85 17.9807 15.88 17.7907C13.19 17.7907 10.53 17.8007 7.86002 17.7907C7.25002 17.7907 6.61002 17.7507 6.02002 17.5807C4.46002 17.1207 3.44002 15.5807 3.58002 14.0207C3.73002 12.2907 5.00001 10.9607 6.70001 10.7707C7.05001 10.7307 7.4 10.7207 7.75 10.7207C13.16 10.7207 18.56 10.7207 23.97 10.7207C26.86 10.7207 28.21 12.0607 28.21 14.9207C28.21 20.4107 28.23 25.8907 28.21 31.3807C28.19 34.4807 25.38 36.2907 22.89 34.8507C21.57 34.0907 21.09 32.8707 21.09 31.4107C21.09 29.0807 21.09 26.7407 21.09 24.4107C21.09 24.0407 21.05 23.6707 20.74 23.1707C19.25 25.1307 17.66 27.0307 16.29 29.0707C11.29 36.4907 8.26001 44.6607 7.48001 53.6007C5.93001 71.4307 11.66 86.5307 24.77 98.6907C32.68 106.021 42.12 110.341 52.8 111.951C57.01 112.581 61.24 112.611 65.48 112.331C67.69 112.181 69.31 113.371 69.57 115.261C69.86 117.391 68.47 119.191 66.25 119.371C49.45 120.711 34.47 116.141 21.54 105.301C11.56 96.9307 4.94 86.3507 1.84 73.6707C1.04 70.4007 0.739991 67.0107 0.209991 63.6707C0.159991 63.3307 0.07 62.9907 0 62.6507C0 60.2407 0 57.8207 0 55.4107V55.3807Z" fill="#8F8F8F" />
        <path d="M62.56 0.0300293C64.71 0.320029 66.87 0.550034 69.01 0.910034C79.31 2.62003 88.58 6.72003 96.68 13.28C109.23 23.44 116.87 36.53 118.93 52.56C121.27 70.7 116.33 86.8 104.38 100.7C104.11 101.01 103.85 101.33 103.42 101.83C106.27 101.83 108.89 101.83 111.52 101.83C112.1 101.83 112.7 101.83 113.25 101.97C114.93 102.38 116.06 103.95 115.93 105.59C115.79 107.36 114.48 108.69 112.7 108.87C112.39 108.9 112.08 108.91 111.77 108.91C106.32 108.91 100.88 108.92 95.43 108.91C92.64 108.91 91.3 107.54 91.3 104.73C91.3 99.2 91.3 93.68 91.3 88.15C91.3 86.68 91.84 85.49 93.16 84.75C94.35 84.09 95.55 84.13 96.7 84.83C98 85.62 98.42 86.87 98.42 88.32C98.42 90.62 98.42 92.91 98.42 95.21C98.42 95.58 98.46 95.95 98.49 96.54C98.92 96.09 99.21 95.8 99.47 95.5C106.02 87.84 110.13 79.03 111.69 69.07C115.51 44.61 102.54 21.15 79.81 11.37C71.62 7.85 63.04 6.62005 54.16 7.30005C51.64 7.49005 49.96 6.11999 49.9 3.92999C49.85 1.92999 51.24 0.45 53.34 0.25C54.07 0.18 54.8 0.09 55.53 0C57.86 0 60.2 0 62.53 0L62.56 0.0300293Z" fill="#8F8F8F" />
        <path d="M67.4899 49.7099C69.2599 49.7099 70.8799 49.7099 72.4999 49.7099C74.1699 49.7099 75.8499 49.6598 77.5199 49.7598C79.9199 49.8898 81.3699 52.1698 80.5799 54.4398C80.4699 54.7698 80.3199 55.0899 80.1599 55.3999C73.1199 69.2099 66.0699 83.0199 59.0299 96.8399C58.4199 98.0299 57.6599 99.0199 56.2599 99.2799C54.0099 99.6999 52.1099 98.1498 52.0499 95.7598C51.9899 93.1898 52.0299 90.6199 52.0299 88.0499C52.0299 82.4799 52.0299 76.9199 52.0299 71.3499C52.0299 70.9299 52.0299 70.5099 52.0299 69.9099C51.5199 69.9099 51.1099 69.9099 50.6999 69.9099C47.9799 69.9099 45.2499 69.9399 42.5299 69.8999C39.3999 69.8499 37.8099 67.2898 39.2299 64.4998C46.3399 50.5098 53.4899 36.5299 60.6399 22.5499C61.5699 20.7299 63.1399 19.9698 64.8399 20.3798C66.5199 20.7898 67.4899 22.1899 67.4899 24.2899C67.4899 32.2299 67.4899 40.1699 67.4899 48.1099C67.4899 48.5699 67.4899 49.0399 67.4899 49.7199V49.7099ZM71.4499 56.8698C71.2499 56.8298 71.2099 56.8098 71.1699 56.8098C68.8399 56.8098 66.4999 56.8099 64.1699 56.7799C61.8799 56.7499 60.4599 55.3398 60.4399 53.0598C60.4099 50.1398 60.4399 47.2198 60.4399 44.3098C60.4399 42.5798 60.4399 40.8399 60.4399 39.1099C60.3599 39.0899 60.2699 39.0599 60.1899 39.0399C56.1599 46.9299 52.1399 54.8099 48.0299 62.8599C50.4999 62.8599 52.7799 62.8599 55.0599 62.8599C57.7199 62.8599 59.0799 64.2198 59.0899 66.8798C59.0999 71.1198 59.0899 75.3599 59.0899 79.5999C59.0899 79.9199 59.0899 80.2398 59.0899 80.5598C59.1799 80.5698 59.2599 80.5899 59.3499 80.5999C63.3699 72.7299 67.3899 64.8598 71.4599 56.8798L71.4499 56.8698Z" fill="#8F8F8F" />
    </svg>
    ,
    'EnergyIcon'
)