import { Box, styled } from "@mui/material";
import { FC } from "react";


const WrapperStyled = styled(Box)(({ }) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
}))


const WrapperBox: FC<{ position?: string, children: React.ReactNode }> = ({ children, position }) => {

    return (
        <WrapperStyled sx={{ alignItems: {
            lg: position || 'center',
            xl: position || 'center',
            md: position || 'center'
        }}}>
            {children}
        </WrapperStyled>
    )
}


export default WrapperBox