import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";


const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5),
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    [theme.breakpoints.down('md')]: {
        height: '100vh',
    }
}));

export default Item