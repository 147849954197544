import { Container, CssBaseline } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAppSelector } from "../store/hook/store.hook";
import Spinner from "../components/atomos/Spinner";
import Footer from "../components/moleculas/Footer";
import useGeolocation from "../hooks/useGeolocation ";


const App: FC = () => {

  const { getLocation } = useGeolocation()

  const { loading, validateOtp, registerOtp, accessToken } = useAppSelector((state) => state.auth)

  useEffect(() => {
    getLocation()
  }, [])

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth={false} // Para que el Container se expanda en todo el ancho
        disableGutters
        sx={{
          minHeight: '100vh', // Permite que el contenedor crezca si es necesario
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column', // Adapta el contenido a medida que crece
          justifyContent: 'space-between', // Alinea el contenido al principio de la página
          alignItems: 'stretch', // Hace que el contenido interno se estire
          backgroundColor: !accessToken ? 'rgb(200,200,200, .2)' : 'white',
          paddingLeft: {
            xs: 0,
          },
          padding: accessToken ? {
            xl: '0px 5% 0px 5%;',
            lg: '0px 5% 0px 5%;',
            md: '0px 2% 0px 2%;'
          } : {
            xl: '0px 10% 0px 10%;',
            lg: '0px 5% 0px 5%;',
            md: '0px 2% 0px 2%;'
          },
        }}
      >
        <Spinner isLoad={loading && !registerOtp && !validateOtp} />
        <Outlet />
        <Footer />
      </Container>
    </React.Fragment >
  );
};

export default App;
