import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState, { AdminMonitoringSchema, VisitedMonitoringSchema } from "../../schemas/monitoring.schema";


export const monitoringSlice = createSlice({
    name: 'monitoring',
    initialState: initialState,
    reducers: {
        visitedAllData(state, action: PayloadAction<VisitedMonitoringSchema>) {
            state.visited.allData = action.payload.allData
            state.visited.dataSelected = null
            state.visited.pagination = action.payload.pagination
            state.visited.meta = action.payload.meta
            state.loading = false
            state.error = null
        },
        visitedChangePagination(state, action: PayloadAction<{ page: number, limit: number }>) {
            state.visited.pagination.page = action.payload.page
            state.visited.pagination.limit = action.payload.limit
            state.loading = true
        },
        historyAdminAllData(state, action: PayloadAction<AdminMonitoringSchema>) {
            state.admin.allData = action.payload.allData
            state.admin.dataSelected = null
            state.admin.pagination = action.payload.pagination
            state.admin.meta = action.payload.meta
            state.loading = false
            state.error = null
        },
        historyAdminChangePagination(state, action: PayloadAction<{ page: number, limit: number }>) {
            state.admin.pagination.page = action.payload.page
            state.admin.pagination.limit = action.payload.limit
            state.loading = true
        },
        monitoringError(state, action: PayloadAction<string>) {
            state.error = action.payload
        }
    }
})

export const { visitedAllData, monitoringError, visitedChangePagination, historyAdminAllData, historyAdminChangePagination } = monitoringSlice.actions

export default monitoringSlice.reducer