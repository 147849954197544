import { createTheme } from "@mui/material";
import TitilliumWebTtf from '../assets/fonts/01.Titilium Web/TitilliumWeb-Regular.ttf'
import BoldTitilliumWebTtf from '../assets/fonts/01.Titilium Web/TitilliumWeb-Bold.ttf'
import ConthraxOtf from '../assets/fonts/02.Conthrax/Conthrax Regular.otf'
import BoldConthraxOtf from '../assets/fonts/02.Conthrax/Conthrax Bold.otf'

import { esES } from '@mui/x-date-pickers/locales';

let theme = createTheme({
    palette: {
        primary: {
            main: '#0065BB'
        },
        secondary: {
            main: '#8F8F8F'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
            @font-face {
              font-family: 'Conthrax';
              font-style: normal;
              src: local('Conthrax'), url(${ConthraxOtf}) format('opentype');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            @font-face {
              font-family: 'BoldConthrax';
              font-style: normal;
              src: local('Conthrax'), url(${BoldConthraxOtf}) format('opentype');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            @font-face {
              font-family: 'TitilliumWeb';
              font-style: normal;
              src: local('TitilliumWeb'), url(${TitilliumWebTtf}) format('truetype');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
            @font-face {
              font-family: 'BoldTitilliumWeb';
              font-style: normal;
              src: local('BoldTitilliumWeb'), url(${BoldTitilliumWebTtf}) format('truetype');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `,
        },
    },
    breakpoints: {
        values: {
            xs: 0,        // Teléfonos
            sm: 600,      // Tablets
            md: 960,      // Pantallas medianas
            lg: 1280,     // Pantallas grandes
            xl: 1920,     // Pantallas extra grandes
        },
    },
    typography: {
        // h1: {
        //     fontFamily: 'Conthrax, sans-serif', // Aplica Conthrax solo a los h1
        //     textAlign: 'left',
        // },
        // h2: {
        //     fontFamily: 'Conthrax, sans-serif', // Aplica Conthrax solo a los h1
        //     textAlign: 'left'
        // },
        // h3: {
        //     fontFamily: 'Conthrax, sans-serif', // Aplica Conthrax solo a los h1
        //     textAlign: 'left'
        // },
        // h4: {
        //     fontFamily: 'Conthrax, sans-serif', // Aplica Conthrax solo a los h1
        //     textAlign: 'left',
        // },
        h1: {
            fontFamily: 'TitilliumWeb, sans-serif', // Aplica Conthrax solo a los h1
            textAlign: 'left',
        },
        h2: {
            fontFamily: 'TitilliumWeb, sans-serif', // Aplica Conthrax solo a los h1
            textAlign: 'left'
        },
        h3: {
            fontFamily: 'TitilliumWeb, sans-serif', // Aplica Conthrax solo a los h1
            textAlign: 'left'
        },
        h4: {
            fontFamily: 'TitilliumWeb, sans-serif', // Aplica Conthrax solo a los h1
            textAlign: 'left',
        },
        /** */
        body1: {
            fontFamily: 'TitilliumWeb, sans-serif', // Aplica TitilliumWeb solo a body1
            textAlign: 'left'
        },
        body2: {
            fontFamily: 'TitilliumWeb, sans-serif', // Aplica TitilliumWeb solo a body2 si lo necesitas
            textAlign: 'left'
        },
        fontWeightBold: 'BoldTitilliumWeb',
        button: {
            fontFamily: 'TitilliumWeb, sans-serif',
            textTransform: 'none',
        },
    },

}, esES)


export default theme