import api from "../axios";

const getInfiniteProjects = async (params: URLSearchParams) => {
    
    const url = `/projects?${params.toString()}`
    try {
        const response = await api.get(url);

        return response.data.items
    } catch (error) {
        console.log(error);
    }
}

export {
    getInfiniteProjects
}