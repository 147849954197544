import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import initialState, { Template, TemplateSstate } from "../schemas/template.schema";


export const temmplateSlice = createSlice({
    name: 'templates',
    initialState: initialState,
    reducers: {
        loadTemplates: (state, action: PayloadAction<TemplateSstate>) => {
            state.templates = action.payload.templates
            state.meta = action.payload.meta
            state.loading = false
            state.error = null
        },
        errorInLoadTemplate: (state, action: PayloadAction<string>) => {
            state.templates = []
            state.loading = false
            state.meta = null
            state.error = action.payload
        },
        changePaginated: (state, action: PayloadAction<{ page: number, limit: number }>) => {
            state.paginated = action.payload
            state.loading = false
        },
        selectedTemplate: (state, action: PayloadAction<Template>) => {
            state.selected = action.payload
            state.loading = false
        },
        resetTemplates: (state) => {
            state.templates = []
            state.meta = null
            state.paginated = {
                page: 0,
                limit: 5
            }
            state.loading = false  
            state.selected = null
        },
        processingApiBuckets: (state) => {
            state.loading = true
            state.error = null
            state.selected = null
        }
    }
})

export const { loadTemplates, errorInLoadTemplate, changePaginated, selectedTemplate, processingApiBuckets, resetTemplates } = temmplateSlice.actions
export default temmplateSlice.reducer