import { styled } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import openedMixin from "../styles/openmixin";
import closedMixin from "../styles/closemixin";
import { drawerWidth } from "../styles/constantes";

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        variants: [
            {
                props: ({ open }) => open,
                style: {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                },
            },
            {
                props: ({ open }) => !open,
                style: {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                },
            },
        ],
        '& .MuiDrawer-paper': {
            backgroundColor: theme.palette.primary.main,
        },
    }),
);

export default Drawer