import { combineReducers, configureStore, createAction } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage'; // usa localStorage por defecto
import authReducer from "./slices/auth.slice"; // importa el reducer de tu slice
import profileReducer from "./slices/profile.slice";
import recursosReducer from "./slices/recursos.slice";
import businessUnitReducer from "./slices/businessUnit.slice";
import fileExplorerReducer from "./slices/fileExplorer.slice";
import projectsReducer from "./slices/project.slice";
import bucketsReducer from "./slices/buckets.slice";
import templatesReducer from "./slices/templates.slice";
import monitoringReducer from "./slices/monitoring/monitoring.slice"


export const resetSlices = createAction('reset/slices');

const appReducer = combineReducers({
    auth: authReducer,
    profile: profileReducer,
    recursos: recursosReducer,
    businessUnit: businessUnitReducer,
    fileExplorer: fileExplorerReducer,
    projects: projectsReducer,
    buckets: bucketsReducer,
    templates: templatesReducer,
    monitoring: monitoringReducer
});

// Root reducer para manejar la acción de reinicio
const rootReducer = (state: any | undefined, action: any) => {
    if (action.type === resetSlices.type) {
        // Reinicia únicamente los slices seleccionados (auth y profile en este caso)
        state = {
            auth: undefined,
            profile: undefined,
            recursosReducer: undefined,
            businessUnitReducer: undefined,
            fileExplorer: undefined,
            projects: undefined,
            buckets: undefined,
            templates: undefined,
            monitoring: undefined
        };
    }
    return appReducer(state, action);
};


const persistConfig = {
    key: 'root', // clave para la persistencia
    storage, // el almacenamiento que usarás (localStorage en este caso)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false, // Desactiva la verificación de valores no serializables
        }),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppStore = typeof store