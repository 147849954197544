// src/axios.ts
import axios from 'axios';
import { store } from '../store';
import { loginSuccess, logout } from '../store/slices/auth.slice';

// Crear una instancia de axios
const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL, // Base URL de tu API
});

// Añadir un interceptor para agregar el accessToken a las solicitudes
api.interceptors.request.use((config) => {
  const state = store.getState();
  const accessToken = state.auth.accessToken;
  const ubication = state.profile.location

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (ubication) {
    config.headers["x-latitud"] = ubication.latitud
    config.headers["x-longitud"] = ubication.longitud
  }

  return config;
});

// Interceptor para manejar respuestas
api.interceptors.response.use(
  (response) => response, // Devolver la respuesta si es correcta
  async (error) => {
    const originalRequest = error.config;

    // Si el error es un 401 (no autorizado) y no estamos intentando refrescar el token
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const state = store.getState();
      const refreshToken = state.auth.refreshToken;

      if (!refreshToken) {
        store.dispatch(logout());
        return Promise.reject(error);
      }

      try {
        const { data } = await axios.get(`${import.meta.env.VITE_API_URL}/auth/refresh-token`, {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
          },
        });

        const { access_token } = data;

        // Actualizar los tokens en el store y localStorage
        store.dispatch(loginSuccess({ accessToken: access_token }));

        // Modificar la solicitud original con el nuevo accessToken y reenviarla
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return api(originalRequest);
      } catch (refreshError) {
        // Si el refresh token falla, desloguear al usuario
        store.dispatch(logout());
        return Promise.reject(refreshError);
      }
    }

    // Manejo de otros errores
    return Promise.reject(error);
  }
);

export default api;
