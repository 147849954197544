import { Autocomplete, styled, TextField } from "@mui/material";
import { FC, FocusEvent } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hook/store.hook";
import { getAllTemplates, getOneTemplate } from "../../../../store/actions/templates.action";
import { Template } from "../../../../store/schemas/template.schema";
import { resetTemplates } from "../../../../store/slices/templates.slice";

const Wrap = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: theme.spacing(0, 1),
    gap: '1rem'
}))

const CustomAutoComplete = styled(Autocomplete)(({ }) => ({
    width: '50%',
}))

interface IHeaderProjectActionProps {
}

const HeaderTemplateAction: FC<IHeaderProjectActionProps> = ({ }) => {

    const dispatch = useAppDispatch()
    const { templates, selected } = useAppSelector(state => state.templates)

    const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
        const { value } = event.target; // Obtener el valor del input

        if (value) {
            dispatch(getAllTemplates({ search: value }))
        } else {
            dispatch(getAllTemplates())
        }
    }

    return (
        <Wrap>
            <CustomAutoComplete
                disablePortal
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                getOptionLabel={(option: any) => option.name}
                options={templates}
                value={selected}
                filterOptions={() => {
                    return templates
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Seleccione una plantilla"
                        onChange={handleOnBlur}
                    />
                )}
                onChange={(...props) => {
                    if (props) {
                        if (props[1]) {
                            const { id } = props[1] as Template;
                            if (id) {
                                dispatch(getOneTemplate(id))
                            } else {
                                dispatch(getAllTemplates())
                            }
                        } else {
                            dispatch(getAllTemplates())
                            dispatch(resetTemplates())
                        }
                    }
                }}
            />
        </Wrap>
    )
}

export default HeaderTemplateAction