import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import ResourceExplorer from "../../components/file-explorer/Resource";
import InfoBar from "../../components/moleculas/recursos/InfoBar";
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook";
import Spinner from "../../components/atomos/Spinner";
import { Typography } from "@mui/material";
import useReloadFileExplorerWithout from "../../hooks/fileExplorer/useReloadFileExplorerWithout";
import { setInfoFolder } from "../../store/actions/recursos.actions";
import { resetFileExplorer, updateRootPath } from "../../store/slices/fileExplorer.slice";
import CustomRecursosBreadcrumb from "../../components/moleculas/recursos/BreadcrumbCustom";

const FolderWithoutProject = () => {

    const location = useLocation()
    const { recursoId, folderId } = useParams<{ recursoId: string, folderId: string }>()
    const [searchParams] = useSearchParams();
    const init = searchParams.get('init')
    const { set } = useReloadFileExplorerWithout({ folderId: folderId! })
    const fileExplorerState = useAppSelector((state) => state.fileExplorer)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (fileExplorerState.rootPath && fileExplorerState.rootPath.length > 0) {
            return
        }
        dispatch(updateRootPath(location.state.from))
    }, [])

    useEffect(() => {
        dispatch(setInfoFolder(folderId!))
        set(init)
        return () => {
            dispatch(resetFileExplorer())
        }
    }, [folderId, recursoId])


    return (
        <>
            <Spinner isLoad={fileExplorerState.loading || false} />
            {
                !fileExplorerState.loading && !fileExplorerState.error && (
                    <>
                        <InfoBar>
                            Recursos
                        </InfoBar>
                        <CustomRecursosBreadcrumb />
                        <ResourceExplorer proyectoId={recursoId!} />
                    </>
                )
            }
            <Typography variant="h6" color="error">
                {fileExplorerState.error}
            </Typography>
        </>
    );
}

export default FolderWithoutProject;
