import { Divider, styled, Typography } from "@mui/material"
import { useAppDispatch } from "../../../store/hook/store.hook"
import { Project as ProjectType } from "../../../store/schemas/project.schema"
import Project from "../../moleculas/projects/project"
import ViewerTemplate from "../plantillas/viewerTemplate"
import { FC, useEffect } from "react"
import { loadSelectedProject } from "../../../store/slices/project.slice"

const PreviewWrap = styled('div')(({ }) => ({
    width: '100%',
}))

interface IPreviewProjectFormProps {
    project: ProjectType
}

const PreviewProjectForm: FC<IPreviewProjectFormProps> = ({ project }) => {
    const dispatch = useAppDispatch()
    let tmpProject: ProjectType = project

    useEffect(() => {
        dispatch(loadSelectedProject(tmpProject))
    }, [])

    return (
        <PreviewWrap >
            <Typography variant="h5">
                Información del proyecto
            </Typography>
            <Project project={tmpProject} />
            <Divider />
            <Typography variant="h5">
                Información de la plantilla:
            </Typography>
            <ViewerTemplate />
        </PreviewWrap>
    )
}

export default PreviewProjectForm