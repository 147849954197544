import { Box, styled } from "@mui/material";


const HeaderBox = styled(Box)(({ theme }) => ({
    display: 'flex', 
    alignItems: 'center', 
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
        width: '90%',
    }
}))

export default HeaderBox