interface AuthState {
    accessToken: string | null;
    refreshToken: string | null;
    loading: boolean;
    error: string | null;
    message: string | null;
    type: AuthType | null;
    futurePath: string | null;
    registerOtp: boolean
    validateOtp: boolean
}

enum AuthType {
    ADMIN = 'admin',
    VISITER = 'visiter'
}

const initialState: AuthState = {
    accessToken: localStorage.getItem('accessToken'), // Recuperar tokens del localStorage
    refreshToken: localStorage.getItem('refreshToken'),
    loading: false,
    error: null,
    message: null,
    type: null,
    futurePath: null,
    registerOtp: false,
    validateOtp: false
};

export {initialState, AuthType}