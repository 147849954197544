import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, IconButtonProps, styled } from '@mui/material';
import { FC } from 'react';

const Btn = styled(IconButton)(({ }) => ({
    padding: 0
}))

const SubMenu: FC<IconButtonProps> = ({ ...props }) => {
    return (
        <Btn aria-label='SubMenu' color='primary' title='Info' {...props} >
            <MoreVertIcon />
        </Btn>
    )
}

export default SubMenu