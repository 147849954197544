import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { RootState } from '../store'; // Ajusta según la estructura de tu store
import { useAppDispatch } from '../store/hook/store.hook';
import { changeAuthType } from '../store/slices/auth.slice';
import { AuthType } from '../store/schemas/auth.schema';

const PrivateRoute = () => {
  const { accessToken } = useSelector((state: RootState) => state.auth);
  const { rol } = useSelector((state: RootState) => state.profile);
  const location = useLocation();
  const dispatch = useAppDispatch();

  // Expresiones regulares ajustadas para rutas con o sin '/' al final
  const visiterPaths = [
    /^\/dashboard\/recursos\/([0-9a-fA-F\-]{36})(\/.*)?$/,
    /^\/proyectos\/([0-9a-fA-F\-]{36})(\/.*)?$/,
    /^\/uom\/([0-9a-fA-F\-]{36})(\/.*)?$/,
    /^\/dashboard\/recursos\/(undefined|[0-9a-fA-F\-]+)(\/folders\/[0-9a-fA-F\-]+)?(\?.*)?$/,
  ];

  // Revisión de coincidencia de ruta
  const match = visiterPaths.some((pattern) => pattern.test(location.pathname));

  if (match) {
    console.log(location.pathname.split('/')[4]);
    if (location.pathname.split('/')[4] === "folders") {
      dispatch(
        changeAuthType({
          type: AuthType.VISITER,
          futurePath: `/dashboard/recursos/${location.pathname.split('/')[3]}/folders/${location.pathname.split('/')[5]}?disabled=true`
        })
      );
    } else if (location.pathname.split('/')[1] !== 'dashboard') {
      if (location.pathname.split('/')[1] === 'uom') {
        dispatch(
          changeAuthType({
            type: AuthType.VISITER,
            futurePath: `/dashboard/recursos/undefined/folders/${location.pathname.split('/')[2]}?disabled=true`
          })
        );
      } else {
        dispatch(
          changeAuthType({
            type: AuthType.VISITER,
            futurePath: `/dashboard/recursos/${location.pathname.split('/')[2]}`
          })
        );
      }
    } else {
      dispatch(
        changeAuthType({
          type: AuthType.VISITER,
          futurePath: `/dashboard/recursos/${location.pathname.split('/')[3]}`
        })
      );
    }
  } else {
    if (rol === 'viewer') {
      dispatch(changeAuthType({ type: AuthType.VISITER }));
    } else {
      dispatch(changeAuthType({ type: AuthType.ADMIN }));
    }
  }

  // Si no está autenticado, redirige al login
  if (!accessToken) {
    return <Navigate to="/login" replace />;
  }

  // Si está autenticado, renderiza las rutas hijas
  return <Outlet />;
};

export default PrivateRoute;
