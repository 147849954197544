import { AppDispatch } from "..";
import api from "../../services/axios";
import { historyAdminAllData, monitoringError, visitedAllData } from "../slices/monitoring/monitoring.slice";

interface IVisitedAllDataProps {
    pagination: {
        page: number;
        limit: number;
    }
    params?: URLSearchParams
}

const getVisitedAllData = ({
    pagination,
    params
}: IVisitedAllDataProps) => async (dispatch: AppDispatch) => {
    const { page, limit } = pagination

    const url = `/settings-app/monitoring/qr-visit?page=${page + 1}&limit=${limit}`

    if (params) {
        url.concat(`&${params.toString()}`)
    }

    try {

        const consult = await api.get(url)

        if (consult.data) {

            const { items, meta } = consult.data

            dispatch(visitedAllData({
                allData: items,
                dataSelected: null,
                pagination,
                meta
            }))

        }

    } catch (error) {
        const { response } = error as { response: any };
        if (response) {
            dispatch(monitoringError((response.data as { msg: string }).msg || 'Error al cargar los datos de los Visitantes'))
        } else {
            dispatch(monitoringError((error as { message: string }).message || 'Error al cargar los datos de los Visitantes'))
        }
    }
}

const getHistoryAdminAllData = ({
    pagination,
    params
}: IVisitedAllDataProps) => async (dispatch: AppDispatch) => {
    const { page, limit } = pagination

    const url = `/settings-app/monitoring/admin-visit?page=${page + 1}&limit=${limit}`

    if (params) {
        url.concat(`&${params.toString()}`)
    }

    try {

        const consult = await api.get(url)

        if (consult.data) {

            const { items, meta } = consult.data

            dispatch(historyAdminAllData({
                allData: items,
                dataSelected: null,
                pagination,
                meta
            }))

        }

    } catch (error) {
        const { response } = error as { response: any };
        if (response) {
            dispatch(monitoringError((response.data as { msg: string }).msg || 'Error al cargar el Historial de Administradores'))
        } else {
            dispatch(monitoringError((error as { message: string }).message || 'Error al cargar el Historial de Administradores'))
        }
    }
}

export {
    getVisitedAllData,
    getHistoryAdminAllData
}