import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { FC } from 'react';

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
        label: 'Esta Semana',
        getValue: () => {
            const hoy = dayjs();
            return [hoy.startOf('week'), hoy.endOf('week')];
        },
    },
    {
        label: 'Semana Pasada',
        getValue: () => {
            const hoy = dayjs();
            const semanaPasada = hoy.subtract(7, 'day');
            return [semanaPasada.startOf('week'), semanaPasada.endOf('week')];
        },
    },
    {
        label: 'Ultimos 7 Días',
        getValue: () => {
            const hoy = dayjs();
            return [hoy.subtract(7, 'day'), hoy];
        },
    },
    {
        label: 'Mes Actual',
        getValue: () => {
            const hoy = dayjs();
            return [hoy.startOf('month'), hoy.endOf('month')];
        },
    },
    {
        label: 'Próximo Mes',
        getValue: () => {
            const hoy = dayjs();
            const inicioDelMesSiguiente = hoy.endOf('month').add(1, 'day');
            return [inicioDelMesSiguiente, inicioDelMesSiguiente.endOf('month')];
        },
    },
    { label: 'Reiniciar', getValue: () => [null, null] },
];

interface DateRangeShortcutsProps {
    dateRange: DateRange<Dayjs> | undefined;
    onChange: (value: DateRange<Dayjs> | undefined) => void;
}

const BasicRangeShortcuts: FC<DateRangeShortcutsProps> = ({ dateRange, onChange }) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
                label="Rango de fechas"
                slotProps={{
                    shortcuts: {
                        items: shortcutsItems,
                    },
                    actionBar: { actions: [] },
                }}
                calendars={2}
                value={dateRange}
                onChange={onChange}
                sx={{ width: '40%' }}
            />
        </LocalizationProvider>
    );
}

export default BasicRangeShortcuts