import { FC, useEffect } from "react";
import { Container, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import TargetsWrapper from "../../components/atomos/TargetsWrap";
import { useBusinessUnit } from "../../hooks/businessUnit/useBusinessUnit";
import TargeGridItem from "../../components/atomos/TargetItem";
import Target from "../../components/moleculas/Target";
import useIconLoader from "../../hooks/useIconLoader";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/hook/store.hook";
import useNavigationVisibility from "../../hooks/useNavigationVisibility";
// import { useAppSelector } from "../../store/hook/store.hook";
// import useNavigationVisibility from "../../hooks/useNavigationVisibility";

const WrapTargetContent = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
}))


const Home: FC = () => {

    const profile = useAppSelector((state) => state.profile)
    const allowerSuperAdmin = ['superadmin', 'admin'];
    
    const showNavigationForSuperAdmin = useNavigationVisibility(profile.rol, allowerSuperAdmin);

    const navigate = useNavigate()
    const { loadAllBusinessUnits, businessUnitState } = useBusinessUnit()
    const { getIcon } = useIconLoader()
    const theme = useTheme()
    const query = useMediaQuery(theme.breakpoints.down('lg'))

    useEffect(() => {
        loadAllBusinessUnits()
    }, [])



    const handleClick = (id: string, name: string) => {
        navigate('/dashboard/businessUnit', {
            state: {
                businessUnit: {
                    id,
                    name
                }
            }
        })
    }

       
    if (!showNavigationForSuperAdmin) {
        return (
            <>
                ACCESO DENEGADO
            </>
        )
    }


    return (
        <Container sx={{overflow: 'auto',}}>
            <Typography variant="h3" sx={{marginBottom: '4rem'}} fontFamily={"BoldTitilliumWeb"} color="secondary">
                Unidades de negocio
            </Typography>
            <TargetsWrapper sx={{marginBottom: '2rem'}}>
                {
                    businessUnitState.businessUnits.map((module) => {
                        return (
                            <TargeGridItem key={module.id} onClick={() => handleClick(module.id, module.name)}>
                                <Target id={module.name}>
                                    {!query && getIcon(module.name, 90, 90)}
                                    {query && (
                                        <WrapTargetContent>
                                            {getIcon(module.name, 90, 90)}
                                            <Typography variant="h6">
                                                {module.name}
                                            </Typography>
                                        </WrapTargetContent>
                                    )}
                                </Target>
                            </TargeGridItem>
                        )
                    })
                }
            </TargetsWrapper>
            {/* <Divider /> */}
        </Container>
    )
}


export default Home