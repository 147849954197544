import { Paper, PaperProps, styled } from "@mui/material";
import { useState } from "react";


const PaperStyled = styled(Paper)(({ }) => ({
    minHeight: '25vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F2F2F2',
    color: '#8F8F8F',
    fontSize: '1.7rem',
    fontWeight: 'bold',
    borderRadius: '15px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    cursor: 'pointer',
    textTransform: 'capitalize',
    ':hover': {
        backgroundColor: '#8F8F8F',
        color: 'white',
    }
}))

interface ITargetProps extends PaperProps {
    children: React.ReactNode
}

const Target = ({ children, id }: ITargetProps) => {

    const [isOver, setIsOver] = useState(false);

    return (
        <PaperStyled
            elevation={0} onMouseOver={() => setIsOver(true)}
            onMouseOut={() => setIsOver(false)}
        >
            {isOver ? id : children}
        </PaperStyled>
    )
}

export default Target