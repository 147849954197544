// src/routes/public.router.tsx
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { RootState } from '../store'; // Ajusta según la estructura de tu store

const PublicRoute = () => {
  const { accessToken, futurePath } = useSelector((state: RootState) => state.auth);
  const location = useLocation();

  // Si ya está autenticado, redirige al dashboard
  const to = futurePath || '/dashboard';

  if (accessToken) {
    return <Navigate to={to} replace state={{ ...location.state }} />;
  }

  // Si no está autenticado, renderiza las rutas públicas
  return <Outlet />;
};

export default PublicRoute;
