import { Box, styled } from "@mui/material";

const WrapperFilterContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '2rem 0',
    gap: '3rem',
}))

export default WrapperFilterContainer