import { getAllTemplates } from "../../store/actions/templates.action"
import { useAppDispatch, useAppSelector } from "../../store/hook/store.hook"

export const useLoaderTemplates = () => {

    const dispatch = useAppDispatch()
    const { templates, meta, paginated } = useAppSelector(state => state.templates)

    const loadTemplates = () => {
        dispatch(getAllTemplates({ page: paginated!.page + 1, limit: paginated!.limit }))
    }

    return {
        loadTemplates,
        templates,
        paginated,
        meta
    }
}