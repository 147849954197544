import { FC, useEffect, useState } from "react";
import { styled, Typography, TextField, IconButton, Snackbar } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import QRView from "../../moleculas/qr/qrview";
import { useAppSelector } from "../../../store/hook/store.hook";

const Wrap = styled('div')(() => ({
    minWidth: '25vw',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
}));

interface IFolderCreatedProps {
    handleCloseModal: () => void;
}

const QRCreated: FC<IFolderCreatedProps> = ({ handleCloseModal }) => {
    const { opened } = useAppSelector(state => state.fileExplorer);
    const { selected } = useAppSelector(state => state.projects);
    const [uri, setUri] = useState<string>(window.location.origin);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(() => {
        if (selected) {
            if (opened) {
                const tmpUrl = `${window.location.origin}/dashboard/recursos/undefined/folders/${opened.id}?init=true`;
                setUri(tmpUrl);
            } else {
                setUri(`${window.location.origin}/dashboard/recursos/${selected.id}`);
            }
        }
    }, [opened]);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(uri);
        setSnackbarOpen(true);
    };

    return (
        <Wrap>
            <Typography variant="h5" color="secondary">TU QR</Typography>
            <QRView tmpURI={uri} tmpName={selected?.id} handleCloseModal={handleCloseModal} />

            {/* Campo de URL con botón para copiar */}
            <TextField
                value={uri}
                variant="outlined"
                fullWidth
                InputProps={{
                    endAdornment: (
                        <IconButton onClick={handleCopyToClipboard}>
                            <ContentCopyIcon />
                        </IconButton>
                    )
                }}
            />

            {/* Snackbar para notificación */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message="URL copiada al portapapeles"
            />
        </Wrap>
    );
};

export default QRCreated;
