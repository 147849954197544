import { UseFormSetValue } from "react-hook-form";
import { AppDispatch } from "..";
import api from "../../services/axios";
import { Project } from "../schemas/project.schema";
import { Template } from "../schemas/template.schema";
import { clearSelectedProject, errorInLoadProject, loadSelectedProject, processingApi } from "../slices/project.slice";
import { FormDataEditableType } from "../../hooks/projects/useFormProject";

const getInfoProject = (projectId: string, params: URLSearchParams, setValue?: UseFormSetValue<FormDataEditableType>) => async (dispatch: AppDispatch) => {
    dispatch(processingApi())
    const url = `/projects/${projectId}?${params.toString()}`
    try {

        const response = await api.get(url);

        dispatch(loadSelectedProject(response.data))

        if (response.data && setValue) {
            const selected = response.data
            setValue("name", selected.name)
            setValue('cliente', selected.cliente)
            setValue('status_project_id', selected.status_project_id)
            setValue('warranty', selected.warranty)
            setValue('description', selected.description)
            setValue('ubication', selected.ubication)
            setValue('managements', selected.managements || [])
        }

    } catch (error) {
        const { response } = error as { response: any };
        if (response) {
            dispatch(errorInLoadProject((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
        } else {
            dispatch(errorInLoadProject((error as { message: string }).message || 'Error al cargar los recursos'))
        }
    }
}

const createProjectXBucket = (project: Project, template: Template, businessUnit: string) => async (dispatch: AppDispatch) => {

    try {
        const { id } = project
        if (id) {
            const bucket = await api.post('/recursos/bucket', {
                name: project.name,
                description: project.description,
                business_unit_id: businessUnit,
                project_id: id,
                template_id: template?.id || null
            })

            console.log("Proyecto creado:", bucket);

        } else {
            const proyecto = await api.post('/projects', {
                name: project.name,
                cliente: project.cliente,
                warranty: project.warranty,
                description: project.description,
                ubication: project.ubication,
                managements: project.managements && project.managements || [],
                template_id: template?.id || null
            })

            const proyectoData = proyecto.data as Project


            const bucket = await api.post('/recursos/bucket', {
                name: proyectoData.name,
                description: proyectoData.description,
                business_unit_id: businessUnit,
                project_id: proyectoData.id,
                template_id: template?.id || null
            })
            console.log("Proyecto creado:", bucket);
        }

        dispatch(clearSelectedProject())

    } catch (error: any) {
        const { response } = error as { response: any };
        if (response) {
            dispatch(errorInLoadProject((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
        } else {
            dispatch(errorInLoadProject((error as { message: string }).message || 'Error al cargar los recursos'))
        }
    }
}

const updateProject = (projectId: string, project: Project) => async (dispatch: AppDispatch) => {

    dispatch(processingApi())
    const url = `/projects/${projectId}`

    try {
        const response = await api.put(url, project);

        dispatch(loadSelectedProject(response.data))
    } catch (error: any) {
        const { response } = error as { response: any };
        if (response) {
            dispatch(errorInLoadProject((response.data as { msg: string }).msg || 'Error al cargar los recursos'))
        } else {
            dispatch(errorInLoadProject((error as { message: string }).message || 'Error al cargar los recursos'))
        }
    }
}


export {
    getInfoProject,
    createProjectXBucket,
    updateProject
}