import { FC } from "react"



const Form: FC<React.FormHTMLAttributes<HTMLFormElement>> = ({ ...all }) => {

    return (
        <form  style={{
            marginTop: '8%',
            width: '100%',
            display: 'flex',
            rowGap: '5%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
        }} {...all}>

        </form>
    )
}

export default Form