import { Grid2, styled } from "@mui/material";
import { FC } from "react";
import TextAtom from "../atomos/Text";

const GridContainerStyled = styled(Grid2)(({ theme }) => ({
    width: '100%',
    height: '5vh',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
        height: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '15%',
    }
}))

const GridChild = styled(Grid2)(({ theme }) => ({
    display: 'flex',

    [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'center'
    }
}))

const TextStyled = styled(TextAtom)(({ }) => ({
    marginLeft: '5%',
}))


const Footer: FC = () => {
    return (
        <GridContainerStyled container >
            <GridChild size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} sx={{ justifyContent: 'flex-start' }}>
                <TextStyled fontFamily="TitilliumWeb" color="secondary" sx={{ marginLeft: 0 }} >
                    &copy; 2025 Copyrigth. Todos los derechos reservados.
                </TextStyled>
            </GridChild>
            <GridChild size={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }} sx={{ justifyContent: 'flex-end' }}>
                <TextStyled fontFamily="TitilliumWeb" color="secondary" sx={{ cursor: 'pointer' }} onClick={
                    () => window.open('/terminos', '_blank')
                }>
                    Terminos y condiciones
                </TextStyled>
                <TextStyled fontFamily="TitilliumWeb" color="secondary" sx={{ cursor: 'pointer' }} onClick={
                    () => window.open('/politicas', '_blank')
                }>
                    Políticas de Privacidad
                </TextStyled>
            </GridChild>
        </GridContainerStyled>
    )
}

export default Footer