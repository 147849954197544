import { styled } from '@mui/material';
import { FC } from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';

const Img = styled('img')(({ theme }) => ({
    maxWidth: '100%',
    maxHeight: '90%',
    height: 'auto',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
        maxWidth: '90%',
    }
}));

const ImageViewer: FC<{ file: File }> = ({ file }) => {
    const fileUrl = URL.createObjectURL(file);

    return (
        <PhotoProvider
        >
            <PhotoView src={fileUrl}>
                <Img
                    src={fileUrl}
                    alt="file-preview"
                />
            </PhotoView>
        </PhotoProvider>
    );
};

export default ImageViewer;
